import axios, { AxiosResponse } from 'axios';
import buildinfo from '../../../../config/buildinfo.json';
import { Localization } from '../../../../localization/Localization';
import { CommandResult } from '../../../../shared/models/CommandResult';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { EditScoreRequestArgs } from './dataContracts/controller/EditScoreRequestArgs';
import { Image } from './dataContracts/queryStack/Image';
import { MultiAuscultationMergedScores } from './dataContracts/queryStack/MultiAuscultationMergedScores';
import { RoadSectionAttributes } from './dataContracts/queryStack/RoadSectionAttributes';
import { RoadSectionScore } from './dataContracts/queryStack/RoadSectionScore';
import { RoadTrunk } from './dataContracts/queryStack/RoadTrunk';
import { RoadTrunkLabelsScoreRanking } from './dataContracts/queryStack/RoadTrunkLabelsScoreRanking';
import { StepImageAnomalies } from './dataContracts/queryStack/StepImageAnomalies';

const buildNumber: string = buildinfo.build.buildNumber;

const controllerUrl = 'api/RoadsCondition/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class RoadsConditionApiClient {

    public static GetRoadsTrunks = (projectVersionId: number)
        : Promise<AxiosResponse<RoadTrunk[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetRoadsTrunks?projectVersionId=${encodeURIComponent(projectVersionId)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetPerAuscultationImages = (projectVersionId: number)
        : Promise<AxiosResponse<Map<number, Image[]>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerAuscultationImages?projectVersionId=${encodeURIComponent(projectVersionId)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetPerStepImagesAnomalies = (projectVersionId: number)
        : Promise<AxiosResponse<Map<number, StepImageAnomalies[]>>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerStepImagesAnomalies?projectVersionId=${encodeURIComponent(projectVersionId)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetProjectVersionAnomalies = (projectVersionId: number)
        : Promise<AxiosResponse<string[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetProjectVersionAnomalies?projectVersionId=${encodeURIComponent(projectVersionId)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetPerRoadSectionImages = (auscultationId: number, roadSectionId: number)
        : Promise<AxiosResponse<Image[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerRoadSectionImages?auscultationId=${encodeURIComponent(auscultationId)}&roadSectionId=${encodeURIComponent(roadSectionId)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetPerRoadSectionImagesFromImageId = (imageId: number)
        : Promise<AxiosResponse<Image[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerRoadSectionImagesFromImageId?imageId=${encodeURIComponent(imageId)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetPerRoadSectionImagesFromImageReferenceId = (imageReferenceId: string, auscultationsIds: string)
        : Promise<AxiosResponse<Image[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerRoadSectionImagesFromImageReferenceId?imageReferenceId=${encodeURIComponent(imageReferenceId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetPerAuscultationScores = (projectVersionId: number, shouldIgnoreCache?: boolean)
        : Promise<AxiosResponse<Map<number, RoadSectionScore[]>>> => {
        const ignoreCahce = shouldIgnoreCache === true ? true : (shouldIgnoreCache === false ? false : false);

        return axios({
            method: 'GET',
            url: `${controllerUrl}GetPerAuscultationScores?projectVersionId=${encodeURIComponent(projectVersionId)}&shouldIgnoreCache=${ignoreCahce}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetMergedScores = (projectVersionId: number, auscultationsIds: string)
        : Promise<AxiosResponse<MultiAuscultationMergedScores>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetMergedScores?projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&v=${buildNumber}`,
            headers: headers
        });
    }

    public static GetAttributesByRoadSections = (projectId: string, roadSectionsIds: number[])
        : Promise<AxiosResponse<RoadSectionAttributes[]>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}GetAttributesByRoadSections?projectId=${encodeURIComponent(projectId)}`,
            headers: headers,
            data: roadSectionsIds
        });
    }

    public static GetRoadTrunkLabelsScoreRanking = (roadSectionsIds: number[], projectVersionId: number, auscultationsIds: string)
        : Promise<AxiosResponse<RoadTrunkLabelsScoreRanking>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}GetRoadTrunkLabelsScoreRanking?projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}`,
            headers: headers,
            data: roadSectionsIds
        });
    }

    public static ExportExcel = (projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportExcel?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportExcelOfSections = (roadSectionsIds: number[], projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ExportExcelOfSections?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            data: roadSectionsIds
        });
    }

    public static ExportGeoJson = (projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportGeoJson?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportGeoJsonOfSctions = (roadSectionsIds: number[], projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ExportGeoJsonOfSctions?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            data: roadSectionsIds
        });
    }

    public static ExportShapefile = (projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportShapefile?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportShapefileOfSections = (roadSectionsIds: number[], projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ExportShapefileOfSections?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            data: roadSectionsIds
        });
    }

    public static ExportKml = (projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportKml?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportKmlOfSections = (roadSectionsIds: number[], projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ExportKmlOfSections?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/zip',
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            data: roadSectionsIds
        });
    }

    public static ExportSynthesisExcel = (projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}ExportSynthesisExcel?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&language=${encodeURIComponent(Localization.locale)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
    }

    public static ExportSynthesisExcelOfSections = (roadSectionsIds: number[], projectId: string, projectVersionId: number, auscultationsIds: string, measurementSystemType: MeasurementSystemType)
        : Promise<AxiosResponse<Blob>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}ExportSynthesisExcelOfSections?projectId=${encodeURIComponent(projectId)}&projectVersionId=${encodeURIComponent(projectVersionId)}&auscultationsIds=${encodeURIComponent(auscultationsIds)}&measurementSystemType=${encodeURIComponent(measurementSystemType)}&language=${encodeURIComponent(Localization.locale)}&v=${buildNumber}`,
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            data: roadSectionsIds
        });
    }

    public static EditScore = (requestArgs: EditScoreRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}EditScore`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }
}