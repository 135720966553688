import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { data, MapMouseEvent } from "azure-maps-control";
import { debounce } from "lodash";
import React, { Component } from "react";
import { Localization } from '../../localization/Localization';
import { PageLoaderComponent } from "../../shared/components/PageLoader/PageLoaderComponent";
import { RoadSectionDetailsComponent } from "../../shared/components/RoadSectionDetails/RoadSectionDetailsComponent";
import { MeasurementSystemType } from "../../shared/models/MeasurementSystemType";
import { Point } from "../../shared/models/Point";
import { MeasurementSystem } from "../../utils/MeasurementSystem";
import Utilities from "../../utils/Utilities";
import { RouteComponentProps, withRouter } from "../../withRouter";
import { Auscultation } from "../Home/services/dataContracts/queryStack/Auscultation";
import { ProjectVersion } from "../Home/services/dataContracts/queryStack/ProjectVersion";
import { ImageExtended } from "../RoadsCondition/models/ImageExtended";
import { MergedProjectVersion } from "../RoadsCondition/models/MergedProjectVersion";
import { ProjectVersionExtended } from "../RoadsCondition/models/ProjectVersionExtended";
import { RoadSectionViewData } from "../RoadsCondition/models/RoadSectionViewData";
import { RouteLocationStateModel } from "../RoadsCondition/models/RouteLocationStateModel";
import { RoadsConditionAndScenariosShared } from "../RoadsCondition/RoadsConditionAndScenariosShared";
import { RoadsConditionApiClient } from "../RoadsCondition/services/RoadsCondition/RoadsConditionApiClient";
import { ActionBarComponent } from "./components/ActionBarComponent";
import { HighwaysGridComponent } from "./components/highwaysGrid/HighwaysGridComponent";
import { HighwaysMap } from "./components/highwaysMap";
import { IndicatorStatsComponent } from "./components/IndicatorStatsComponent";
import { LayersMenuComponent } from "./components/LayersMenu/LayersMenuComponent";
import './HighwaysStyles.scss';
import { getRoadSectionGridViewDatas, RoadSectionGridViewData } from "./models/RoadSectionGridViewData";
import { UpdateRoadSectionAttributesBulkRequestArgs } from "./services/dataContracts/controller/UpdateRoadSectionAttributesBulkRequestArgs";
import { UpdateRoadSectionAttributesRequestArgs } from "./services/dataContracts/controller/UpdateRoadSectionAttributesRequestArgs";
import { RoadSectionAttributes } from "./services/dataContracts/queryStack/RoadSectionAttributes";
import { getRoadSectionsAttributes, updateRoadSectionAttributes } from "./services/HighwaysApiClient";

export enum DisplayMode {
    Map,
    Grid,
    MapAndGrid,
    Stats
}

export type HighwaysViewProps = {
    location: Point,
    userPermissions: string[]
}

interface HighwaysViewState {
    roadSectionsAttributesModel: RoadSectionGridViewData[],
    mergedProjectVersion: MergedProjectVersion,
    displayMode: DisplayMode,
    isLayerMenuDisplayed: boolean,
    currentMeasurementSystemType: MeasurementSystemType,
    selectedSectionsId: number[],
    filteredSectionsId: number[],
    filteredSectionsIdFromSearchText: number[],
    isGridHasActiveFilters: boolean,
    layersFilter: { [id: string]: string[] },
    layersOrder: string[],
    isMapInit: boolean,
    isLoading: boolean,
    isRoadSectionDetailsOpened: boolean,
    selectedImage: ImageExtended,
    selectedRoadSection: RoadSectionViewData
}

const initialState: HighwaysViewState = {
    roadSectionsAttributesModel: [],
    mergedProjectVersion: null,
    displayMode: DisplayMode.MapAndGrid,
    isLayerMenuDisplayed: false,
    currentMeasurementSystemType: null,
    selectedSectionsId: [],
    filteredSectionsId: [],
    filteredSectionsIdFromSearchText: [],
    isGridHasActiveFilters: false,
    layersFilter: {},
    layersOrder: [],
    isMapInit: false,
    isLoading: true,
    isRoadSectionDetailsOpened: false,
    selectedImage: null,
    selectedRoadSection: null
}


export class HighwaysView extends Component<RouteComponentProps & HighwaysViewProps, HighwaysViewState> {
    _isMounted: boolean;
    projectVersionId: number;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.inputSearchRoadsRef = React.createRef();

        initialState.currentMeasurementSystemType = MeasurementSystem.getCurrentType();
        this.state = initialState;
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void =>
        this.setState({
            currentMeasurementSystemType: measurementSystemType
        });

    updateDisplayMode = (displayMode: DisplayMode) =>
        this.setState({ displayMode });


    UpdateLayerMenuDisplay = () =>
        this.setState({ isLayerMenuDisplayed: !this.state.isLayerMenuDisplayed });

    getRoadSectionsAttributes = async (projectVersionId: number, auscultations: Auscultation[]): Promise<AxiosResponse<RoadSectionAttributes[]>> => {
        let auscultationsIdsArray: number[] = auscultations.map(x => x.auscultationId);
        let response = await getRoadSectionsAttributes(projectVersionId, Utilities.GetCommaSeparatedString(auscultationsIdsArray));
        return response;
    }

    getMergedProject = async (projectVersionId: number, projectVersionsCache: Map<number, ProjectVersion>): Promise<MergedProjectVersion> => {
        let project = await RoadsConditionAndScenariosShared.getVersionOfProject(projectVersionId, projectVersionsCache);
        let projectAuscultationsArray = project.auscultations;

        let auscultationsIdsArray: number[] = projectAuscultationsArray.map(x => x.auscultationId);
        let auscultationsIdsString: string = Utilities.GetCommaSeparatedString(auscultationsIdsArray);
        let multiAuscultationMergedScores = await RoadsConditionAndScenariosShared.getMergedScoresRawData(project.projectVersionId, auscultationsIdsString);

        let projectVersionData = await RoadsConditionAndScenariosShared.getProjectVersionRawData(project.projectVersionId, false);
        let projectVersion: ProjectVersionExtended = RoadsConditionAndScenariosShared.buildExtendedProjectVersion(project, projectVersionData.roadsTrunks, projectVersionData.roadSectionsScoresData);

        let roadSectionsIds = Array.from(projectVersion.roadsSections.keys());
        let roadSectionsAttributesData = await RoadsConditionAndScenariosShared.getAttributesByRoadSections(project.projectId, roadSectionsIds);
        let roadSectionsAttributes = RoadsConditionAndScenariosShared.buildRoadSectionsAttributes(roadSectionsAttributesData);

        let mergedProject: MergedProjectVersion = RoadsConditionAndScenariosShared.buildMergedProject(projectVersion, roadSectionsAttributes, multiAuscultationMergedScores, auscultationsIdsString);

        return mergedProject;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectVersionId = locationState.projectVersionId;

        await this.initViewData();
    }

    initViewData = async (): Promise<void> => {
        await this.getMergedProject(
            this.projectVersionId,
            this.projectVersionsCache)
            .then((res) => {
                if (res) {
                    this.setState({
                        mergedProjectVersion: res,
                        roadSectionsAttributesModel: getRoadSectionGridViewDatas(res.roadsSections),
                        isLoading: false
                    });
                }
            });
    }

    onSelectedSectionChange = (selectedSectionsId: number[]): void =>
        this.setState({ selectedSectionsId });

    onFilterSectionChange = (filteredSectionsId: number[], isGridHasActiveFilters: boolean): void =>
        this.setState({ filteredSectionsId, isGridHasActiveFilters });

    onValidateUpdateSectionAttributes = async (updatedRoadSectionsAttributes: RoadSectionGridViewData[]): Promise<void> => {
        this.setState({ isLoading: true });
        let updateRoadSectionAttributesRequestArgs = updatedRoadSectionsAttributes.map(section => {
            for (let [key, value] of section.changes) {
                section[key] = value;
            }

            return {
                roadSectionId: section.roadSectionId,
                roadSectionAttributesId: section.roadSectionAttributesId,
                roadLabel: section.roadLabel !== "" ? section.roadLabel?.trim() : null,
                municipality: section.municipality !== "" ? section.municipality?.trim() : null,
                widthInMeters: section.widthInMeters,
                district: section.district !== "" ? section.district?.trim() : null,
                collaborativeDevelopmentZone: section.collaborativeDevelopmentZone !== "" ? section.collaborativeDevelopmentZone?.trim() : null,
                hierarchy: section.hierarchy !== "" ? section.hierarchy : null,
                traffic: section.traffic !== "" ? section.traffic : null,
                environment: section.environment !== "" ? section.environment : null,
                manager: section.manager !== "" ? section.manager : null,
                bus: section.bus,
                bikeLase: section.bikeLase,
                border: section.border,
                ditch: section.ditch,
                side: section.side
            } as UpdateRoadSectionAttributesRequestArgs
        });

        let sectionModelUpdate = { ianaTimeZoneId: Localization.ianaTimeZoneId, attributes: updateRoadSectionAttributesRequestArgs } as UpdateRoadSectionAttributesBulkRequestArgs;
        updateRoadSectionAttributes(sectionModelUpdate).then((res) => {
            if (res) {
                this.initViewData();
            }
        });
    }

    updateLayerFilters = (filters: { [id: string]: string[] }) => {
        this.setState({
            layersFilter: filters
        });
    }

    updateOrder = (order: string[]) => {
        this.setState({
            layersOrder: order
        });
    }

    isMapInit = (isMapInit: boolean) => {
        this.setState({
            isMapInit: isMapInit
        });
    }

    handleChangeRoadsSearchText = debounce((value: string, state: HighwaysViewState): void => {
        let roadSectionsAttributesModel = state.roadSectionsAttributesModel;
        let filteredSectionIds: number[] = []
        let inputValue = value;
        if (inputValue.length > 2) {
            roadSectionsAttributesModel.forEach((roadSectionAttributes) => {
                if (roadSectionAttributes.roadLabel.trim().toLowerCase().removeDiacritics().includes(inputValue.trim().toLowerCase().removeDiacritics())) {
                    filteredSectionIds.push(roadSectionAttributes.roadSectionId);
                }
            });
        }

        this.setState({
            filteredSectionsIdFromSearchText: filteredSectionIds
        });
    }, 500);

    handleCloseRoadSectionDetails = (): void => {
        this.setState({
            selectedImage: null,
            selectedRoadSection: null,
            isRoadSectionDetailsOpened: false
        });
    }

    handleImageChanged = async (imageId: number, state: HighwaysViewState): Promise<void> => {
        if (imageId === null)
            return;

        let image: ImageExtended = state.mergedProjectVersion.imagesDico.get(imageId);
        if (image) {
            this.showImageAndSectionDetails(image, state.mergedProjectVersion);
        }
        else {
            await RoadsConditionApiClient.GetPerRoadSectionImagesFromImageId(imageId)
                .then((res) => {
                    let images = res.data as ImageExtended[];
                    let roadSection = state.mergedProjectVersion.roadsSections.get(images[0].roadSectionId);
                    RoadsConditionAndScenariosShared.buildViewDataFromSectionImages(state.mergedProjectVersion, images, roadSection);
                    image = state.mergedProjectVersion.imagesDico.get(imageId);
                    this.showImageAndSectionDetails(image, state.mergedProjectVersion);
                });
        }
    }

    handleStepChanged = (selectedImage: ImageExtended, searchByNext: boolean, state: HighwaysViewState): void => {
        let image: ImageExtended = state.mergedProjectVersion.imagesDico.get(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId);
        while (image?.roadStepId === selectedImage.roadStepId) {
            image = state.mergedProjectVersion.imagesDico.get(searchByNext ? image.nextImageId : image.previousImageId);
        }

        if (image) {
            this.handleImageChanged(image?.imageId, state);
        }
        else {
            this.handleImageChanged(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId, state);
        }
    }

    showImageAndSectionDetails = (image: ImageExtended, mergedProjectVersion: MergedProjectVersion): void => {
        this.setState((prevState, props) => {
            let roadSection = mergedProjectVersion.roadsSections.get(image.roadSectionId);

            return {
                selectedImage: image,
                selectedRoadSection: roadSection,
                isRoadSectionDetailsOpened: true,
                mergedProjectVersion: mergedProjectVersion
            };
        });
    }

    handleDisplayImageFromSectionClicked = async (e: MapMouseEvent, state: HighwaysViewState): Promise<void> => {
        const { mergedProjectVersion } = state;

        let isAltKeyPressed = (e.originalEvent as any).altKey;
        let isCtrlKeyPressed = (e.originalEvent as any).ctrlKey;
        let isShiftKeyPressed = (e.originalEvent as any).shiftKey;

        let clickedSectionId: number = null;
        let shape = (e.shapes[0] as any);
        if (shape) {
            clickedSectionId = shape.id ?? shape?.data?.properties?.RoadSectionId;
        }

        let section: RoadSectionViewData = mergedProjectVersion.roadsSections.get(clickedSectionId);
        if (!section || section.roadSectionScoreId === null)
            return;

        console.log("section : ");
        console.log(section);

        let clickedPosition: data.Position = e.position;
        if (clickedPosition) {
            if (!isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
                //Click simple: dans ce cas il faut positionner l'icone voiture dans la position de l'image la plus proche de la sélection
                let image = await RoadsConditionAndScenariosShared.handleSectionClicked(section, clickedPosition, mergedProjectVersion, false, false);
                this.showImageAndSectionDetails(image, mergedProjectVersion);
                return;
            }
        }
    }

    render() {
        return (
            <div className="highways-view">
                {this.state.isLoading ? <PageLoaderComponent></PageLoaderComponent> : null}
                <ActionBarComponent
                    displayMode={this.state.displayMode}
                    inputRef={this.inputSearchRoadsRef}
                    isLayerMenuDisplayed={this.state.isLayerMenuDisplayed}
                    isRoadSectionDetailsOpened={this.state.isRoadSectionDetailsOpened}
                    updateDisplayMode={this.updateDisplayMode}
                    UpdateLayerMenuDisplay={this.UpdateLayerMenuDisplay}
                    handleSearchTextChanged={(value) => this.handleChangeRoadsSearchText(value, this.state)}
                />
                {this.state.displayMode === DisplayMode.Stats &&
                    <IndicatorStatsComponent mergedProject={this.state.mergedProjectVersion} />
                }
                <Box className="main-content" visibility={this.state.displayMode === DisplayMode.Stats ? 'hidden' : 'visible'}>
                    {this.state.isRoadSectionDetailsOpened &&
                        <RoadSectionDetailsComponent
                            selectedRoadSection={this.state.selectedRoadSection}
                            selectedImage={this.state.selectedImage}
                            hasScoreAnalysisAccess={null}
                            role={null}
                            shouldDisplayDetails={false}
                            shouldEditRoadSectionScore={false}
                            handleStepChanged={(selectedImage: ImageExtended, searchByNext: boolean) => this.handleStepChanged(selectedImage, searchByNext, this.state)}
                            handleImageChanged={(imageId) => this.handleImageChanged(imageId, this.state)}
                            onClose={() => this.handleCloseRoadSectionDetails()}
                        />
                    }
                    <Box className="highways-content" >
                        <div className={this.state.displayMode === DisplayMode.Map ? "highways-info-map" :
                            (this.state.displayMode === DisplayMode.MapAndGrid ? "highways-info-map-grid" :
                                (this.state.displayMode === DisplayMode.Grid ? "highways-info-grid" : ""))}>
                            <div className="map">
                                <HighwaysMap
                                    displayMode={this.state.displayMode}
                                    mergedProjectVersion={this.state.mergedProjectVersion}
                                    filteredSectionsId={this.state.filteredSectionsId}
                                    filteredSectionsIdFromSearchText={this.state.filteredSectionsIdFromSearchText}
                                    selectedSectionsId={this.state.selectedSectionsId}
                                    isGridHasActiveFilters={this.state.isGridHasActiveFilters}
                                    locationGeometry={this.props.location.state.locationGeometry}
                                    isLayerMenuDisplayed={this.state.isLayerMenuDisplayed}
                                    layersFilter={this.state.layersFilter}
                                    layersOrder={this.state.layersOrder}
                                    onSelectedSectionChange={this.onSelectedSectionChange}
                                    isMapInit={this.isMapInit}
                                    currentMeasurementSystemType={this.state.currentMeasurementSystemType}
                                    searchText={this.inputSearchRoadsRef?.current?.value?.trim()}
                                    selectedImage={this.state.selectedImage}
                                    handleDisplayImageFromSectionClicked={(e) => this.handleDisplayImageFromSectionClicked(e, this.state)}
                                    handleCLoseRoadSectionDetails={this.handleCloseRoadSectionDetails}

                                />
                            </div>
                            <div className="grid">
                                <HighwaysGridComponent
                                    roadSectionsAttributes={this.state.roadSectionsAttributesModel}
                                    onSelectedSectionChange={this.onSelectedSectionChange}
                                    onFilterSectionChange={this.onFilterSectionChange}
                                    onValidateUpdateSectionAttributes={this.onValidateUpdateSectionAttributes}
                                    selectedSectionsId={this.state.selectedSectionsId}
                                    userPermissions={this.props.userPermissions}
                                    filteredSectionsIdFromSearchText={this.state.filteredSectionsIdFromSearchText}
                                    searchText={this.inputSearchRoadsRef?.current?.value?.trim()}
                                />
                            </div>
                        </div>
                        {this.state.isMapInit &&
                            <div className={!this.state.isLayerMenuDisplayed ? "hide-layers-menu" : ""}>
                                <LayersMenuComponent UpdateLayerMenuDisplay={this.UpdateLayerMenuDisplay}
                                    updateFilter={this.updateLayerFilters}
                                    updateLayerOrder={this.updateOrder}
                                    mergedProjectVersion={this.state.mergedProjectVersion}
                                />
                            </div>
                        }
                    </Box>
                </Box>
            </div>
        )
    }
}

export default React.forwardRef(withRouter(HighwaysView));