import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Translate from '../../../../localization/Localization';
import { getScoreType } from '../../../../shared/models/ScoreTypes';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { ProgrammingAreaExtended } from '../../models/ProgrammingAreaExtended';
import { getProgrammingWorkOrientationTextValues } from '../../models/WorkOrientation';
import { AreaSection } from '../../services/dataContracts/queryStack/AreaSection';
import { WorkOrientation } from '../../services/dataContracts/queryStack/WorkOrientation';
import './AreaSectionsDetailsStyles.scss';

interface AreaSectionsDetailsComponentProps {
    dataItem: ProgrammingAreaExtended,
    mergedProject: MergedProjectVersion,
    costRatios: CostRatio[],
    areasSections: Map<number, Map<number, AreaSection>>,
    handleEditArea: (programmingAreaId: number) => void
}

export const AreaSectionsDetailsComponent = (props: AreaSectionsDetailsComponentProps) => {

    const programmingWorkOrientationTextValues = getProgrammingWorkOrientationTextValues();
    const sections = props.mergedProject.roadsSections;

    const getSectionWorkOrientation = (score: number): WorkOrientation => {
        if (score === 10 || score === 9 || score === 8) {
            return WorkOrientation.monitoring;
        }

        if (score === 7 || score === 6) {
            return WorkOrientation.localizedRepair;
        }

        if (score === 5 || score === 4) {
            return WorkOrientation.generalMaintenance;
        }

        if (score === 3 || score === 2) {
            return WorkOrientation.reinforcement;
        }

        if (score === 1) {
            return WorkOrientation.rehabilitation;
        }

        return null;
    }

    return (
        <Box className="area-sections-details" >
            <Box display="flex" flexDirection="column">
                {props.areasSections.size > 0 && props.dataItem.sectionsId.map((sectionId, index) => {
                    let section = sections.get(sectionId);

                    let programmingAreaId = props.dataItem.programmingAreaId;
                    let areaSections = props.areasSections.get(programmingAreaId);
                    let areaSection = areaSections.get(sectionId);

                    let lengthInMeters = section.lengthInMeters ? Math.round(section.lengthInMeters) : null;
                    let surface = lengthInMeters && section.widthInMeters ? Math.round(lengthInMeters * section.widthInMeters) : null;
                    let costRatio: CostRatio = props.costRatios.find(x => x.traffic === section.traffic && x.score === areaSection.score);
                    let budget = surface && costRatio ? surface * costRatio.costRatioValue : null;
                    let sectionWorkOrientation = getSectionWorkOrientation(areaSection.score);

                    let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(areaSection.score);
                    let scoreType = getScoreType(scoreColor);

                    return (
                        <Box key={`section-${index}`} className="section-details">
                            <div className={`score-color ${scoreType}`}></div>
                            <div className="section-id">{sectionId}</div>
                            <div className="road-label">{section.roadLabel}</div>
                            <div style={{ width: "110px" }}></div>
                            <div className="" style={{ width: "150px" }}>{(programmingWorkOrientationTextValues.get(sectionWorkOrientation))}</div>
                            <div className="" style={{ width: "140px" }}></div>
                            <div className="score">{areaSection.score}</div>
                            <div className="budget">{budget}</div>
                            <div className="cost-ratio">{costRatio ? costRatio.costRatioValue : '-'}</div>
                            <div className="length-in-meters">{section.lengthInMeters}</div>
                            <div className="surface">{surface}</div>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" p="10px">
                <Button className="btn-secondary" onClick={() => props.handleEditArea(props.dataItem.programmingAreaId)}>
                    {Translate.Resources.UI_AreaSectionsDetails_AccessToTheSections}
                </Button>
                <div className="description-text">{Translate.Resources.UI_AreaSectionsDetails_YouCanSelectDeselectSections}</div>
            </Box>
        </Box>
    )
}