import Translate from "../../localization/Localization";
import { ScoreColors } from "../../views/RoadsCondition/models/ScoreColors";

export const ScoreTypes = {
    monitoring: "monitoring",
    generalMaintenance: "generalMaintenance",
    localizedRepair: "localizedRepair",
    reinforcement: "reinforcement",
    rehabilitation: "rehabilitation"
}

export const getScoreClassName = (scoreType: string): string => {
    switch (scoreType) {
        case ScoreTypes.monitoring:
            return 'monitoring';

        case ScoreTypes.localizedRepair:
            return 'localized-repair';

        case ScoreTypes.generalMaintenance:
            return 'general-maintenance';

        case ScoreTypes.reinforcement:
            return 'reinforcement';

        case ScoreTypes.rehabilitation:
            return 'rehabilitation';

        default:
            return 'empty';
    }
}

export const getScoreLabel = (scoreType: string): string => {
    switch (scoreType) {
        case ScoreTypes.monitoring:
            return Translate.Resources.UI_ActionsMenu_Quality_Monitoring;

        case ScoreTypes.localizedRepair:
            return Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair;

        case ScoreTypes.generalMaintenance:
            return Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance;

        case ScoreTypes.reinforcement:
            return Translate.Resources.UI_ActionsMenu_Quality_Reinforcement;

        case ScoreTypes.rehabilitation:
            return Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation;

        default:
            return Translate.Resources.UI_ActionsMenu_Empty;
    }
}

export const getScoreType = (scoreColor: string): string => {
    switch (scoreColor) {
        case ScoreColors.monitoring:
            return ScoreTypes.monitoring;

        case ScoreColors.localizedRepair:
            return ScoreTypes.localizedRepair;

        case ScoreColors.generalMaintenance:
            return ScoreTypes.generalMaintenance;

        case ScoreColors.reinforcement:
            return ScoreTypes.reinforcement;

        case ScoreColors.rehabilitation:
            return ScoreTypes.rehabilitation;

        default:
            return null;
    }
}