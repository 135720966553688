import { MeasurementSystemType } from "../shared/models/MeasurementSystemType";
import { AppModule, LocalStorage } from "./Storage";

interface IMeasurementSystemInstanceData {
    current: MeasurementSystemType,
    isMetric: boolean
}

export class MeasurementSystem {

    private static _instanceData: IMeasurementSystemInstanceData | null = null;

    public static MetersToYardsConversionRatio: number = 1.0936133;
    public static KilometersToMilesConversionRatio: number = 0.6213712;
    public static SquaredMetersToSquaredYardsConversionRatio: number = 1.19599005;

    private constructor() {
    }

    private static SetInstance = (systemType: MeasurementSystemType) => {
        MeasurementSystem._instanceData = {
            current: systemType,
            isMetric: systemType === MeasurementSystemType.Metric
        };
    }

    public static Init = () => {
        var systemType = LocalStorage.GetItem(AppModule.Global, "MeasurementSystemType");
        if (!systemType) {
            systemType = MeasurementSystemType.Metric;
        }

        MeasurementSystem.SetInstance(systemType as MeasurementSystemType);
    }

    public static Set = (systemType: MeasurementSystemType) => {
        MeasurementSystem.SetInstance(systemType as MeasurementSystemType);
        LocalStorage.SetItem(AppModule.Global, "MeasurementSystemType", systemType);
    }

    public static getCurrentType = (): MeasurementSystemType => {
        return MeasurementSystem._instanceData.current;
    }

    public static getRatioInMetersOrYards = (valueInMeters: number): number => {
        if (MeasurementSystem._instanceData.isMetric)
            return valueInMeters;

        return valueInMeters / MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
    }

    public static getLengthInMetersOrYards = (valueInMeters: number): number => {
        if (MeasurementSystem._instanceData.isMetric)
            return valueInMeters;

        return valueInMeters * MeasurementSystem.MetersToYardsConversionRatio;
    }  

    public static getLenghtInKilometersOrMiles = (valueInMeters: number): number => {
        const valueInKilometers = valueInMeters / 1000;

        if (MeasurementSystem._instanceData.isMetric)
            return valueInKilometers;

        return valueInKilometers * MeasurementSystem.KilometersToMilesConversionRatio;
    }

    public static getSurfaceInSquaredMetersOrSquaredYards = (squaredMetersValue: number): number => {
        if (MeasurementSystem._instanceData.isMetric)
            return squaredMetersValue;

        return squaredMetersValue * MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
    }
       
    public static convertToLengthInMeters = (valueInMetersOrYards: number, valueSystemType: MeasurementSystemType): number => {

        if (valueSystemType === MeasurementSystemType.Metric)
            return valueInMetersOrYards;

        return valueInMetersOrYards / MeasurementSystem.MetersToYardsConversionRatio;
    }

    public static convertToSurfaceInSquaredMeters = (valueInSquaredMetersOrSquaredYards: number, valueSystemType: MeasurementSystemType): number => {

        if (valueSystemType === MeasurementSystemType.Metric)
            return valueInSquaredMetersOrSquaredYards;

        return valueInSquaredMetersOrSquaredYards / MeasurementSystem.SquaredMetersToSquaredYardsConversionRatio;
    }

    public static getSymbolOfLengthUnit = (): string => {
        if (MeasurementSystem._instanceData.isMetric)
            return 'm';

        return 'yd';
    }

    public static getSymbolOfSurfaceUnit = (): string => {
        if (MeasurementSystem._instanceData.isMetric)
            return 'm²';

        return 'sq.yd';
    }

    public static getSymbolOfKilometersOrMiles = (): string => {
        if (MeasurementSystem._instanceData.isMetric)
            return 'km';

        return 'mi';
    }
}