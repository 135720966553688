import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import Translate, { Localization } from '../../../localization/Localization';
import { CommandResult } from '../../../shared/models/CommandResult';
import { Currencies } from '../../../shared/models/Currencies';
import { MeasurementSystemType } from '../../../shared/models/MeasurementSystemType';
import { Point } from '../../../shared/models/Point';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ProjectVersion } from '../../Home/services/dataContracts/queryStack/ProjectVersion';
import { getQualityText } from '../../ProjectSettings/models/WorkPriorityViewData';
import { CostRatio } from '../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import { RouteLocationStateModel } from '../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../RoadsCondition/RoadsConditionAndScenariosShared';
import { AreaMigration } from '../models/AreaMigration';
import { AddProgrammingRequestArgs } from '../services/dataContracts/controller/AddProgrammingRequestArgs';
import { DeleteProgrammingRequestArgs } from '../services/dataContracts/controller/DeleteProgrammingRequestArgs';
import { DuplicateProgrammingRequestArgs } from '../services/dataContracts/controller/DuplicateProgrammingRequestArgs';
import { UpdateLabelAndYearProgrammingRequestArgs } from '../services/dataContracts/controller/UpdateLabelAndYearProgrammingRequestArgs';
import { Programming } from '../services/dataContracts/queryStack/Programming';
import { ProgrammingsApiClient } from '../services/ProgrammingsApiClient';
import { AddOrUpdateProgrammingComponent } from './components/AddOrUpdateProgrammingComponent';
import { ProgrammingsListComponent } from './components/ProgrammingsListComponent';
import { ProgrammingsMapComponent } from './components/ProgrammingsMapComponent';
import { ProgrammingsModuleDescriptionComponent } from './components/ProgrammingsModuleDescriptionComponent';
import './ProgrammingsManagementStyles.scss';

interface ProgrammingsManagementState {
    loading: boolean,
    programmings: Programming[],
    isCreateEditProgrammingContentVisible: boolean,
    currencySymbole: string,
    isProgrammingInEdit: boolean,
    programmingInEdit: Programming,
    mergedProjects: Map<number, MergedProjectVersion>,
    measurementSystemType: MeasurementSystemType,
    isImpossibleProgrammingMigrationDialogOpened: boolean,
    isProgrammingMigrationDialogOpened: boolean,
    areas: AreaMigration[],
    forceDuplicateProjectId: string,
    forceDuplicateProjectVersionId: number,
    forceDuplicateProjectVersionNumber: number,
    forceDuplicateProgrammingId: number,
    forceDuplicateProgrammingLabel: string,
    forceDuplicateProgrammingDate: Date
}

const initialState: ProgrammingsManagementState = {
    loading: false,
    programmings: null,
    isCreateEditProgrammingContentVisible: false,
    currencySymbole: null,
    isProgrammingInEdit: false,
    programmingInEdit: null,
    mergedProjects: null,
    measurementSystemType: null,
    isImpossibleProgrammingMigrationDialogOpened: false,
    isProgrammingMigrationDialogOpened: false,
    areas: null,
    forceDuplicateProjectId: null,
    forceDuplicateProjectVersionId: null,
    forceDuplicateProjectVersionNumber: null,
    forceDuplicateProgrammingId: null,
    forceDuplicateProgrammingLabel: null,
    forceDuplicateProgrammingDate: null
}

export class ProgrammingsManagementView extends Component<RouteComponentProps, ProgrammingsManagementState> {
    _isMounted: boolean;
    projectId: string;
    projectVersionId: number;
    locationGeometry: Point;
    projectVersionsCache: Map<number, ProjectVersion>;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    costRatiosCache: Map<number, CostRatio[]>;

    constructor(props) {
        super(props);

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();

        this.costRatiosCache = new Map<number, CostRatio[]>();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectId = locationState.projectId;
        this.projectVersionId = locationState.projectVersionId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const programmingId = Number(query.get('programmingId'));

        this.setState({
            loading: true
        });

        await Promise.all([
            ProgrammingsApiClient.GetProjectCurrency(this.projectId),
            ProgrammingsApiClient.GetProgrammings(this.projectId)
        ])
            .then(async (results) => {
                let currencySymbole = Currencies[results[0].data];
                let programmings = results[1].data;

                let yearApiCalls: Promise<AxiosResponse<CostRatio[]>>[] = [];
                let years = programmings.map(x => x.year);
                let yearsSet = new Set(years);
                yearsSet.forEach((year) => {
                    yearApiCalls.push(ProgrammingsApiClient.GetCostRatios(this.projectId, year));
                });

                let costRatiosData = await Promise.all(yearApiCalls);
                costRatiosData.forEach((res) => {
                    let data = res.data;
                    if (data.length > 0) {
                        this.costRatiosCache.set(data[0].year, data);
                    }
                });

                let apiCalls: Promise<MergedProjectVersion>[] = [];
                let projectVersionIds = programmings.map(x => x.projectVersionId);
                let ids = new Set(projectVersionIds);
                if (!ids.has(this.projectVersionId)) {
                    ids.add(this.projectVersionId);
                }
                ids.forEach((projectVersionId) => {
                    apiCalls.push(RoadsConditionAndScenariosShared.getMergedProject(projectVersionId, this.mergedProjectAuscultationsCache, this.projectVersionsCache))
                });

                await Promise.all(apiCalls).then(() => {
                    let state: ProgrammingsManagementState = { ...this.state };

                    if (programmingId) {
                        let programming = programmings.find(x => x.programmingId === programmingId);
                        state.isCreateEditProgrammingContentVisible = true;
                        state.isProgrammingInEdit = true;
                        state.programmingInEdit = programming;
                    }

                    state.currencySymbole = currencySymbole;
                    state.programmings = programmings;
                    state.mergedProjects = this.mergedProjectAuscultationsCache;
                    state.loading = false;

                    this.setState(state);
                })
            });
    }

    handleCreateProgrammingClicked = (): void => {
        this.setState({
            isCreateEditProgrammingContentVisible: true,
            isProgrammingInEdit: false,
            programmingInEdit: null
        });
    }

    handleCancelAddOrUpdateProgrammingClicked = (): void => {
        const query = new URLSearchParams(this.props.location.search);
        if (query.get('programmingId')) {
            this.props.navigate(-1);
            return;
        }

        this.setState({
            loading: true
        });

        ProgrammingsApiClient.GetProgrammings(this.projectId)
            .then((res) => {
                this.setState({
                    programmings: res.data,
                    isCreateEditProgrammingContentVisible: false,
                    isProgrammingInEdit: false,
                    programmingInEdit: null,
                    loading: false
                });
            });
    }

    handleAddProgrammingClicked = (label: string, year: number): void => {
        this.setState({
            loading: true
        });

        let args: AddProgrammingRequestArgs = {
            projectId: this.projectId,
            projectVersionId: this.projectVersionId,
            label: label,
            year: year,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.AddProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });
                    return;
                }
                const programmingId: number = data.customData;
                this.navigateToProgrammingAreasManagement(programmingId);

                this.setState({
                    loading: false
                });
            });
    }

    handleMigrateCanceled = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        this.setState({
            isProgrammingMigrationDialogOpened: false,
            loading: false
        });
    }

    handleMigrateConfirmed = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        this.setState({
            isProgrammingMigrationDialogOpened: false,
            loading: true
        });
        this.handleForceDuplicate(this.projectId, this.projectVersionId, this.state.forceDuplicateProjectVersionNumber, this.state.forceDuplicateProgrammingId)
    }

    handleUpdateProgrammingClicked = (label: string, year: number, state: ProgrammingsManagementState): void => {
        this.setState({
            loading: true
        });

        let args: UpdateLabelAndYearProgrammingRequestArgs = {
            programmingId: state.programmingInEdit.programmingId,
            label: label,
            year: year,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.UpdateProgrammingLabelAndYear(args)
            .then((res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                this.handleCancelAddOrUpdateProgrammingClicked();
            });
    }

    navigateToProgrammingAreasManagement = (programmingId: number): void => {
        let urlRedirect = `/ProgrammingAreasManagement?programmingId=${programmingId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleOpenProgramming = (programmingId: number): void => {
        this.navigateToProgrammingAreasManagement(programmingId);
    }

    handleEditProgramming = (programmingId: number, state: ProgrammingsManagementState): void => {
        let programming = state.programmings.find(x => x.programmingId === programmingId);

        this.setState({
            isCreateEditProgrammingContentVisible: true,
            isProgrammingInEdit: true,
            programmingInEdit: programming
        });
    }

    handleDeleteProgramming = (programmingId: number): void => {
        let args: DeleteProgrammingRequestArgs = {
            programmingId: programmingId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.DeleteProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                ProgrammingsApiClient.GetProgrammings(this.projectId)
                    .then((res) => {
                        let programmings = res.data;

                        this.setState({
                            loading: false,
                            programmings: programmings
                        });
                    });
            });
    }

    handleDuplicateProgramming = (projectId: string, projectVersionId: number, projectVersionNumber: number, programmingId: number, programmingLabel: string, programmingDate: Date): void => {
        this.setState({
            loading: true,
            forceDuplicateProjectVersionNumber: projectVersionNumber,
            forceDuplicateProgrammingId: programmingId,
            forceDuplicateProgrammingLabel: programmingLabel,
            forceDuplicateProgrammingDate: programmingDate
        });

        let args: DuplicateProgrammingRequestArgs = {
            projectId: projectId,
            projectVersionId: projectVersionId,
            projectVersionNumber: projectVersionNumber,
            programmingId: programmingId,
            ianaTimeZoneId: Localization.ianaTimeZoneId,
            auscultationsIds: this.mergedProjectAuscultationsCache.get(this.projectVersionId).auscultationsIdsString
        };

        ProgrammingsApiClient.DuplicateProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data as CommandResult);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                if (data as any === false) {
                    this.setState({
                        isImpossibleProgrammingMigrationDialogOpened: true,
                        loading: false
                    });
                    return;
                }

                if (data[0]?.label !== undefined) {
                    this.setState({
                        areas: data as AreaMigration[],
                        isProgrammingMigrationDialogOpened: true,
                        loading: false
                    });
                    return;
                }

                ToastService.showSuccessToast(Translate.Resources.UI_ProgrammingsManagementView_ProgramWasSuccessfullyMigrated);

                ProgrammingsApiClient.GetProgrammings(this.projectId)
                    .then((res) => {
                        let programmings = res.data;

                        this.setState({
                            loading: false,
                            programmings: programmings
                        });
                    });
            });
    }

    handleForceDuplicate = (projectId: string, projectVersionId: number, projectVersionNumber: number, programmingId: number): void => {
        let args: DuplicateProgrammingRequestArgs = {
            projectId: projectId,
            projectVersionId: projectVersionId,
            projectVersionNumber: projectVersionNumber,
            programmingId: programmingId,
            ianaTimeZoneId: Localization.ianaTimeZoneId,
            auscultationsIds: this.mergedProjectAuscultationsCache.get(this.projectVersionId).auscultationsIdsString
        };

        ProgrammingsApiClient.ForceDuplicateProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }
                ToastService.showSuccessToast(Translate.Resources.UI_ProgrammingsManagementView_ProgramWasSuccessfullyMigrated);
                ProgrammingsApiClient.GetProgrammings(this.projectId)
                    .then((res) => {
                        let programmings = res.data;
                        this.setState({
                            loading: false,
                            programmings: programmings
                        });
                    });
            });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const state = this.state;

        return (
            <Box className="programmings">
                <Box className="programmings-content">
                    {state.isCreateEditProgrammingContentVisible ?
                        (<AddOrUpdateProgrammingComponent projectId={this.projectId}
                            projectVersionId={this.projectVersionId}
                            inEdit={state.isProgrammingInEdit}
                            programmingInEdit={state.programmingInEdit}
                            handleAddProgrammingClicked={this.handleAddProgrammingClicked}
                            handleUpdateProgrammingClicked={(label: string, year: number) => this.handleUpdateProgrammingClicked(label, year, state)}
                            handleCancel={() => this.handleCancelAddOrUpdateProgrammingClicked()} />) :
                        (state.programmings ?
                            (state.programmings.length > 0 ?
                                (<ProgrammingsListComponent programmingsList={state.programmings}
                                    currency={state.currencySymbole}
                                    mergedProjects={state.mergedProjects}
                                    projectId={this.projectId}
                                    costRatiosCache={this.costRatiosCache}
                                    currentProjectVersionId={this.projectVersionId}
                                    handleCreateProgrammingClicked={this.handleCreateProgrammingClicked}
                                    handleOpenProgramming={this.handleOpenProgramming}
                                    handleEditProgramming={(programmingId: number) => this.handleEditProgramming(programmingId, state)}
                                    handleDeleteProgramming={this.handleDeleteProgramming}
                                    handleDuplicateProgramming={this.handleDuplicateProgramming}
                                />) :
                                (<ProgrammingsModuleDescriptionComponent handleCreateProgrammingClicked={this.handleCreateProgrammingClicked} />)
                            ) : '')
                    }
                </Box>
                {this.locationGeometry &&
                    <ProgrammingsMapComponent locationGeometry={this.locationGeometry} projectVersionId={this.projectVersionId} mergedProjects={state.mergedProjects} programmingInEdit={state.programmingInEdit} loading={state.loading} />
                }
                {state.isImpossibleProgrammingMigrationDialogOpened &&
                    <Dialog id="impossible-Programming-migration-dialog" open={state.isImpossibleProgrammingMigrationDialogOpened}>
                        <DialogTitle className="title-icon">
                            <IconButton onClick={() => {
                                this.setState({
                                    isImpossibleProgrammingMigrationDialogOpened: false
                                });
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexDirection="row" className="title" alignItems="center" justifyContent="center">
                                <ReplayIcon className="migrate-icon" />
                                <div>{Translate.Resources.UI_Progrmmings_ProgrmmingCard_Menu_MigrateProgrmmingDialog_StatementMigration}</div>
                            </Box>
                            <Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Progrmmings_AutomaticMigrationImpossible}
                                </Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Progrmmings_NecessaryToCreateANewProgram}
                                </Box>
                            </Box>

                        </DialogContent>
                    </Dialog>
                }

                {state.isProgrammingMigrationDialogOpened &&
                    <Dialog id="programming-migration-dialog" open={state.isProgrammingMigrationDialogOpened}>
                        <DialogTitle className="title-icon">
                            <IconButton onClick={() => {
                                this.setState({
                                    isProgrammingMigrationDialogOpened: false
                                });
                            }}>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexDirection="row" className="title" alignItems="center" justifyContent="center">
                                <ReplayIcon className="migrate-icon" />
                                <div>{Translate.Resources.UI_Progrmmings_ProgrmmingCard_Menu_MigrateProgrmmingDialog_StatementMigration}</div>
                            </Box>
                            <Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Programming_Migration_Warning}
                                </Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Programming_Migration_RefChanged}
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Zone}</TableCell>
                                                <TableCell>{Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_WorkOrientation}</TableCell>
                                                <TableCell>{Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_SelectedWorks}</TableCell>
                                                <TableCell>{`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Budget} (${state.currencySymbole ?? ''})`}</TableCell>
                                                <TableCell>{`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_CostRatio} (${state.currencySymbole}/${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</TableCell>
                                                <TableCell>{`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Linear} (${MeasurementSystem.getSymbolOfLengthUnit()})`}</TableCell>
                                                <TableCell>{`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Surface} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</TableCell>
                                                <TableCell>{Translate.Resources.UI_Programming_AreasManagement_Begin}</TableCell>
                                                <TableCell>{Translate.Resources.UI_Programming_AreasManagement_End}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.areas.map((row) => (
                                                <TableRow
                                                    key={row.label}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{row.label}</TableCell>
                                                    <TableCell>{getQualityText(row.workOrientation)}</TableCell>
                                                    <TableCell>{row.selectedWork}</TableCell>
                                                    <TableCell>{row.budgetAmount}</TableCell>
                                                    <TableCell>{row.costRatio}</TableCell>
                                                    <TableCell>{row.lengthInLinearMeters}</TableCell>
                                                    <TableCell>{row.areaInSquareMeters}</TableCell>
                                                    <TableCell>{row.startDate ? `${(row.startDate.getMonth() + 1) > 9 ? row.startDate.getMonth() + 1 : '0' + (row.startDate.getMonth() + 1)}/${row.startDate.getFullYear()}` : ''}</TableCell>
                                                    <TableCell>{row.endDate ? `${(row.endDate.getMonth() + 1) > 9 ? row.endDate.getMonth() + 1 : '0' + (row.endDate.getMonth() + 1)}/${row.endDate.getFullYear()}` : ''}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box className="text">
                                    {Translate.Resources.UI_Programming_Migration_NewProgram}
                                </Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Programming_Migration_YourProgram} {this.state.forceDuplicateProgrammingLabel} {Translate.Resources.UI_Programming_Migration_OfDate} {this.state.forceDuplicateProgrammingDate ? `${this.state.forceDuplicateProgrammingDate.getDate()}/${(this.state.forceDuplicateProgrammingDate.getMonth() + 1) > 9 ? this.state.forceDuplicateProgrammingDate.getMonth() + 1 : '0' + (this.state.forceDuplicateProgrammingDate.getMonth() + 1)}/${this.state.forceDuplicateProgrammingDate.getFullYear()} ` : ''}
                                    {Translate.Resources.UI_Programming_Migration_Duplicated} {this.state.forceDuplicateProgrammingLabel}_V{this.state.forceDuplicateProjectVersionNumber} {Translate.Resources.UI_Programming_Migration_AndKept}
                                </Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Programming_Migration_OnceModified} {this.state.forceDuplicateProgrammingLabel}.
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <Button className="btn-secondary" onClick={(e) => this.handleMigrateCanceled(e)}>
                                        {Translate.Resources.UI_Progrmmings_ProgrmmingCard_Menu_MigrateProgrmmingDialog_Button_Cancel}
                                    </Button>
                                    <Button className="btn-primary" onClick={(e) => this.handleMigrateConfirmed(e)}>
                                        {Translate.Resources.UI_Progrmmings_ProgrmmingCard_Menu_MigrateProgrmmingDialog_Button_Validate}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ProgrammingsManagementView));