import { mdiLightbulbOn } from '@mdi/js';
import Icon from '@mdi/react';
import DeleteIcon from '@mui/icons-material/Delete';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Box } from '@mui/system';
import { Grid, GridColumn, GridExpandChangeEvent, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import Translate, { Localization } from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import styles2 from '../../../../_variables2.scss';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { getProgrammingAreaStatusCssClassValues, getProgrammingAreaStatusTextValues } from '../../models/AreaStatus';
import { ProgrammingAreaExtended } from '../../models/ProgrammingAreaExtended';
import { getProgrammingWorkOrientationTextValues } from '../../models/WorkOrientation';
import { AreaSection } from '../../services/dataContracts/queryStack/AreaSection';
import { AreaSectionsDetailsComponent } from './AreaSectionsDetailsComponent';
import './AreasListStyles.scss';
import { CustomColorPickerCell } from './CustomColorPickerCell';
import { CustomDatePickerCell } from './CustomDatePickerCell';

interface AreasListComponentProps {
    areas: ProgrammingAreaExtended[],
    currency: string,
    mergedProject: MergedProjectVersion,
    costRatios: CostRatio[],
    areasSections: Map<number, Map<number, AreaSection>>,
    handleEditArea: (programmingAreaId: number) => void,
    handleDeleteArea: (programmingAreaId: number) => void,
    handleDisplayAreaSections: (programmingAreaId: number) => void,
    handleHideAreaSections: (programmingAreaId: number) => void
}

export const AreasListComponent = (props: AreasListComponentProps): JSX.Element => {

    const [areas, setAreas] = useState<ProgrammingAreaExtended[]>(props.areas);
    const [skip, setSkip] = useState<number>(0);

    useEffect(() => {
        setAreas(props.areas);
    }, [props.areas])

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const onExpandChange = (event: GridExpandChangeEvent): void => {
        let areasArray = [...areas];
        let areaIndex = areasArray.findIndex(x => x.programmingAreaId === event.dataItem.programmingAreaId);
        areasArray[areaIndex].expanded = !event.dataItem.expanded;
        setAreas(areasArray);
    }

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    const programmingAreaStatusTextValues = getProgrammingAreaStatusTextValues();
    const programmingAreaStatusCssClassValues = getProgrammingAreaStatusCssClassValues();
    const programmingWorkOrientationTextValues = getProgrammingWorkOrientationTextValues();

    let gridHeight: number = parseInt(styles2.areasListHeight);
    let rowHeight: number = 51;
    let gridPageSize: number = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
    let gridStyle: React.CSSProperties = { height: gridHeight };

    return (
        <Box className="areas-list">
            <LocalizationProvider language={Localization.locale}>
                <IntlProvider locale={Localization.locale}>
                    <Grid
                        className="roadssections-grid"
                        data={areas}
                        scrollable="scrollable"
                        skip={skip}
                        total={props.areas?.length}
                        rowHeight={rowHeight}
                        pageSize={gridPageSize}
                        style={gridStyle}
                        onPageChange={pageChange}
                        expandField="expanded"
                        onExpandChange={onExpandChange}
                        detail={(_props) => <AreaSectionsDetailsComponent {..._props}
                            mergedProject={props.mergedProject}
                            costRatios={props.costRatios}
                            areasSections={props.areasSections}
                            handleEditArea={props.handleEditArea}
                        />}
                    >
                        <GridColumn field="" title="" width="70px"
                            headerCell={() => <></>}
                            cell={(cellProps) => <td>
                                {cellProps.dataItem.isEnlightened ? (
                                    <div className="light-bulb-on-icon" onClick={() => props.handleHideAreaSections(cellProps.dataItem.programmingAreaId)}>
                                        <Icon path={mdiLightbulbOn} size={1} />
                                    </div>
                                ) : (
                                    <div className="light-bulb-icon-content" onClick={() => props.handleDisplayAreaSections(cellProps.dataItem.programmingAreaId)}>
                                        <LightbulbIcon className="light-bulb-icon" />
                                    </div>
                                )}
                            </td>}
                        />
                        <GridColumn field="" title="" width="50px"
                            headerCell={() => <></>}
                            cell={(cellProps) => <td><ModeEditIcon className="edit-icon" onClick={() => props.handleEditArea(cellProps.dataItem.programmingAreaId)} /></td>}
                        />
                        <GridColumn field="hexColor" width="50px" title=""
                            headerCell={() => <></>}
                            cell={(cellProps) => <CustomColorPickerCell {...cellProps} handleColorChanged={null} disabled={true} />}
                        />
                        <GridColumn field="label" width="190px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Zone} />
                        <GridColumn field="status" width="110px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Status}
                            cell={(cellProps) =>
                                <td>
                                    <div className={`status ${programmingAreaStatusCssClassValues.get(cellProps.dataItem.status)}`}>{programmingAreaStatusTextValues.get(cellProps.dataItem.status)}</div>
                                </td>
                            }
                        />
                        <GridColumn field="workOrientation" width="150px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_WorkOrientation}
                            cell={(cellProps) => <td>{programmingWorkOrientationTextValues.get(cellProps.dataItem.workOrientation)}</td>}
                        />
                        <GridColumn field="selectedWork" width="140px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_SelectedWorks} />
                        <GridColumn field="score" width="80px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Score}
                            cell={(cellProps) =>
                                <td>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Box className="pie-chart">
                                            <PieChartComponent
                                                data={{
                                                    monitoringSectionsPercent: cellProps.dataItem.monitoringSectionsPercent,
                                                    localizedRepairSectionsPercent: cellProps.dataItem.localizedRepairSectionsPercent,
                                                    generalMaintenanceSectionsPercent: cellProps.dataItem.generalMaintenanceSectionsPercent,
                                                    reinforcementSectionsPercent: cellProps.dataItem.reinforcementSectionsPercent,
                                                    rehabilitationSectionsPercent: cellProps.dataItem.rehabilitationSectionsPercent
                                                }}
                                                innerRadius={5}
                                                outerRadius={12}
                                                paddingAngle={0}
                                                cornerRadius={0}
                                                startAngle={-180}
                                                endAngle={180}
                                                cx={10}
                                                cy={8}
                                            />
                                        </Box>
                                        <Box>{cellProps.dataItem.averageScore.toFixed(1)}</Box>
                                    </Box>
                                </td>
                            }
                        />
                        <GridColumn field="budgetAmount" width="110px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Budget} (${props.currency ?? ''})`}
                            cell={(cellProps) => <td className="column-number">{cellProps.dataItem.budgetAmount?.toFixedLocalized(0)}</td>}
                        />
                        <GridColumn field="costRatio" width="110px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_CostRatio} (${props.currency}/${symbolOfSurfaceUnit})`}
                            cell={(cellProps) => <td className={`column-number ${cellProps.dataItem.costRatioIsOverriden ? 'overriden' : ''}`}>{MeasurementSystem.getRatioInMetersOrYards(cellProps.dataItem.costRatio)?.toFixedLocalized(2)}</td>}
                        />
                        <GridColumn field="lengthInLinearMeters" width="110px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Linear} (${symbolOfLengthUnit})`}
                            cell={(cellProps) => <td className={`column-number ${cellProps.dataItem.lengthInLinearMetersIsOverridden ? 'overriden' : ''}`}>{MeasurementSystem.getLengthInMetersOrYards(cellProps.dataItem.lengthInLinearMeters)?.toFixedLocalized(0)}</td>}
                        />
                        <GridColumn field="areaInSquareMeters" width="110px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Surface} (${symbolOfSurfaceUnit})`}
                            cell={(cellProps) => <td className={`column-number ${cellProps.dataItem.areaInSquareMetersIsOverridden ? 'overriden' : ''}`}>{MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(cellProps.dataItem.areaInSquareMeters)?.toFixedLocalized(0)}</td>}
                        />
                        <GridColumn field="startDate" width="120px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Begin}
                            cell={(cellProps) => <CustomDatePickerCell {...cellProps} disabled={true} isStartDate={true} />}
                        />
                        <GridColumn field="endtDate" width="120px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_End}
                            cell={(cellProps) => <CustomDatePickerCell {...cellProps} disabled={true} isStartDate={false} />}
                        />
                        <GridColumn field="remarks" width="190px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Comment} />
                        <GridColumn field="" title="" width="50px"
                            headerCell={() => <></>}
                            cell={(cellProps) => <td><DeleteIcon className="delete-icon" onClick={() => props.handleDeleteArea(cellProps.dataItem.programmingAreaId)} /></td>}
                        />
                    </Grid>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}