import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import CompareIcon from '@mui/icons-material/Compare';
import EditIcon from '@mui/icons-material/Edit';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Button, ButtonGroup, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import iconIncognito from 'src/assets/icons/icon-incognito.svg';
import iconInfracareCarSquare from 'src/assets/icons/icon-infracare-car-square.svg';
import buildinfo from '../../../config/buildinfo.json';
import Translate from '../../../localization/Localization';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { AppModule, LocalStorage } from '../../../utils/Storage';
import { AreaSection } from '../../../views/Programmings/services/dataContracts/queryStack/AreaSection';
import { ImageExtended } from '../../../views/RoadsCondition/models/ImageExtended';
import { RoadSectionViewData } from '../../../views/RoadsCondition/models/RoadSectionViewData';
import { RoadsConditionAndScenariosShared } from '../../../views/RoadsCondition/RoadsConditionAndScenariosShared';
import { RoadSection } from '../../../views/Scenarios/models/RoadSection';
import { DetectionLabels } from '../../models/DetectionLabels';
import { getScoreClassName, getScoreLabel, getScoreType } from '../../models/ScoreTypes';
import './RoadSectionDetailsStyles.scss';

const versionMajorMinor: string = buildinfo.versionMajorMinor;
const buildNumber: string = buildinfo.build.buildNumber;

const ModuleKey = AppModule.RoadSectionDetails;
const defaultDetailsWidth = 764;

interface InformationItemProps {
    title: string,
    value: string | number
}

const InformationItem = (props: InformationItemProps): JSX.Element => {
    return (
        <Box display="flex" flexDirection="row" mb="5px" alignItems="center">
            <Box className="title" width="60%">{props.title}</Box>
            <Box className="value" width="40%">{props.value}</Box>
        </Box>
    );
}

interface ImageContentProps {
    url: string,
    videoDateTime: string,
    handleOpenImageInNewTab: (url: string) => void
}

const ImageContent = (props: ImageContentProps): JSX.Element => {
    return (
        <figure className="image-figure">
            <img src={props.url} className="img" alt="labeled content"
                onClick={() => props.handleOpenImageInNewTab(props.url)} />
            <figcaption className="caption">
                <Box className="image-date">{props.videoDateTime}</Box>
            </figcaption>
        </figure>
    );
}

interface SectionScoreComponentProps {
    selectedRoadSection: RoadSectionViewData,
    shouldEditRoadSectionScore: boolean,
    scenarioSections?: Map<number, RoadSection>,
    areaSections?: Map<number, AreaSection>,
    isEditScoreDialogOpened?: boolean,
    handleOpenEditScoreDialog: () => void
}

const SectionScoreComponent = (props: SectionScoreComponentProps): JSX.Element => {
    const { selectedRoadSection, shouldEditRoadSectionScore, scenarioSections, areaSections, isEditScoreDialogOpened } = props;

    let scoreType = selectedRoadSection.scoreType;
    let score = selectedRoadSection.score;

    let lastModificationUserFullName = selectedRoadSection.lastModificationUserFullName;
    let lastModificationDate = selectedRoadSection.lastModificationDate;
    let remarkScoreOverridden = selectedRoadSection.remarkScoreOverridden;

    if (scenarioSections) {
        let scenarioSection = scenarioSections.get(selectedRoadSection.roadSectionId);
        if (scenarioSection && scenarioSection.scenarioSectionScore.roadSectionScoreOverrideHistoryId !== selectedRoadSection.roadSectionScoreOverrideHistoryId) {
            score = scenarioSection.scenarioSectionScore.score;
            let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(score);
            scoreType = getScoreType(scoreColor);

            lastModificationUserFullName = scenarioSection.scenarioSectionScore.lastModificationUserFullName;
            lastModificationDate = scenarioSection.scenarioSectionScore.lastModificationDate;
            remarkScoreOverridden = scenarioSection.scenarioSectionScore.remarkScoreOverridden;
        }
    }

    if (areaSections) {
        let areaSection = areaSections.get(selectedRoadSection.roadSectionId);
        if (areaSection && areaSection.roadSectionScoreOverrideHistoryId !== selectedRoadSection.roadSectionScoreOverrideHistoryId) {
            score = areaSection.score;
            let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(score);
            scoreType = getScoreType(scoreColor);

            lastModificationUserFullName = areaSection.lastModificationUserFullName;
            lastModificationDate = areaSection.lastModificationDate;
            remarkScoreOverridden = areaSection.remarkScoreOverridden;
        }
    }

    return (
        <Box className="section-score-component">
            <Box className="score-info">
                <div className={`score ${getScoreClassName(scoreType)}`} >
                    {score}
                </div>
                <div className="quality">{getScoreLabel(scoreType)}</div>
                {selectedRoadSection.scoreIsOverridden &&
                    <Tooltip placement="bottom-start" classes={{ popper: 'tooltip-score' }} title={
                        <Box className="tooltip-content">
                            <Box className="score">
                                <div className="title">{`${Translate.Resources.UI_EditRoadSectionScore_AutomaticScore} : `}</div>
                                <div className={`score-color ${getScoreClassName(selectedRoadSection.computedScoreType)}`}></div>
                                <div className="title">{selectedRoadSection.computedScore === null ? getScoreLabel(selectedRoadSection.computedScoreType) : `${selectedRoadSection.computedScore} - ${getScoreLabel(selectedRoadSection.computedScoreType)}`}</div>
                            </Box>
                            <Box className="score">
                                <div className="title">{`${Translate.Resources.UI_EditRoadSectionScore_ManualScore} : `}</div>
                                <div className={`score-color ${getScoreClassName(scoreType)}`}></div>
                                <div className="title">{score === null ? getScoreLabel(scoreType) : `${score} - ${getScoreLabel(scoreType)}`}</div>
                            </Box>
                            <Box mt="10px">{`${Translate.Resources.UI_EditRoadSectionScore_By} ${lastModificationUserFullName}`}</Box>
                            <Box mt="10px">{`${Translate.Resources.UI_EditRoadSectionScore_the} ${lastModificationDate?.toLocaleDateString()} ${Translate.Resources.UI_EditRoadSectionScore_at} ${lastModificationDate?.toLocaleTimeString()}`}</Box>
                            <Box>
                                <div className="reasons-title">{`${Translate.Resources.UI_EditRoadSectionScore_ReasonsForModification} :`}</div>
                                <div className="reasons">{remarkScoreOverridden}</div>
                            </Box>
                        </Box>
                    }>
                        <PriorityHighIcon fontSize="medium" className="priority-icon" />
                    </Tooltip>
                }
            </Box>
            {shouldEditRoadSectionScore &&
                <Button className={`btn-edit-score ${isEditScoreDialogOpened ? 'edit-opened' : ''}`} onClick={props.handleOpenEditScoreDialog}>
                    <EditIcon fontSize="small" />
                </Button>
            }
        </Box>
    )
}

enum ImageDisplayedMode {
    image = "image",
    labeled = "labeled",
    combined = "combined"
}

interface RoadSectionDetailsComponentProps {
    selectedRoadSection: RoadSectionViewData,
    selectedImage: ImageExtended,
    hasScoreAnalysisAccess: boolean,
    role: string,
    shouldDisplayDetails: boolean,
    shouldEditRoadSectionScore: boolean,
    scenarioSections?: Map<number, RoadSection>,
    areaSections?: Map<number, AreaSection>,
    isEditScoreDialogOpened?: boolean,
    handleStepChanged: (selectedImage: ImageExtended, searchByNext: boolean) => void,
    handleImageChanged: (imageId: number) => void,
    handleOpenEditScoreDialog?: () => void,
    onClose: () => void
}

export const RoadSectionDetailsComponent = (props: RoadSectionDetailsComponentProps): JSX.Element => {
    const { selectedRoadSection, selectedImage, hasScoreAnalysisAccess, role } = props;

    const resizableRef = useRef(null);
    const handleRef = useRef(null);

    const [imageDisplayedMode, setImageDisplayedMode] = useState<ImageDisplayedMode>(hasScoreAnalysisAccess && role !== "CLIPLUS" && role !== "CLI" && role !== "VISU" ? ImageDisplayedMode.combined : ImageDisplayedMode.image);
    const [isDisplayedDetailsOpened, setIsDisplayedDetailsOpened] = useState<boolean>(!props.shouldDisplayDetails ? false : true);

    const defaultDetailsWidthStorage = LocalStorage.GetItem(ModuleKey, "defaultDetailsWidth");
    let storedDetailsWidth = defaultDetailsWidth;
    if (defaultDetailsWidthStorage) {
        storedDetailsWidth = Number(JSON.parse(defaultDetailsWidthStorage)) ?? storedDetailsWidth;
    }
    const [width, setWidth] = useState(storedDetailsWidth);

    useEffect(() => {
        if (handleRef.current) {
            handleRef.current.addEventListener('mousedown', onMouseDown);
        }

        return () => {
            if (handleRef.current) {
                handleRef.current.removeEventListener('mousedown', onMouseDown);
            }
        };
    }, []);

    const onMouseDown = (e) => {
        e.preventDefault();

        const startX = e.clientX;
        const startWidth = resizableRef.current.offsetWidth;

        const onMouseMove = (moveEvent) => {
            const newWidth = startWidth + (moveEvent.clientX - startX);
            if (newWidth > defaultDetailsWidth) {
                setWidth(newWidth);
                LocalStorage.SetItem(ModuleKey, "defaultDetailsWidth", newWidth);
            }
        }

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }

    const handleOpenImageInNewTab = (url: string): void => {
        let newWindow = window.open(url, '_blank');
        newWindow.addEventListener('load', function () {
            newWindow.document.title = "infraCare v" + versionMajorMinor + "." + buildNumber;
        }, false);
    }

    let displayedImages = selectedRoadSection.images.filter(x => x.roadStepId === selectedImage.roadStepId);
    let videoDateTime = new Date(selectedImage.auscultation.videoDateTime).toLocaleDateString();

    return (
        <Box ref={resizableRef} className="road-section-details" style={{ width: `${width}px` }}>
            <Box display="flex" flexDirection="column" className="road-section-details-content">
                <Box display="flex" flexDirection="row" justifyContent="space-between" className="road-section">
                    <Box display="flex" flexDirection="row">
                        <Box mr="5px" className="title-heading">{selectedRoadSection.roadLabel ?? ''}</Box>
                        <Box className="paragraphe-large">{`Section ID ${selectedRoadSection.roadSectionId}`}</Box>
                    </Box>
                    <CloseIcon fontSize="small" onClick={props.onClose} className="poper-close-icon" />
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    {props.shouldDisplayDetails &&
                        <Button className={`btn-display-details ${isDisplayedDetailsOpened ? 'details-opened' : ''}`} onClick={() => setIsDisplayedDetailsOpened(!isDisplayedDetailsOpened)}>
                            {isDisplayedDetailsOpened && <FontAwesomeIcon icon={faEye} />}
                            {!isDisplayedDetailsOpened && <FontAwesomeIcon icon={faEyeSlash} />}
                            <span className="label">{Translate.Resources.UI_RoadSectionDetails_ScoreDetail}</span>
                        </Button>
                    }
                    <Box className="navigation">
                        <Box display="flex" flexDirection="row" justifyContent="space-between" width="65px">
                            <Button className="btn-navigation" onClick={() => props.handleStepChanged(selectedImage, false)}>
                                <FirstPageIcon fontSize="small" />
                            </Button>
                            <Button className="btn-navigation" onClick={() => props.handleImageChanged(selectedImage.previousImageId)}>
                                <ChevronLeftIcon fontSize="small" />
                            </Button>
                        </Box>
                        <Box display="flex" flexDirection="row" width={isDisplayedDetailsOpened ? '282px' : '373px'} justifyContent="center">
                            <ButtonGroup variant="contained" aria-label="button group" className="image-ids-group">
                                {displayedImages.map((image, index) => {
                                    return (
                                        <Button key={`image-${index}`} className={`button ${selectedImage.imageId === image.imageId ? 'selected' : ''}`} onClick={() => props.handleImageChanged(image.imageId)}>
                                            {image.displayedId}
                                        </Button>
                                    );
                                })}
                            </ButtonGroup>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" width="65px">
                            <Button className="btn-navigation" onClick={() => props.handleImageChanged(selectedImage.nextImageId)}>
                                <ChevronRightIcon fontSize="small" />
                            </Button>
                            <Button className="btn-navigation" onClick={() => props.handleStepChanged(selectedImage, true)}>
                                <LastPageIcon fontSize="small" />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" className="details">
                    {props.shouldDisplayDetails && isDisplayedDetailsOpened &&
                        <Box display="flex" flexDirection="column" className="section-details">
                            <SectionScoreComponent selectedRoadSection={selectedRoadSection}
                                shouldEditRoadSectionScore={props.shouldEditRoadSectionScore}
                                scenarioSections={props.scenarioSections}
                                areaSections={props.areaSections}
                                isEditScoreDialogOpened={props.isEditScoreDialogOpened}
                                handleOpenEditScoreDialog={props.handleOpenEditScoreDialog}
                            />
                            <Box mb="5px" display="flex" flexDirection="column" className="detections">
                                {selectedRoadSection && selectedRoadSection.anomaliesCounters && Array.from(selectedRoadSection.anomaliesCounters).map((anomaly, index) => {
                                    if (anomaly[0] === DetectionLabels.bordure_trottoir || anomaly[0] === DetectionLabels.emergence_sur_chaussee)
                                        return <React.Fragment key={`detection-${index}`}></React.Fragment>;

                                    let count = `${Math.round((anomaly[1].percent * 100))} %`;
                                    if (anomaly[0] === DetectionLabels.nid_de_poule ||
                                        anomaly[0] === DetectionLabels.nid_de_poule_repare ||
                                        anomaly[0] === DetectionLabels.fissure_transversale ||
                                        anomaly[0] === DetectionLabels.fiss_trans_ponte ||
                                        anomaly[0] === DetectionLabels.emergence_sur_chaussee ||
                                        anomaly[0] === DetectionLabels.tranchee) {
                                        count = `${anomaly[1].counter}`;
                                    }

                                    if (anomaly[0].startsWith('marking') ||
                                        anomaly[0].startsWith('complementary') ||
                                        anomaly[0].startsWith('warning') ||
                                        anomaly[0].startsWith('information') ||
                                        anomaly[0].startsWith('regulatory')) {
                                        anomaly[0] = anomaly[0].replace(/-/g, '_');
                                    }

                                    return (
                                        <Box key={`detection-${index}`} mb="5px" display="flex" flexDirection="row" alignItems="center" className="item">
                                            <Box display="flex" alignItems="center" className="percent-count">{count} </Box>
                                            <Box className="label" >{Translate.GetAnomalyTypeLabel(anomaly[0])}</Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <InformationItem title="ml" value={`${MeasurementSystem.getLengthInMetersOrYards(selectedRoadSection.lengthInMeters).toFixed(2)} ${MeasurementSystem.getSymbolOfLengthUnit()}`} />
                                <InformationItem title={Translate.Resources.UI_RoadSectionDetails_City} value={selectedRoadSection.municipality ?? ''} />
                            </Box>
                            <Box className="title">{Translate.Resources.UI_RoadSectionDetails_StatementDate}</Box>
                            <Box className="title">{selectedRoadSection.videoDateTime ? selectedRoadSection.videoDateTime.toLocaleDateString() : ''}</Box>
                        </Box>
                    }
                    <Box display="flex" flexDirection="column" className={`images ${isDisplayedDetailsOpened ? 'details-opened' : ''}`} >
                        {props.shouldDisplayDetails &&
                            <Box display="flex" flexDirection="row" justifyContent={!isDisplayedDetailsOpened ? "space-between" : "flex-end"}>
                                {!isDisplayedDetailsOpened && <SectionScoreComponent selectedRoadSection={selectedRoadSection}
                                    shouldEditRoadSectionScore={props.shouldEditRoadSectionScore}
                                    scenarioSections={props.scenarioSections}
                                    areaSections={props.areaSections}
                                    isEditScoreDialogOpened={props.isEditScoreDialogOpened}
                                    handleOpenEditScoreDialog={props.handleOpenEditScoreDialog}
                                />}
                                <Box display="flex" flexDirection="row" className="image-group">
                                    <ButtonGroup variant="contained" aria-label="button group" className="image-btns-group">
                                        <Button className={`button ${imageDisplayedMode === ImageDisplayedMode.image ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.image)}>
                                            <img src={iconIncognito} alt="icon incognito" />
                                            {Translate.Resources.UI_RoadSectionDetails_Original}
                                        </Button>
                                        {role !== "CLIPLUS" && role !== "CLI" && role !== "VISU" && <Button className={`button ${imageDisplayedMode === ImageDisplayedMode.labeled ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.labeled)}>
                                            <img src={iconInfracareCarSquare} alt="icon infracare-car-square" />
                                            {Translate.Resources.UI_RoadSectionDetails_Detection}
                                        </Button>}
                                        {role !== "CLIPLUS" && role !== "CLI" && role !== "VISU" && <Button className={`button ${imageDisplayedMode === ImageDisplayedMode.combined ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.combined)}>
                                            <CompareIcon />
                                            {Translate.Resources.UI_RoadSectionDetails_Mix}
                                        </Button>}
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        }
                        <Box display="flex" flexDirection="column" className="images-box">
                            {imageDisplayedMode === ImageDisplayedMode.image &&
                                <Box className={`image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                    <ImageContent
                                        url={`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`}
                                        videoDateTime={videoDateTime}
                                        handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                    />
                                </Box>
                            }
                            {hasScoreAnalysisAccess && role !== "CLIPLUS" && imageDisplayedMode === ImageDisplayedMode.labeled &&
                                <Box className={`labeled-image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                    <ImageContent
                                        url={`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`}
                                        videoDateTime={videoDateTime}
                                        handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                    />
                                </Box>
                            }
                            {hasScoreAnalysisAccess && role !== "CLIPLUS" && imageDisplayedMode === ImageDisplayedMode.combined &&
                                <Box className={`combined-images ${!isDisplayedDetailsOpened ? 'details-closed' : ''}`}>
                                    <Box className={`combined-image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                        <ImageContent
                                            url={`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`}
                                            videoDateTime={videoDateTime}
                                            handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                        />
                                    </Box>
                                    <Box className={`combined-image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                        <ImageContent
                                            url={`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`}
                                            videoDateTime={videoDateTime}
                                            handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                        {props.shouldDisplayDetails && hasScoreAnalysisAccess &&
                            <Box className={`anomalies ${imageDisplayedMode === ImageDisplayedMode.combined ? 'combined' : ''}`}>
                                {selectedImage.anomaliesCounters && (Array.from(selectedImage.anomaliesCounters)).map((anomaly, index) => {
                                    if (anomaly[0].startsWith('marking') ||
                                        anomaly[0].startsWith('complementary') ||
                                        anomaly[0].startsWith('warning') ||
                                        anomaly[0].startsWith('information') ||
                                        anomaly[0].startsWith('regulatory')) {
                                        anomaly[0] = anomaly[0].replace(/-/g, '_');
                                    }

                                    return (
                                        <Box className="image-anomaly" display="flex" flexDirection="row" key={`image-anomaly${index}`}>
                                            <Box className="counter filled">{anomaly[1].counter}</Box>
                                            <Box className="label">{Translate.GetAnomalyTypeLabel(anomaly[0])}</Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <div ref={handleRef} className="resize-handle">
                <MoreVertIcon fontSize="large" className="more-vert-icon" />
            </div>
        </Box>
    );
}