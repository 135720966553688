import { Box } from '@mui/material';
import { data, MapMouseEvent } from 'azure-maps-control';
import React, { Component } from 'react';
import { ProgrammingStatus } from '../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { PageLoaderComponent } from '../../shared/components/PageLoader/PageLoaderComponent';
import { RoadSectionDetailsComponent } from '../../shared/components/RoadSectionDetails/RoadSectionDetailsComponent';
import { Currencies } from '../../shared/models/Currencies';
import { MeasurementSystemType } from '../../shared/models/MeasurementSystemType';
import { Point } from '../../shared/models/Point';
import { ShapeEntityType } from '../../shared/models/ShapeEntityType';
import { UserRoleIds } from '../../shared/models/UserRoleIds';
import { MeasurementSystem } from '../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { Programming } from '../Programmings/services/dataContracts/queryStack/Programming';
import { ImageExtended } from '../RoadsCondition/models/ImageExtended';
import { MergedProjectVersion } from '../RoadsCondition/models/MergedProjectVersion';
import { RoadSectionViewData } from '../RoadsCondition/models/RoadSectionViewData';
import { RouteLocationStateModel } from '../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../RoadsCondition/RoadsConditionAndScenariosShared';
import { RoadsConditionApiClient } from '../RoadsCondition/services/RoadsCondition/RoadsConditionApiClient';
import { ActionsMenuComponent } from './components/ActionsMenuComponent';
import { areaConeEntityTypes, FollowUpWorksMapComponent } from './components/FollowUpWorksMapComponent';
import { RoadWorksInformationDrawer } from './components/RoadWorksInformationDrawer';
import './FollowUpWorksStyles.scss';
import { AreaShapeProps } from './models/AreaShapeProps';
import { FollowUpWorksApiClient } from './services/FollowUpWorksApiClient';

interface FollowUpWorksViewState {
    loading: boolean,
    mergedProject: MergedProjectVersion,
    programmings: Programming[],
    activeQualities: Set<number>,
    currentMeasurementSystemType: MeasurementSystemType,
    isRoadWorksInformationDrawerOpened: boolean,
    selectedAreas: Map<number, AreaShapeProps>,
    currencySymbole: string,
    isAddressIconPinOnMap: boolean,
    addressIconPinPositionOnMap: data.Position,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    isRoadSectionDetailsOpened: boolean,
    selectedImage: ImageExtended,
    selectedRoadSection: RoadSectionViewData
}

const initialState: FollowUpWorksViewState = {
    loading: false,
    mergedProject: null,
    programmings: null,
    activeQualities: new Set<number>([]),
    currentMeasurementSystemType: null,
    isRoadWorksInformationDrawerOpened: false,
    selectedAreas: new Map<number, AreaShapeProps>(),
    currencySymbole: null,
    isAddressIconPinOnMap: false,
    addressIconPinPositionOnMap: null,
    selectedProgrammings: [],
    selectedYears: [],
    activeStatus: new Set<ProgrammingStatus>([ProgrammingStatus.toBeCompleted, ProgrammingStatus.finished]),
    isRoadSectionDetailsOpened: false,
    selectedImage: null,
    selectedRoadSection: null
};

interface FollowUpWorksViewProps {
    role: string
}

export class FollowUpWorksView extends Component<RouteComponentProps & FollowUpWorksViewProps, FollowUpWorksViewState> {
    _isMounted: boolean;
    locationGeometry: Point;
    projectVersionId: number;
    projectId: string;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    hasScoreAnalysisAccess: boolean;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.hasScoreAnalysisAccess = this.props.role === UserRoleIds.administrator ||
            this.props.role === UserRoleIds.agencyPlus ||
            this.props.role === UserRoleIds.agencyTech ||
            this.props.role === UserRoleIds.customerPlus ||
            this.props.role === UserRoleIds.customerTech;

        initialState.currentMeasurementSystemType = MeasurementSystem.getCurrentType();
        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.locationGeometry = locationState.locationGeometry;
        this.projectVersionId = locationState.projectVersionId;
        this.projectId = locationState.projectId;

        this.setState({
            loading: true
        });

        await Promise.all([
            RoadsConditionAndScenariosShared.getMergedProject(this.projectVersionId, this.mergedProjectAuscultationsCache, this.projectVersionsCache),
            FollowUpWorksApiClient.GetRoadWorks(this.projectId),
            FollowUpWorksApiClient.GetProjectCurrency(this.projectId)
        ])
            .then((results) => {
                let mergedProject = results[0];
                let programmings = results[1].data;
                let currencySymbole = Currencies[results[2].data];

                this.setState({
                    mergedProject,
                    programmings,
                    currencySymbole,
                    loading: false
                });
            });
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>): void => {
        this.setState({
            activeQualities: activeQualities
        });
    }

    openWorksInformationDrawer = (selectedAreas: Map<number, AreaShapeProps>): void => {
        this.setState({
            isRoadWorksInformationDrawerOpened: true,
            selectedAreas: selectedAreas
        });
    }

    closeWorksInformationDrawer = (): void => {
        this.setState({
            isRoadWorksInformationDrawerOpened: false,
            selectedAreas: new Map<number, AreaShapeProps>()
        });
    }

    handleAreaEnlightenmentChange = (programmingAreaId: number, state: FollowUpWorksViewState): void => {
        let areas = new Map(state.selectedAreas);
        let areaShapeProperties = areas.get(programmingAreaId);
        areaShapeProperties.isEnlightened = !areaShapeProperties.isEnlightened;
        areas.set(programmingAreaId, areaShapeProperties);
        this.setState({
            selectedAreas: areas
        });
    }

    removeAddressIconPin = (): void => {
        this.setState({
            isAddressIconPinOnMap: false,
            addressIconPinPositionOnMap: null
        });
    }

    createAddressIconPin = (position: data.Position): void => {
        this.setState({
            isAddressIconPinOnMap: true,
            addressIconPinPositionOnMap: position
        });
    }

    handleDisplayAreasFromWorksFilter = (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>): void => {
        this.setState({
            isRoadWorksInformationDrawerOpened: false,
            selectedAreas: new Map<number, AreaShapeProps>(),
            selectedProgrammings,
            selectedYears,
            activeStatus
        });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            currentMeasurementSystemType: measurementSystemType
        });
    }

    handleCloseRoadSectionDetails = (): void => {
        this.setState({
            selectedImage: null,
            selectedRoadSection: null,
            isRoadSectionDetailsOpened: false
        });
    }

    handleImageChanged = async (imageId: number, state: FollowUpWorksViewState): Promise<void> => {
        if (imageId === null)
            return;

        let image: ImageExtended = state.mergedProject.imagesDico.get(imageId);
        if (image) {
            let roadSection = state.mergedProject.roadsSections.get(image.roadSectionId);

            if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
            }

            this.showImageAndSectionDetails(image, state.mergedProject);
        }
        else {
            await RoadsConditionApiClient.GetPerRoadSectionImagesFromImageId(imageId)
                .then((res) => {
                    let images = res.data as ImageExtended[];
                    let roadSection = state.mergedProject.roadsSections.get(images[0].roadSectionId);
                    RoadsConditionAndScenariosShared.buildViewDataFromSectionImages(state.mergedProject, images, roadSection);

                    image = state.mergedProject.imagesDico.get(imageId);

                    if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                        RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
                    }

                    this.showImageAndSectionDetails(image, state.mergedProject);
                });
        }
    }

    handleStepChanged = (selectedImage: ImageExtended, searchByNext: boolean, state: FollowUpWorksViewState): void => {
        let image: ImageExtended = state.mergedProject.imagesDico.get(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId);
        while (image?.roadStepId === selectedImage.roadStepId) {
            image = state.mergedProject.imagesDico.get(searchByNext ? image.nextImageId : image.previousImageId);
        }

        if (image) {
            this.handleImageChanged(image?.imageId, state);
        }
        else {
            this.handleImageChanged(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId, state);
        }
    }

    showImageAndSectionDetails = (image: ImageExtended, mergedProject: MergedProjectVersion): void => {
        this.setState((prevState, props) => {
            let roadSection = mergedProject.roadsSections.get(image.roadSectionId);

            return {
                selectedImage: image,
                selectedRoadSection: roadSection,
                isRoadSectionDetailsOpened: true,
                mergedProject: mergedProject
            };
        });
    }

    handleDisplayImageFromSectionClicked = async (e: MapMouseEvent): Promise<void> => {
        let mergedProject = this.mergedProjectAuscultationsCache.get(this.projectVersionId);
        let isAltKeyPressed = (e.originalEvent as any).altKey;
        let isCtrlKeyPressed = (e.originalEvent as any).ctrlKey;
        let isShiftKeyPressed = (e.originalEvent as any).shiftKey;

        let clickedSectionId: number = null;
        for (var i = 0; i < e.shapes.length; i++) {
            let shape = (e.shapes[i] as any);
            let entityType = shape?.data?.properties?.EntityType;
            if (entityType === ShapeEntityType.section ||
                entityType === ShapeEntityType.areaEnlightened ||
                entityType === areaConeEntityTypes.workDoneArea ||
                entityType === areaConeEntityTypes.workDoneMultiArea ||
                entityType === areaConeEntityTypes.workToDoArea ||
                entityType === areaConeEntityTypes.workToDoMultiArea ||
                entityType === areaConeEntityTypes.workToDoAndWorkDoneMultiArea) {
                clickedSectionId = shape?.data?.properties?.RoadSectionId;
                break;
            }
        }

        let section: RoadSectionViewData = mergedProject.roadsSections.get(clickedSectionId);
        if (!section || section.roadSectionScoreId === null)
            return;

        console.log("section : ");
        console.log(section);

        let clickedPosition: data.Position = e.position;
        if (clickedPosition) {
            if (!isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
                //Click simple: dans ce cas il faut positionner l'icone voiture dans la position de l'image la plus proche de la sélection
                let image = await RoadsConditionAndScenariosShared.handleSectionClicked(section, clickedPosition, mergedProject, this.hasScoreAnalysisAccess, false);
                this.showImageAndSectionDetails(image, mergedProject);
                return;
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const state = this.state;

        return (
            <Box className="follow-up-works">
                {state.loading ? <PageLoaderComponent /> : null}
                <ActionsMenuComponent
                    activeQualities={state.activeQualities}
                    mergedProject={state.mergedProject}
                    programmings={state.programmings}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    isRoadSectionDetailsOpened={state.isRoadSectionDetailsOpened}
                    handleDisplaySections={this.handleDisplaySectionsFromQualityFilters}
                    removeAddressIconPin={this.removeAddressIconPin}
                    createAddressIconPin={this.createAddressIconPin}
                    handleDisplayAreasFromWorksFilter={this.handleDisplayAreasFromWorksFilter}
                />
                <Box className="main-content">
                    {state.isRoadSectionDetailsOpened &&
                        <RoadSectionDetailsComponent
                            selectedRoadSection={state.selectedRoadSection}
                            selectedImage={state.selectedImage}
                            hasScoreAnalysisAccess={this.hasScoreAnalysisAccess}
                            role={this.props.role}
                            shouldDisplayDetails={true}
                            shouldEditRoadSectionScore={false}
                            handleStepChanged={(selectedImage: ImageExtended, searchByNext: boolean) => this.handleStepChanged(selectedImage, searchByNext, state)}
                            handleImageChanged={(imageId) => this.handleImageChanged(imageId, state)}
                            onClose={() => this.handleCloseRoadSectionDetails()}
                        />
                    }
                    <Box className="informations-and-map-content">
                        <FollowUpWorksMapComponent
                            locationGeometry={this.locationGeometry}
                            mergedProject={state.mergedProject}
                            activeQualities={state.activeQualities}
                            programmings={state.programmings}
                            selectedProgrammings={state.selectedProgrammings}
                            selectedYears={state.selectedYears}
                            activeStatus={state.activeStatus}
                            isAddressIconPinOnMap={state.isAddressIconPinOnMap}
                            addressIconPinPositionOnMap={state.addressIconPinPositionOnMap}
                            selectedAreas={state.selectedAreas}
                            isRoadWorksInformationDrawerOpened={state.isRoadWorksInformationDrawerOpened}
                            selectedImage={state.selectedImage}
                            openWorksInformationDrawer={this.openWorksInformationDrawer}
                            closeWorksInformationDrawer={this.closeWorksInformationDrawer}
                            handleDisplayImageFromSectionClicked={this.handleDisplayImageFromSectionClicked}
                            handleCLoseRoadSectionDetails={this.handleCloseRoadSectionDetails}
                        />
                        {state.isRoadWorksInformationDrawerOpened &&
                            <RoadWorksInformationDrawer
                                selectedAreas={state.selectedAreas}
                                currencySymbole={state.currencySymbole}
                                handleClose={this.closeWorksInformationDrawer}
                                handleAreaEnlightenmentChange={(programmingAreaId: number) => this.handleAreaEnlightenmentChange(programmingAreaId, state)}
                            />
                        }
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(FollowUpWorksView));
