import { Box } from '@mui/material';
import { GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { data, layer, Map as AzureMap, MapMouseEvent, Popup, Shape, source } from 'azure-maps-control';
import { debounce, isEqual, orderBy } from 'lodash';
import React, { Component } from 'react';
import azureMapPinIcon from 'src/assets/icons/icon-azure-map-pin.png';
import { PageLoaderComponent } from '../../shared/components/PageLoader/PageLoaderComponent';
import { RoadSectionDetailsComponent } from '../../shared/components/RoadSectionDetails/RoadSectionDetailsComponent';
import { createMap, createShape, getMapChoiceValue, getSectionShapeId, getSelectedSectionShapeId, hideSectionSelectedShape, sectionWidth, setMapCursor, setMapZoom, showSectionSelectedShape, transparentColor } from '../../shared/Map/MapUtils';
import { MapCursorMode } from '../../shared/models/MapCursorMode';
import { MeasurementSystemType } from '../../shared/models/MeasurementSystemType';
import { ShapeEntityType } from '../../shared/models/ShapeEntityType';
import { UserRoleIds } from '../../shared/models/UserRoleIds';
import '../../utils/Map';
import { MeasurementSystem } from '../../utils/MeasurementSystem';
import '../../utils/String';
import Utilities from '../../utils/Utilities';
import { RouteComponentProps, withRouter } from '../../withRouter';
import styles from '../../_variables.scss';
import styles2 from '../../_variables2.scss';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { ScoringParameter } from '../Home/services/dataContracts/queryStack/ScoringParameter';
import { ActionsMenuComponent } from './components/ActionsMenuComponent';
import { IndicatorStatsComponent } from './components/IndicatorStatsComponent';
import { MapWithImagesComponent } from './components/MapWithImagesComponent';
import { RoadsDrawerComponent } from './components/RoadsDrawerComponent';
import { AuscultationExtended } from './models/AuscultationExtended';
import { ImageExtended } from './models/ImageExtended';
import { MergedProjectVersion } from './models/MergedProjectVersion';
import { OtherAttributes } from './models/OtherAttributes';
import { ProjectVersionExtended } from './models/ProjectVersionExtended';
import { RoadSectionViewData } from './models/RoadSectionViewData';
import { RoadsSectionsSummaryModel } from './models/RoadsSectionsSummaryModel';
import { RoadTrunkLabelScoreExtended } from './models/RoadTrunkLabelScoreExtended';
import { RouteLocationStateModel } from './models/RouteLocationStateModel';
import { ScoreColors } from './models/ScoreColors';
import { ScoreTypesColors } from './models/ScoreTypesColors';
import { carDatasourceId, carIconSymbolLayerId, mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from './RoadsConditionAndScenariosShared';
import './RoadsConditionStyles.scss';
import { Environment } from './services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from './services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from './services/RoadsCondition/dataContracts/queryStack/Manager';
import { RoadTrunkLabelScore } from './services/RoadsCondition/dataContracts/queryStack/RoadTrunkLabelScore';
import { StepImageAnomalies } from './services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from './services/RoadsCondition/dataContracts/queryStack/Traffic';
import { RoadsConditionApiClient } from './services/RoadsCondition/RoadsConditionApiClient';

const projectPinId = "projectPinId";
const projectSymbolLayerId = "projectSymbolLayerId";

const cursorAutoClassName = "cursor-auto";
const cursorCrosshairClassName = "cursor-crosshair";

interface RoadsConditionViewState2 {
    loading: boolean,
    statsOpened: boolean,
    openedRoadsDrawer: boolean,
    projectVersion: ProjectVersionExtended,
    mergedProject: MergedProjectVersion,
    selectedRoadsSectionsSummary: RoadsSectionsSummaryModel,
    roadTrunkLabelsScores: Map<string, RoadTrunkLabelScoreExtended>,
    displayedSectionsIds: Set<number>,
    selectedImage: ImageExtended,
    selectedRoadSection: RoadSectionViewData,
    isRoadSectionDetailsOpened: boolean,
    activeAnomalies: Set<string>,
    activeMarkingDetections: Set<string>,
    activeComplementaryDetections: Set<string>,
    activeWarningDetections: Set<string>,
    activeInformationDetections: Set<string>,
    activeRegulatoryDetections: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    perStepImagesAnomalies: Map<number, StepImageAnomalies[]>,
    measurementSystemType: MeasurementSystemType,
    projectVersionAnomalies: Map<string, string>,
    projectVersionMarking: Map<string, string>,
    projectVersionComplementary: Map<string, string>,
    projectVersionWarning: Map<string, string>,
    projectVersionInformation: Map<string, string>,
    projectVersionRegulatory: Map<string, string>
}

interface RoadsConditionViewProps2 {
    role: string
}

const initialState: RoadsConditionViewState2 = {
    loading: false,
    openedRoadsDrawer: false,
    statsOpened: false,
    projectVersion: null,
    mergedProject: null,
    selectedRoadsSectionsSummary: null,
    roadTrunkLabelsScores: new Map<string, RoadTrunkLabelScoreExtended>(),
    displayedSectionsIds: new Set<number>(),
    selectedImage: null,
    selectedRoadSection: null,
    isRoadSectionDetailsOpened: false,
    activeAnomalies: new Set<string>(),
    activeMarkingDetections: new Set<string>(),
    activeComplementaryDetections: new Set<string>(),
    activeWarningDetections: new Set<string>(),
    activeInformationDetections: new Set<string>(),
    activeRegulatoryDetections: new Set<string>(),
    activeQualities: new Set<number>([]),
    activeMunicipalities: new Set<string>(),
    activeDistricts: new Set<string>(),
    activeCollaborativeDevelopmentZones: new Set<string>(),
    activeHierarchies: new Set<Hierarchy>(),
    activeTraffics: new Set<Traffic>(),
    activeEnvironments: new Set<Environment>(),
    activeManagers: new Set<Manager>(),
    activeImportances: new Set<string>(),
    activeOtherAttributes: new Set<string>(),
    perStepImagesAnomalies: new Map<number, StepImageAnomalies[]>(),
    measurementSystemType: null,
    projectVersionAnomalies: new Map<string, string>(),
    projectVersionMarking: new Map<string, string>(),
    projectVersionComplementary: new Map<string, string>(),
    projectVersionWarning: new Map<string, string>(),
    projectVersionInformation: new Map<string, string>(),
    projectVersionRegulatory: new Map<string, string>()
}

export class RoadsConditionView2 extends Component<RouteComponentProps & RoadsConditionViewProps2, RoadsConditionViewState2> {
    _isMounted: boolean;
    hasScoreAnalysisAccess: boolean;
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>;
    inputSearchImagesRef: React.RefObject<HTMLInputElement>;
    selectedProject: ProjectVersionExtended;
    projectPopup: Popup;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    map: AzureMap;
    currentMapCursorClassName: string;
    //NOTE CMA HGA afin de pouvoir désenregistrer les évenements asssociés, on a besoin de garder les pointeurs vers ces handlers car ce sont des fonctions lambda
    projectPinClickHandler: () => void;
    anomalyPointClickHandler: (e: void | MapMouseEvent | layer.Layer) => void;

    constructor(props: RouteComponentProps & RoadsConditionViewProps2) {
        super(props);

        this.hasScoreAnalysisAccess = this.props.role === UserRoleIds.administrator ||
            this.props.role === UserRoleIds.agencyPlus ||
            this.props.role === UserRoleIds.agencyTech ||
            this.props.role === UserRoleIds.customerPlus ||
            this.props.role === UserRoleIds.customerTech;

        this.inputSearchRoadsRef = React.createRef();
        this.inputSearchImagesRef = React.createRef();
        this.map = null;
        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.currentMapCursorClassName = cursorAutoClassName;

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();
        initialState.activeQualities = RoadsConditionAndScenariosShared.getInitialActiveQualities();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let routeLocationState = sessionStorage.getItem("routeLocationState");
        let locationState = JSON.parse(routeLocationState) as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        await this.initViewData(locationState, initialState);
    }

    async componentDidUpdate(prevProps: RouteComponentProps & RoadsConditionViewProps2): Promise<void> {
        let routeLocationState = sessionStorage.getItem("routeLocationState");
        let locationState = JSON.parse(routeLocationState) as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        if (this.selectedProject && (this.selectedProject.projectVersionId !== locationState.projectVersionId
            || this.selectedProject.projectId !== locationState.projectId)) {
            await this.initViewData(locationState, initialState);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.disposeMap();
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType: measurementSystemType
        });
    }

    disposeMap = (): void => {
        this.projectPinClickHandler = null;
        this.anomalyPointClickHandler = null;

        this.map?.dispose();
    }

    initViewData = async (locationState: RouteLocationStateModel, state: RoadsConditionViewState2): Promise<void> => {
        this.disposeMap();

        let mapChoice = getMapChoiceValue();
        this.map = createMap('AzureMap', 4, locationState.locationGeometry, mapChoice);

        this.map.resize("100%", styles2.roadConditionMapContentWidthWithoutDrawer);

        if (locationState.projectVersionId && this.props.role) {
            let projectVersionId = locationState.projectVersionId;

            this.selectedProject = null;
            this.setState({ loading: true });

            //NOTE AME CMA : 
            // On doit s'assurer que le "mergedProject" de la version courante est toujours chargée et en cache. 
            // Dans certaines parties du code (ex: ajout d'un scenario) on s'attends a ce que ce soit bien le cas.
            let mergedProject = await RoadsConditionAndScenariosShared.getMergedProject(
                projectVersionId,
                this.mergedProjectAuscultationsCache,
                this.projectVersionsCache);

            this.selectedProject = mergedProject.projectVersion;
            this.projectVersionsCache.set(projectVersionId, this.selectedProject);

            const selectedRoadsSectionsSummary = this.computeSummary(mergedProject.roadsSections);

            const roadTrunkLabelsScores = this.buildExtendedRoadTrunkLabelsScoreRanking(mergedProject);

            this.initMap(this.map, () => {
                let displayedSectionsIds = this.createMapSectionsShapes(mergedProject, state.activeQualities);
                this.setState({
                    displayedSectionsIds
                });

                setMapZoom(this.map, mergedProject, 150);
            });

            this.resizeMapWhenRoadsConditionView(this.map, false);

            if (this._isMounted) {
                this.setState({
                    projectVersion: mergedProject.projectVersion,
                    mergedProject: mergedProject,
                    selectedRoadsSectionsSummary: selectedRoadsSectionsSummary,
                    roadTrunkLabelsScores: roadTrunkLabelsScores,
                    loading: false
                });
            }
        }
    }

    resizeMapWhenRoadsConditionView = (map: AzureMap, openedDrawer: boolean): void => {
        let height = styles2.roadConditionMapContentHeight;
        let width = "";

        if (openedDrawer) {
            width = styles2.roadConditionMapContentWidthWithOpenedRoadsDrawer;
        }
        else if (!openedDrawer) {
            width = styles2.roadConditionMapContentWidthWithoutDrawer;
        }

        map.resize(height, width);
    }

    createMainDatasource = (mergedProject: MergedProjectVersion): source.DataSource => {
        var datasource = new source.DataSource(mainDatasourceId);
        this.map.sources.add(datasource);

        var roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        this.map.layers.add(roadLayer);

        this.projectPinClickHandler = () => this.handleProjectPinClick(mergedProject);

        var projectPinSymbolLayer = this.createProjectPinLayer(datasource);
        this.map.events.add('mouseover', projectPinSymbolLayer, this.handleProjectPinMouseover);
        this.map.events.add('mouseout', projectPinSymbolLayer, this.handleProjectPinMouseout);
        this.map.events.add('mousedown', projectPinSymbolLayer, this.projectPinClickHandler);

        if (mergedProject.projectLocationGeometry) {
            var projectPin = new data.Feature(new data.Point(mergedProject.projectLocationGeometry.coordinates), { EntityType: ShapeEntityType.project, projectLabel: mergedProject.projectLabel, projectPin: true }, projectPinId);
            datasource.add(projectPin);
        }

        return datasource;
    }

    removeMainDatasource = (): void => {
        var existingRoadLayer = this.map.layers.getLayerById(roadLayerId);
        if (existingRoadLayer) {
            this.map.layers.remove(roadLayerId);
        }

        var existingProjectSymbolLayer = this.map.layers.getLayerById(projectSymbolLayerId);
        if (existingProjectSymbolLayer) {
            this.map.events.remove('mouseover', existingProjectSymbolLayer, this.handleProjectPinMouseover);
            this.map.events.remove('mouseout', existingProjectSymbolLayer, this.handleProjectPinMouseout);
            this.map.events.remove('mousedown', existingProjectSymbolLayer, this.projectPinClickHandler);

            this.map.layers.remove(projectSymbolLayerId);
        }

        var existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId);
        if (existingCarIconLayer) {
            this.map.layers.remove(carIconSymbolLayerId);
        }

        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        datasource.clear();
        this.map.sources.remove(datasource);
    }

    recreateMainDatasource = (mergedProject: MergedProjectVersion): source.DataSource => {
        var datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource) {
            this.removeMainDatasource();
        }

        datasource = this.createMainDatasource(mergedProject);
        return datasource;
    }

    anomalyLayerMouseover = (): void => {
        this.handleLayerMouseover(MapCursorMode.Pointer);
    }

    anomalyLayerMouseout = (): void => {
        this.handleLayerMouseout();
    }

    handleAnomalyPointClicked = async (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion): Promise<void> => {
        e = e as MapMouseEvent;
        let shape = e.shapes[0] as Shape;
        let shapeProps = shape.getProperties();
        let section: RoadSectionViewData = mergedProject.roadsSections.get(shapeProps.sectionId);
        if (section) {
            let clickedPosition = e.position;
            let image = await RoadsConditionAndScenariosShared.handleSectionClicked(section, clickedPosition, mergedProject, this.hasScoreAnalysisAccess, true, this.map);
            this.showImageAndSectionDetails(image, mergedProject);
        }
    }

    displayCarIconLayer = (map: AzureMap, visible: boolean): void => {
        let carIconLayer = map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
        if (carIconLayer) {
            let options = carIconLayer.getOptions();
            options.visible = visible;
            carIconLayer.setOptions(options);
        }
    }

    displayProjectPinLayer = (map: AzureMap, visible: boolean): void => {
        var projectPinLayer = map.layers.getLayerById(projectSymbolLayerId) as layer.SymbolLayer;
        var options = projectPinLayer?.getOptions();
        if (projectPinLayer && (!options || (options && options.visible !== visible))) {
            projectPinLayer.setOptions({
                iconOptions: {
                    image: 'azure-map-pin-icon'
                },
                visible: visible,
                //Only render Point with property projectPin.
                filter: ['has', "projectPin"]
            });
        }
    }

    createProjectPinLayer = (datasource: source.DataSource): layer.SymbolLayer => {
        var projectPinSymbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, projectSymbolLayerId,
            {
                iconOptions: {
                    image: 'azure-map-pin-icon'
                },
                //Only render Point with property projectPin.
                filter: ['has', "projectPin"]
            });

        this.map.imageSprite.add('azure-map-pin-icon', azureMapPinIcon);
        this.map.layers.add(projectPinSymbolLayer);

        return projectPinSymbolLayer;
    }

    handleZoomend = (projectPopup: Popup): void => {
        var zoom = this.map.getCamera().zoom;

        if (zoom <= 8) {
            this.displayRoadLayer(this.map, false);
            this.displayCarIconLayer(this.map, false);

            this.displayProjectPinLayer(this.map, true);

            // Pin visible donc attacher la popup 
            projectPopup.attach(this.map);
        }
        else if (zoom > 8) {
            this.displayRoadLayer(this.map, true);
            this.displayCarIconLayer(this.map, true);

            this.displayProjectPinLayer(this.map, false);

            // Pin invisible donc remove la popup 
            projectPopup.remove();
        }
    }

    displayRoadLayer = (map: AzureMap, isVisible: boolean): void => {
        var roadLayer = map.layers.getLayerById(roadLayerId) as layer.LineLayer;
        var options = roadLayer?.getOptions();
        if (roadLayer && isVisible !== options.visible) {
            // rendre les sections invisibles
            roadLayer.setOptions({ strokeColor: ['get', 'strokeColor'], strokeWidth: ['get', 'strokeWidth'], lineJoin: 'round', lineCap: 'round', visible: isVisible });
        }
    }

    createMapSectionsShapes = (mergedProject: MergedProjectVersion, activeQualities: Set<number>): Set<number> => {
        let datasource = this.recreateMainDatasource(mergedProject);

        let displayedSectionsIds = new Set<number>();
        mergedProject.roadsSections.forEach((section: RoadSectionViewData) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let selectedSectionShapeId = getSelectedSectionShapeId(roadSectionId);
            let selectedSectionShape = createShape(coordinates, selectedSectionShapeId, transparentColor, 0, ShapeEntityType.sectionSelected, roadSectionId);
            datasource.add(selectedSectionShape);

            let shapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = styles.unfilteredSectionColor;

            if (activeQualities.has(section.score)) {
                strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
            }

            let sectionShape = createShape(coordinates, shapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType, section.score, section.municipality, section.district, section.collaborativeDevelopmentZone);
            datasource.add(sectionShape);

            displayedSectionsIds.add(roadSectionId);
        });

        return displayedSectionsIds;
    }

    initMap = (map: AzureMap, callback: () => void): void => {

        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {

            if (!this._isMounted)
                return;

            this.projectPopup = new Popup({ closeButton: false });

            map.events.add('zoomend', () => this.handleZoomend(this.projectPopup));

            if (callback) {
                callback();
            }
        });
    }

    handleMapClick = async (e: MapMouseEvent, state: RoadsConditionViewState2): Promise<void> => {
        const { mergedProject } = state;

        var isAltKeyPressed = (e.originalEvent as any).altKey;
        var isCtrlKeyPressed = (e.originalEvent as any).ctrlKey;
        var isShiftKeyPressed = (e.originalEvent as any).shiftKey;

        var clickedSectionId: number = null;
        var shape = (e.shapes[0] as any);
        if (shape) {
            clickedSectionId = shape.id ?? shape?.data?.properties?.RoadSectionId;
        }

        var section: RoadSectionViewData = mergedProject.roadsSections.get(clickedSectionId);
        if (!section || section.roadSectionScoreId === null)
            return;

        console.log("section : ");
        console.log(section);

        var clickedPosition: data.Position = e.position;
        if (clickedPosition) {
            if (!isAltKeyPressed && !isCtrlKeyPressed && !isShiftKeyPressed) {
                //Click simple: dans ce cas il faut positionner l'icone voiture dans la position de l'image la plus proche de la sélection
                let image = await RoadsConditionAndScenariosShared.handleSectionClicked(section, clickedPosition, state.mergedProject, this.hasScoreAnalysisAccess, true, this.map);
                this.showImageAndSectionDetails(image, mergedProject);
                return;
            }
        }
    }

    handleProjectPinClick = (mergedProject: MergedProjectVersion): void => {
        this.map.setCamera({
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        });
    }

    handleLayerMouseover = (mode: MapCursorMode): void => {
        setMapCursor(this.map, mode);
    }

    handleLayerMouseout = (): void => {
        let cursorMode = this.currentMapCursorClassName === cursorCrosshairClassName ? MapCursorMode.Crosshair : MapCursorMode.Auto;
        setMapCursor(this.map, cursorMode);
    }

    handleProjectPinMouseover = (e: MapMouseEvent): void => {
        this.handleLayerMouseover(MapCursorMode.Pointer);

        let locationState = this.props.location.state as RouteLocationStateModel;
        let projectLabel = locationState?.label;

        this.projectPopup.setOptions({
            content: `<div style="padding:10px;">${projectLabel}</div>`,
            position: e.position,
            pixelOffset: [0, -18]
        });
        this.projectPopup.open(this.map);
    }

    handleProjectPinMouseout = (): void => {
        this.handleLayerMouseout();
        this.projectPopup.close();
    }

    buildExtendedRoadTrunkLabelsScoreRanking = (mergedProject: MergedProjectVersion): Map<string, RoadTrunkLabelScoreExtended> => {
        let roadTrunkLabelsScoreRanking = mergedProject.roadTrunkLabelsScoreRanking;
        let roadTrunkLabelsScores = new Map<string, RoadTrunkLabelScoreExtended>();
        if (roadTrunkLabelsScoreRanking) {

            roadTrunkLabelsScoreRanking.roadTrunkLabelScores.forEach((element: RoadTrunkLabelScore, index: number) => {
                let fixedAverageScore = element.averageScore;
                let sectionIds: number[] = element.roadSectionIds;
                let sections: RoadSectionViewData[] = [];
                sectionIds.forEach((sectionId: number) => {
                    let section = mergedProject.roadsSections.get(sectionId);
                    section.isSelected = false;
                    sections.push(section);
                });

                let roadLabelLengthInMeters = Math.round(element.lengthInMetersScored)
                let monitoringSections = sections.filter(s => s.score === 10 || s.score === 9 || s.score === 8);
                let monitoringSectionsLengthInMeters: number = 0;
                monitoringSections.forEach(section => {
                    monitoringSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let monitoringSectionsPercent = (monitoringSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let localizedRepairSections = sections.filter(s => s.score === 7 || s.score === 6);
                let localizedRepairSectionsLengthInMeters: number = 0;
                localizedRepairSections.forEach(section => {
                    localizedRepairSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let localizedRepairSectionsPercent = (localizedRepairSectionsLengthInMeters * 100) / roadLabelLengthInMeters;;

                let generalMaintenanceSections = sections.filter(s => s.score === 5 || s.score === 4);
                let generalMaintenanceSectionsLengthInMeters: number = 0;
                generalMaintenanceSections.forEach(section => {
                    generalMaintenanceSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let generalMaintenanceSectionsPercent = (generalMaintenanceSectionsLengthInMeters * 100) / roadLabelLengthInMeters;;

                let reinforcementSections = sections.filter(s => s.score === 3 || s.score === 2);
                let reinforcementSectionsLengthInMeters: number = 0;
                reinforcementSections.forEach(section => {
                    reinforcementSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let reinforcementSectionsPercent = (reinforcementSectionsLengthInMeters * 100) / roadLabelLengthInMeters;;

                let rehabilitationSections = sections.filter(s => s.score === 1);
                let rehabilitationSectionsLengthInMeters: number = 0;
                rehabilitationSections.forEach(section => {
                    rehabilitationSectionsLengthInMeters += section.score && section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
                });
                let rehabilitationSectionsPercent = (rehabilitationSectionsLengthInMeters * 100) / roadLabelLengthInMeters;

                let item: RoadTrunkLabelScoreExtended = {
                    ...element,
                    index: index + 1,
                    fixedAverageScore: fixedAverageScore?.toFixedLocalized(2, 2),
                    lengthInMeters: Math.round(element.lengthInMetersScored),
                    hiddenFixedAverageScoreForSort: fixedAverageScore,
                    isVisible: true,
                    isSelected: false,
                    labelLowerWithoutDiacritics: element.label?.toLowerCase()?.removeDiacritics(),
                    hiddenLabelForSort: element.labelIsRoadTrunkId ? `zzz${element.label}` : element.label,
                    sectionIds: sectionIds,
                    sections: sections,
                    expanded: false,
                    monitoringSectionsPercent: monitoringSectionsPercent,
                    localizedRepairSectionsPercent: localizedRepairSectionsPercent,
                    generalMaintenanceSectionsPercent: generalMaintenanceSectionsPercent,
                    reinforcementSectionsPercent: reinforcementSectionsPercent,
                    rehabilitationSectionsPercent: rehabilitationSectionsPercent
                };
                roadTrunkLabelsScores.set(item.label, item);
            });
        }

        return roadTrunkLabelsScores;
    }

    computeSummary = (roadsSections: Map<number, RoadSectionViewData>): RoadsSectionsSummaryModel => {
        if (roadsSections) {
            let totalSectionsLengthInMeters = 0;
            let totalSectionsSurface = 0;

            let monitoringQualitySectionsLengthInMeters = 0;
            let localizedRepairQualitySectionsLengthInMeters = 0;
            let generalMaintenanceQualitySectionsLengthInMeters = 0;
            let reinforcementQualitySectionsLengthInMeters = 0;
            let rehabilitationQualitySectionsLengthInMeters = 0;

            let monitoringQualitySectionsSurface = 0;
            let localizedRepairQualitySectionsSurface = 0;
            let generalMaintenanceQualitySectionsSurface = 0;
            let reinforcementQualitySectionsSurface = 0;
            let rehabilitationQualitySectionsSurface = 0;

            roadsSections.forEach((section: RoadSectionViewData) => {
                let lengthInMeters = Math.round(section.lengthInMeters);
                let surface = (lengthInMeters * section.widthInMeters);

                switch (section.scoreColor) {
                    case ScoreColors.monitoring:
                        monitoringQualitySectionsLengthInMeters += lengthInMeters;
                        monitoringQualitySectionsSurface += surface;
                        break;

                    case ScoreColors.localizedRepair:
                        localizedRepairQualitySectionsLengthInMeters += lengthInMeters;
                        localizedRepairQualitySectionsSurface += surface;
                        break;

                    case ScoreColors.generalMaintenance:
                        generalMaintenanceQualitySectionsLengthInMeters += lengthInMeters;
                        generalMaintenanceQualitySectionsSurface += surface;
                        break;

                    case ScoreColors.reinforcement:
                        reinforcementQualitySectionsLengthInMeters += lengthInMeters;
                        reinforcementQualitySectionsSurface += surface;
                        break;

                    case ScoreColors.rehabilitation:
                        rehabilitationQualitySectionsLengthInMeters += lengthInMeters;
                        rehabilitationQualitySectionsSurface += surface;
                        break;

                    default:
                        break;
                }

                totalSectionsLengthInMeters = (section.roadSectionScoreId !== null) ? totalSectionsLengthInMeters + lengthInMeters : totalSectionsLengthInMeters;
                totalSectionsSurface = (section.roadSectionScoreId !== null) ? totalSectionsSurface + surface : totalSectionsSurface;
            });

            return {
                totalSectionsCount: roadsSections.size,

                totalSectionsLengthInMeters,
                totalSectionsSurface,

                monitoringQualitySectionsLengthInMeters,
                localizedRepairQualitySectionsLengthInMeters,
                generalMaintenanceQualitySectionsLengthInMeters,
                reinforcementQualitySectionsLengthInMeters,
                rehabilitationQualitySectionsLengthInMeters,

                monitoringQualitySectionsSurface,
                localizedRepairQualitySectionsSurface,
                generalMaintenanceQualitySectionsSurface,
                reinforcementQualitySectionsSurface,
                rehabilitationQualitySectionsSurface,

                monitoringQualitySectionsPercent: totalSectionsLengthInMeters ? (monitoringQualitySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0,
                localizedRepairQualitySectionsPercent: totalSectionsLengthInMeters ? (localizedRepairQualitySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0,
                generalMaintenanceQualitySectionsPercent: totalSectionsLengthInMeters ? (generalMaintenanceQualitySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0,
                reinforcementQualitySectionsPercent: totalSectionsLengthInMeters ? (reinforcementQualitySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0,
                rehabilitationQualitySectionsPercent: totalSectionsLengthInMeters ? (rehabilitationQualitySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0
            } as RoadsSectionsSummaryModel;
        }

        return null;
    }

    handleCloseRoadSectionDetails = (state: RoadsConditionViewState2): void => {
        if (state.isRoadSectionDetailsOpened) {
            let isRoadSectionDetailsOpened = false;
            this.resizeMapWhenRoadsConditionView(this.map, state.openedRoadsDrawer);

            RoadsConditionAndScenariosShared.removeCarDatasource(this.map);

            this.setState({
                selectedImage: null,
                selectedRoadSection: null,
                isRoadSectionDetailsOpened: isRoadSectionDetailsOpened
            });
        }
    }

    getSelectedSections = (displayedSectionsIds: Set<number>, mergedProject: MergedProjectVersion): Map<number, RoadSectionViewData> => {
        let displayedSectionsArray = Array.from(displayedSectionsIds);
        let roadsSectionsArray = Array.from(mergedProject.roadsSections).filter(x => displayedSectionsArray.includes(x[0]));
        let roadsSectionsDico = new Map<number, RoadSectionViewData>();
        roadsSectionsArray.forEach((element) => {
            roadsSectionsDico.set(element[0], element[1])
        });

        return roadsSectionsDico;
    }

    handleRoadsDrawerOpened = (state: RoadsConditionViewState2): void => {
        let openedRoadsDrawer = true;
        this.resizeMapWhenRoadsConditionView(this.map, openedRoadsDrawer);

        this.setState({
            openedRoadsDrawer: openedRoadsDrawer,
            statsOpened: false
        });
    }

    handleRoadsDrawerClosed = (state: RoadsConditionViewState2): void => {
        let openedRoadsDrawer = false;
        this.resizeMapWhenRoadsConditionView(this.map, openedRoadsDrawer);

        this.setState({
            openedRoadsDrawer: openedRoadsDrawer,
            statsOpened: false
        });
    }

    handleStatsOpened = () => {
        let openedRoadsDrawer = false;
        //this.resizeMapWhenRoadsConditionView(this.map, openedRoadsDrawer);

        this.setState({
            openedRoadsDrawer: openedRoadsDrawer,
            statsOpened: true
        });
    }

    handleSelectAuscultation = async (auscultationId: number, isCompleteHeritageChecked: boolean, state: RoadsConditionViewState2): Promise<void> => {
        this.setState({
            loading: true
        });

        let selectedAuscultation = state.projectVersion.extendedAuscultations.get(auscultationId);
        let auscultationsIdsArray = this.getAuscultationIds(isCompleteHeritageChecked, selectedAuscultation);
        let auscultationsIdsString: string = Utilities.GetCommaSeparatedString(auscultationsIdsArray);
        let multiAuscultationMergedScores = await RoadsConditionAndScenariosShared.getMergedScoresRawData(this.selectedProject.projectVersionId, auscultationsIdsString);

        let roadSectionsIds = Array.from(state.projectVersion.roadsSections.keys());
        let roadSectionsAttributesData = await RoadsConditionAndScenariosShared.getAttributesByRoadSections(this.selectedProject.projectId, roadSectionsIds);
        let roadSectionsAttributes = RoadsConditionAndScenariosShared.buildRoadSectionsAttributes(roadSectionsAttributesData);

        let roadTrunkLabelsScoreRanking = await RoadsConditionAndScenariosShared.getRoadTrunkLabelsScoreRanking(roadSectionsIds, this.selectedProject.projectVersionId, auscultationsIdsString);

        let mergedProject = RoadsConditionAndScenariosShared.buildMergedProject(state.projectVersion, roadSectionsAttributes, multiAuscultationMergedScores, auscultationsIdsString, roadTrunkLabelsScoreRanking);
        let selectedRoadsSectionsSummary = this.computeSummary(mergedProject.roadsSections);
        let roadTrunkLabelsScores = this.buildExtendedRoadTrunkLabelsScoreRanking(mergedProject);

        let displayedSectionsIds = this.createMapSectionsShapes(mergedProject, state.activeQualities);

        if (!state.statsOpened)
            this.inputSearchRoadsRef.current.value = "";

        let hasAnomaliesLayerMapEvent = true;
        RoadsConditionAndScenariosShared.removeAnomaliesDatasource(this.map, hasAnomaliesLayerMapEvent, this.anomalyLayerMouseover, this.anomalyLayerMouseout, this.anomalyPointClickHandler);

        if (this._isMounted) {
            this.setState((prevState, props) => {
                let isRoadSectionDetailsOpened = prevState.isRoadSectionDetailsOpened;
                if (prevState.isRoadSectionDetailsOpened) {
                    isRoadSectionDetailsOpened = false;
                    this.resizeMapWhenRoadsConditionView(this.map, prevState.openedRoadsDrawer);
                }

                setMapZoom(this.map, mergedProject, 150);
                this.handleZoomend(this.projectPopup);

                return {
                    mergedProject: mergedProject,
                    selectedRoadsSectionsSummary: selectedRoadsSectionsSummary,
                    selectedImage: null,
                    selectedRoadSection: null,
                    isRoadSectionDetailsOpened: isRoadSectionDetailsOpened,
                    displayedSectionsIds,
                    roadTrunkLabelsScores: roadTrunkLabelsScores,
                    activeAnomalies: new Set<string>(),
                    activeMarkingDetections: new Set<string>(),
                    loading: false
                }
            });
        }
    }

    getAuscultationIds = (isCompleteHeritageValue: boolean, selectedAuscultation: AuscultationExtended): number[] => {
        const { projectVersion } = this.state;
        var auscultationIds: number[] = [];
        if (isCompleteHeritageValue) {
            var extendedAuscultations: AuscultationExtended[] = [];
            for (let item of Array.from(projectVersion.extendedAuscultations)) {
                extendedAuscultations.push(item[1]);
            }

            var orderedAuscultations = orderBy(extendedAuscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']);
            for (let item of orderedAuscultations) {
                auscultationIds.push(item.auscultationId);
                if (item.auscultationId === selectedAuscultation.auscultationId)
                    break;
            }
        }
        else
            auscultationIds.push(selectedAuscultation.auscultationId);

        return auscultationIds;
    }

    handleImageChanged = async (imageId: number, state: RoadsConditionViewState2): Promise<void> => {
        if (imageId === null)
            return;

        let image: ImageExtended = state.mergedProject.imagesDico.get(imageId);
        if (image) {
            RoadsConditionAndScenariosShared.setImagePosition(this.map, image, state.mergedProject);
            let roadSection = state.mergedProject.roadsSections.get(image.roadSectionId);

            if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
            }

            this.showImageAndSectionDetails(image, state.mergedProject);
        }
        else {
            await RoadsConditionApiClient.GetPerRoadSectionImagesFromImageId(imageId)
                .then((res) => {
                    let images = res.data as ImageExtended[];
                    let roadSection = state.mergedProject.roadsSections.get(images[0].roadSectionId);
                    RoadsConditionAndScenariosShared.buildViewDataFromSectionImages(state.mergedProject, images, roadSection);

                    image = state.mergedProject.imagesDico.get(imageId);
                    RoadsConditionAndScenariosShared.setImagePosition(this.map, image, state.mergedProject);

                    if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                        RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
                    }

                    this.showImageAndSectionDetails(image, state.mergedProject);
                });
        }
    }

    handleStepChanged = (selectedImage: ImageExtended, searchByNext: boolean, state: RoadsConditionViewState2): void => {
        let image: ImageExtended = state.mergedProject.imagesDico.get(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId);
        while (image?.roadStepId === selectedImage.roadStepId) {
            image = state.mergedProject.imagesDico.get(searchByNext ? image.nextImageId : image.previousImageId);
        }

        if (image) {
            this.handleImageChanged(image?.imageId, state);
        }
        else {
            this.handleImageChanged(searchByNext ? selectedImage.nextImageId : selectedImage.previousImageId, state);
        }
    }

    handleChangeRoadsSearchText = debounce((value: string, state: RoadsConditionViewState2): void => {
        let inputValue = value;
        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        if (inputValue.length > 2) {
            this.updateRoadTrunkLabelsScoresVisibility(roadTrunkLabelsScores, state.activeQualities, inputValue.trim().toLowerCase().removeDiacritics(), state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes);
        }
        else if (inputValue.length === 0) {
            this.updateRoadTrunkLabelsScoresVisibility(roadTrunkLabelsScores, state.activeQualities, "", state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes);
        }

        let newState = { ...state };
        newState.roadTrunkLabelsScores = roadTrunkLabelsScores;

        if (!newState.openedRoadsDrawer) {
            let openedRoadsDrawer = true;
            newState.openedRoadsDrawer = openedRoadsDrawer;

            this.resizeMapWhenRoadsConditionView(this.map, openedRoadsDrawer);
        }

        this.updateFilteredRoadsSectionShapesColor(newState.activeQualities, newState.activeMunicipalities, newState.activeDistricts, newState.activeCollaborativeDevelopmentZones, newState.activeHierarchies, newState.activeTraffics, newState.activeEnvironments, newState.activeManagers, newState.activeImportances, newState.activeOtherAttributes, roadTrunkLabelsScores, newState);
    }, 500);

    updateFilteredRoadsSectionShapesColor = (activeQualities: Set<number>, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, roadTrunkLabelsScores: Map<string, RoadTrunkLabelScoreExtended>, newState: RoadsConditionViewState2): void => {
        let visibleSectionIds = new Set<number>();
        roadTrunkLabelsScores.forEach((road) => {
            if (road.isVisible) {
                road.sectionIds.forEach((sectionId) => {
                    visibleSectionIds.add(sectionId);
                });
            }
        });

        let displayedSectionsIds = new Set<number>();
        let datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;
        datasource.getShapes().forEach((section: Shape) => {
            let properties = section.getProperties();
            let entityType = properties.EntityType;
            if (entityType === ShapeEntityType.section) {
                let sectionId = properties.RoadSectionId;
                let sectionView = newState.mergedProject.roadsSections.get(sectionId);

                let strokeColor = styles.unfilteredSectionColor;
                let sectionScoreType = sectionView.scoreType;
                let sectionScore = sectionView.score;

                //NOTE HGA : ici si la section repond aux filtres selectionnés (états de chaussé, geographies (communes, quarties, zac), attributs (hierarchies, trafics, environnements, gestionnaire, importance, autres attributs))
                // on affiche la section avec sa couleur correspondant et on met le reste des sections du relevé avec la couleur "unfilteredSectionColor"

                if (visibleSectionIds.has(sectionId) &&
                    activeQualities.has(sectionScore) &&
                    ((activeMunicipalities.size > 0 && activeMunicipalities.has(sectionView.municipality)) || activeMunicipalities.size === 0) &&
                    ((activeDistricts.size > 0 && activeDistricts.has(sectionView.district)) || activeDistricts.size === 0) &&
                    ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(sectionView.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                    ((activeHierarchies.size > 0 && activeHierarchies.has(sectionView.hierarchy)) || activeHierarchies.size === 0) &&
                    ((activeTraffics.size > 0 && activeTraffics.has(sectionView.traffic)) || activeTraffics.size === 0) &&
                    ((activeEnvironments.size > 0 && activeEnvironments.has(sectionView.environment)) || activeEnvironments.size === 0) &&
                    ((activeManagers.size > 0 && activeManagers.has(sectionView.manager)) || activeManagers.size === 0) &&
                    ((activeImportances.size > 0 && activeImportances.has(sectionView.importance ? sectionView.importance.toString() : null)) || activeImportances.size === 0) &&
                    ((activeOtherAttributes.size > 0 && (
                        (sectionView.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                        (sectionView.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                        (sectionView.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                        (sectionView.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                        (sectionView.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                        (!sectionView.bus && !sectionView.bikeLase && !sectionView.border && !sectionView.ditch && !sectionView.side && activeOtherAttributes.has(null))
                    )) || activeOtherAttributes.size === 0)
                ) {
                    strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
                    displayedSectionsIds.add(sectionId);
                }

                if (strokeColor !== properties.strokeColor) {
                    properties.strokeColor = strokeColor;
                    section.setProperties(properties);
                }
            }
        });

        let filteredSections = this.getSelectedSections(displayedSectionsIds, newState.mergedProject);
        let selectedRoadsSectionsSummary = this.computeSummary(filteredSections);

        newState.roadTrunkLabelsScores = roadTrunkLabelsScores;
        newState.displayedSectionsIds = displayedSectionsIds;
        newState.selectedRoadsSectionsSummary = selectedRoadsSectionsSummary;
        newState.activeQualities = activeQualities
        newState.activeMunicipalities = activeMunicipalities;
        newState.activeDistricts = activeDistricts;
        newState.activeCollaborativeDevelopmentZones = activeCollaborativeDevelopmentZones;
        newState.activeHierarchies = activeHierarchies;
        newState.activeTraffics = activeTraffics;
        newState.activeEnvironments = activeEnvironments;
        newState.activeManagers = activeManagers;
        newState.activeImportances = activeImportances;
        newState.activeOtherAttributes = activeOtherAttributes;

        this.setState(newState);

        if (displayedSectionsIds.size > 0) {
            RoadsConditionAndScenariosShared.setMapCameraFromPosition(this.map, displayedSectionsIds, newState.mergedProject.roadsSections);
        }
        else {
            setMapZoom(this.map, newState.mergedProject, 150);
        }
    }

    onExpandChange = (event: GridExpandChangeEvent, state: RoadsConditionViewState2): void => {
        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        let item = roadTrunkLabelsScores.get(event.dataItem.label);
        item.expanded = !event.dataItem.expanded;

        this.setState({
            roadTrunkLabelsScores: roadTrunkLabelsScores
        });
    }

    handleRoadClick = (item: RoadTrunkLabelScoreExtended, state: RoadsConditionViewState2): void => {
        let datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;

        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        let row = roadTrunkLabelsScores.get(item.label);
        row.isSelected = !row.isSelected;

        let selectedSectionIds = new Set<number>();
        row.sections.forEach((section) => {
            section.isSelected = row.isSelected;

            if (section.isSelected) {
                showSectionSelectedShape(datasource, section.roadSectionId);
                selectedSectionIds.add(section.roadSectionId);
            }
            else {
                hideSectionSelectedShape(datasource, section.roadSectionId);
            }
        });

        this.setState({
            roadTrunkLabelsScores: roadTrunkLabelsScores
        });

        if (selectedSectionIds.size > 0) {
            RoadsConditionAndScenariosShared.setMapCameraFromPosition(this.map, selectedSectionIds, state.mergedProject.roadsSections);
        }
        else {
            setMapZoom(this.map, state.mergedProject, 150);
        };
    }

    handleSectionRowClick = (item: RoadSectionViewData, state: RoadsConditionViewState2): void => {
        let datasource = this.map.sources.getById(mainDatasourceId) as source.DataSource;

        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        let roadRow = roadTrunkLabelsScores.get(item.roadLabel);
        let rowSections = roadRow.sections;
        let sectionIndex = rowSections.findIndex(x => x.roadSectionId === item.roadSectionId);
        rowSections[sectionIndex].isSelected = !rowSections[sectionIndex].isSelected;

        let selectedSectionIds = new Set<number>();
        let section = rowSections[sectionIndex];
        if (section.isSelected) {
            showSectionSelectedShape(datasource, section.roadSectionId);
            selectedSectionIds.add(section.roadSectionId);
        }
        else {
            hideSectionSelectedShape(datasource, section.roadSectionId);
        }

        roadRow.sections = rowSections;
        if (roadRow.sections.some(x => x.isSelected)) {
            roadRow.isSelected = true;
        }
        else {
            roadRow.isSelected = false;
        }

        this.setState({
            roadTrunkLabelsScores: roadTrunkLabelsScores
        });

        if (selectedSectionIds.size > 0) {
            RoadsConditionAndScenariosShared.setMapCameraFromPosition(this.map, selectedSectionIds, state.mergedProject.roadsSections);
        }
        else {
            setMapZoom(this.map, state.mergedProject, 150);
        }
    }

    showImageAndSectionDetails = (image: ImageExtended, mergedProject: MergedProjectVersion): void => {
        this.setState((prevState, props) => {
            let isRoadSectionDetailsOpened = true;
            let openedRoadsDrawer = false;
            if (!prevState.isRoadSectionDetailsOpened) {
                this.resizeMapWhenRoadsConditionView(this.map, openedRoadsDrawer);
            }

            var roadSection = mergedProject.roadsSections.get(image.roadSectionId);

            return {
                selectedImage: image,
                selectedRoadSection: roadSection,
                isRoadSectionDetailsOpened: isRoadSectionDetailsOpened,
                openedRoadsDrawer: openedRoadsDrawer,
                mergedProject: mergedProject
            };
        });
    }

    handleDisplayDetections = (activeAnomalies: Set<string>, state: RoadsConditionViewState2): void => {
        if (!isEqual(state.activeAnomalies, activeAnomalies)) {
            let hasAnomaliesLayerMapEvent = true;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(this.map, state.mergedProject, hasAnomaliesLayerMapEvent, this.anomalyLayerMouseover, this.anomalyLayerMouseout, this.anomalyPointClickHandler, this.handleAnomalyPointClicked);
            if (activeAnomalies.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = step.scoringParameters;
                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeAnomalies);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }
        }

        this.setState({
            activeAnomalies: activeAnomalies
        });
    }

    handleDisplayDetections2 = (activeAnomalies: Set<string>,
        activeMarkingDetections: Set<string>,
        activeComplementaryDetections: Set<string>,
        activeWarningDetections: Set<string>,
        activeInformationDetections: Set<string>,
        activeRegulatoryDetections: Set<string>,
        state: RoadsConditionViewState2): void => {
        if (!isEqual(state.activeAnomalies, activeAnomalies) ||
            !isEqual(state.activeMarkingDetections, activeMarkingDetections) ||
            !isEqual(state.activeComplementaryDetections, activeComplementaryDetections) ||
            !isEqual(state.activeWarningDetections, activeWarningDetections) ||
            !isEqual(state.activeInformationDetections, activeInformationDetections) ||
            !isEqual(state.activeRegulatoryDetections, activeRegulatoryDetections)) {
            let hasAnomaliesLayerMapEvent = true;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(this.map, state.mergedProject, hasAnomaliesLayerMapEvent, this.anomalyLayerMouseover, this.anomalyLayerMouseout, this.anomalyPointClickHandler, this.handleAnomalyPointClicked);
            if (activeAnomalies.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = step.scoringParameters;
                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeAnomalies);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }

            if (activeMarkingDetections.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = new Map<string, ScoringParameter>();
                        step.scoringParameters.forEach((value, key) => {
                            value.anomalyType = value.anomalyType.replace(/-/g, '_');
                            scoringParameters.set(key.replace(/-/g, '_'), value);
                        });

                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeMarkingDetections);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }

            if (activeComplementaryDetections.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = new Map<string, ScoringParameter>();
                        step.scoringParameters.forEach((value, key) => {
                            value.anomalyType = value.anomalyType.replace(/-/g, '_');
                            scoringParameters.set(key.replace(/-/g, '_'), value);
                        });


                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeComplementaryDetections);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }

            if (activeWarningDetections.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = new Map<string, ScoringParameter>();
                        step.scoringParameters.forEach((value, key) => {
                            value.anomalyType = value.anomalyType.replace(/-/g, '_');
                            scoringParameters.set(key.replace(/-/g, '_'), value);
                        });

                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeWarningDetections);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }

            if (activeInformationDetections.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = new Map<string, ScoringParameter>();
                        step.scoringParameters.forEach((value, key) => {
                            value.anomalyType = value.anomalyType.replace(/-/g, '_');
                            scoringParameters.set(key.replace(/-/g, '_'), value);
                        });

                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeInformationDetections);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }

            if (activeRegulatoryDetections.size > 0) {
                state.perStepImagesAnomalies.forEach((value, key) => {
                    let step = state.mergedProject.roadsSteps.get(key);
                    if (step) {
                        let scoringParameters = new Map<string, ScoringParameter>();
                        step.scoringParameters.forEach((value, key) => {
                            value.anomalyType = value.anomalyType.replace(/-/g, '_');
                            scoringParameters.set(key.replace(/-/g, '_'), value);
                        });

                        let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, activeRegulatoryDetections);
                        if (anomalies.size >= 1) {
                            let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                            anomaliesDatasource.add(anomalyPoint);
                        }
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                let existingCarIconLayer = this.map.layers.getLayerById(carIconSymbolLayerId) as layer.SymbolLayer;
                let heading = 0;
                if (existingCarIconLayer) {
                    heading = existingCarIconLayer.getOptions().iconOptions.rotation as number;
                    this.map.layers.remove(existingCarIconLayer);
                    let carDatasource = this.map.sources.getById(carDatasourceId) as source.DataSource;
                    RoadsConditionAndScenariosShared.createCarIconSymbolLayer(this.map, carDatasource, carIconSymbolLayerId, heading);
                }
            }
        }

        this.setState({
            activeAnomalies: activeAnomalies,
            activeMarkingDetections: activeMarkingDetections,
            activeComplementaryDetections: activeComplementaryDetections,
            activeWarningDetections: activeWarningDetections,
            activeInformationDetections: activeInformationDetections,
            activeRegulatoryDetections: activeRegulatoryDetections
        })
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>, state: RoadsConditionViewState2): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        this.updateRoadTrunkLabelsScoresVisibility(roadTrunkLabelsScores, activeQualities, inputValue, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes);
        this.updateFilteredRoadsSectionShapesColor(activeQualities, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state.roadTrunkLabelsScores, state);
    }

    handleDisplaySectionsFromGeographiesFilters = (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, state: RoadsConditionViewState2): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        this.updateRoadTrunkLabelsScoresVisibility(roadTrunkLabelsScores, state.activeQualities, inputValue, activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes);
        this.updateFilteredRoadsSectionShapesColor(state.activeQualities, activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, roadTrunkLabelsScores, state);
    }

    handleDisplaySectionsFromAttributesFilters = (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: RoadsConditionViewState2): void => {
        let inputValue = this.inputSearchRoadsRef.current.value.trim().toLowerCase().removeDiacritics();
        let roadTrunkLabelsScores = state.roadTrunkLabelsScores;
        this.updateRoadTrunkLabelsScoresVisibility(roadTrunkLabelsScores, state.activeQualities, inputValue, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes);
        this.updateFilteredRoadsSectionShapesColor(state.activeQualities, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, roadTrunkLabelsScores, state);
    }

    updateRoadTrunkLabelsScoresVisibility = (roadTrunkLabelsScores: Map<string, RoadTrunkLabelScoreExtended>, activeQualities: Set<number>, inputSearchText: string, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>): void => {
        roadTrunkLabelsScores.forEach((road) => {
            if (road.sections.some(x =>
                (activeQualities.size > 0 && activeQualities.has(x.score)) &&
                ((road.labelLowerWithoutDiacritics.includes(inputSearchText)) || (!inputSearchText && road.averageScore === null)) &&
                ((activeMunicipalities.size > 0 && activeMunicipalities.has(x.municipality)) || activeMunicipalities.size === 0) &&
                ((activeDistricts.size > 0 && activeDistricts.has(x.district)) || activeDistricts.size === 0) &&
                ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(x.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                ((activeHierarchies.size > 0 && activeHierarchies.has(x.hierarchy)) || activeHierarchies.size === 0) &&
                ((activeTraffics.size > 0 && activeTraffics.has(x.traffic)) || activeTraffics.size === 0) &&
                ((activeEnvironments.size > 0 && activeEnvironments.has(x.environment)) || activeEnvironments.size === 0) &&
                ((activeManagers.size > 0 && activeManagers.has(x.manager)) || activeManagers.size === 0) &&
                ((activeImportances.size > 0 && activeImportances.has(x.importance ? x.importance.toString() : null)) || activeImportances.size === 0) &&
                ((activeOtherAttributes.size > 0 && (
                    (x.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                    (x.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                    (x.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                    (x.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                    (x.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                    (!x.bus && !x.bikeLase && !x.border && !x.ditch && !x.side && activeOtherAttributes.has(null))
                )) || activeOtherAttributes.size === 0))) {
                road.isVisible = true;
            }
            else {
                road.isVisible = false;
            }
        });
    }

    setAnomaliesData = ({ anomalies,
        markingDetections,
        complementaryDetections,
        warningDetections,
        informationDetections,
        regulatoryDetections,
        perStepImagesAnomaliesMap }: {
            anomalies: Map<string, string>,
            markingDetections: Map<string, string>,
            complementaryDetections: Map<string, string>,
            warningDetections: Map<string, string>,
            informationDetections: Map<string, string>,
            regulatoryDetections: Map<string, string>,
            perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]>
        }): void => {
        this.setState({
            projectVersionAnomalies: anomalies,
            projectVersionMarking: markingDetections,
            projectVersionComplementary: complementaryDetections,
            projectVersionWarning: warningDetections,
            projectVersionInformation: informationDetections,
            projectVersionRegulatory: regulatoryDetections,
            perStepImagesAnomalies: perStepImagesAnomaliesMap
        })
    }

    handleSearchImageKeyPressed = async (code: string, state: RoadsConditionViewState2): Promise<void> => {
        if (code === "Enter" || code === "NumpadEnter") {
            let refImageId = Number(this.inputSearchImagesRef.current.value);
            await RoadsConditionApiClient.GetPerRoadSectionImagesFromImageReferenceId(`image${refImageId}`, state.mergedProject.auscultationsIdsString)
                .then((res) => {
                    let images = res.data as ImageExtended[];
                    if (images) {
                        let roadSection = state.mergedProject.roadsSections.get(images[0].roadSectionId);
                        RoadsConditionAndScenariosShared.buildViewDataFromSectionImages(state.mergedProject, images, roadSection);

                        let imageData = images.find(x => x.referenceImageId === `image${refImageId}`);
                        let image: ImageExtended = state.mergedProject.imagesDico.get(imageData.imageId);
                        RoadsConditionAndScenariosShared.setImagePosition(this.map, image, state.mergedProject);

                        if (this.hasScoreAnalysisAccess && !roadSection.anomaliesCounters) {
                            RoadsConditionAndScenariosShared.initAnomaliesCounters(roadSection);
                        }

                        this.showImageAndSectionDetails(image, state.mergedProject);
                    }
                });
        }
    }

    render() {
        const state = this.state;

        return (
            <Box display="flex" flexDirection="column">
                {this.state.loading ? <PageLoaderComponent></PageLoaderComponent> : null}
                <ActionsMenuComponent openedRoadsDrawer={state.openedRoadsDrawer}
                    inputRef={this.inputSearchRoadsRef}
                    statsOpened={state.statsOpened}
                    inputImageRef={this.inputSearchImagesRef}
                    activeAnomalies={state.activeAnomalies}
                    activeMarkingDetections={state.activeMarkingDetections}
                    activeComplementaryDetections={state.activeComplementaryDetections}
                    activeWarningDetections={state.activeWarningDetections}
                    activeInformationDetections={state.activeInformationDetections}
                    activeRegulatoryDetections={state.activeRegulatoryDetections}
                    activeQualities={state.activeQualities}
                    activeMunicipalities={state.activeMunicipalities}
                    activeDistricts={state.activeDistricts}
                    activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                    activeHierarchies={state.activeHierarchies}
                    activeTraffics={state.activeTraffics}
                    activeEnvironments={state.activeEnvironments}
                    activeManagers={state.activeManagers}
                    activeImportances={state.activeImportances}
                    activeOtherAttributes={state.activeOtherAttributes}
                    mergedProject={state.mergedProject}
                    projectVersion={state.projectVersion}
                    isDisable={state.isRoadSectionDetailsOpened}
                    role={this.props.role}
                    POC={true}
                    projectVersionAnomalies={state.projectVersionAnomalies}
                    projectVersionMarking={state.projectVersionMarking}
                    projectVersionComplementary={state.projectVersionComplementary}
                    projectVersionWarning={state.projectVersionWarning}
                    projectVersionInformation={state.projectVersionInformation}
                    projectVersionRegulatory={state.projectVersionRegulatory}
                    handleRoadsDrawerClosed={() => this.handleRoadsDrawerClosed(state)}
                    handleRoadsDrawerOpened={() => this.handleRoadsDrawerOpened(state)}
                    handleStatsOpened={() => this.handleStatsOpened()}
                    handleSearchTextChanged={(value) => this.handleChangeRoadsSearchText(value, state)}
                    handleDisplayDetections={(activeAnomalies: Set<string>) => this.handleDisplayDetections(activeAnomalies, state)}
                    handleDisplayDetections2={(
                        activeAnomalies: Set<string>,
                        activeMarkingDetections: Set<string>,
                        activeComplementaryDetections: Set<string>,
                        activeWarningDetections: Set<string>,
                        activeInformationDetections: Set<string>,
                        activeRegulatoryDetections: Set<string>
                    ) =>
                        this.handleDisplayDetections2(
                            activeAnomalies,
                            activeMarkingDetections,
                            activeComplementaryDetections,
                            activeWarningDetections,
                            activeInformationDetections,
                            activeRegulatoryDetections,
                            state)}
                    handleDisplaySections={(activeQualities: Set<number>) => this.handleDisplaySectionsFromQualityFilters(activeQualities, state)}
                    handleSelectAuscultation={(auscultationId, isCompleteHeritageChecked) => this.handleSelectAuscultation(auscultationId, isCompleteHeritageChecked, state)}
                    handleDisplaySectionsFromGeographiesFilters={(activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => this.handleDisplaySectionsFromGeographiesFilters(activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state)}
                    handleDisplaySectionsFromAttributesFilters={(activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => this.handleDisplaySectionsFromAttributesFilters(activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state)}
                    handleSearchImageKeyPressed={(code) => this.handleSearchImageKeyPressed(code, state)}
                    setAnomaliesData={this.setAnomaliesData}
                />
                {state.statsOpened &&
                    <IndicatorStatsComponent mergedProject={state.mergedProject}
                        summary={this.computeSummary(state.mergedProject.roadsSections)} />
                }
                <Box className="roads-condition" display="flex" flexDirection="row">
                    {state.isRoadSectionDetailsOpened && !state.loading &&
                        <RoadSectionDetailsComponent
                            selectedRoadSection={state.selectedRoadSection}
                            selectedImage={state.selectedImage}
                            hasScoreAnalysisAccess={this.hasScoreAnalysisAccess}
                            role={this.props.role}
                            shouldDisplayDetails={true}
                            shouldEditRoadSectionScore={this.props.role === UserRoleIds.administrator || this.props.role === UserRoleIds.agencyTech || this.props.role === UserRoleIds.customerTech}
                            handleStepChanged={(selectedImage: ImageExtended, searchByNext: boolean) => this.handleStepChanged(selectedImage, searchByNext, state)}
                            handleImageChanged={(imageId) => this.handleImageChanged(imageId, state)}
                            onClose={() => this.handleCloseRoadSectionDetails(state)}
                        />
                    }
                    <div style={{ position: 'absolute', width: '100%', height: '100%', display: "flex", flexDirection: "row" }}>
                        {!state.loading && <RoadsDrawerComponent
                            roadTrunkLabelsScores={state.roadTrunkLabelsScores}
                            projectVersion={state.projectVersion}
                            openedDrawer={state.openedRoadsDrawer}
                            role={this.props.role}
                            displayedSectionsIds={state.displayedSectionsIds}
                            onExpandChange={(event) => this.onExpandChange(event, this.state)}
                            handleRowClick={(item) => this.handleRoadClick(item, this.state)}
                            handleSectionRowClick={(item) => this.handleSectionRowClick(item, this.state)}
                        />}
                        <MapWithImagesComponent
                            loading={state.loading}
                            role={this.props.role}
                            selectedRoadsSectionsSummary={state.selectedRoadsSectionsSummary}
                            activeQualities={state.activeQualities}
                            displayedSectionsIds={state.displayedSectionsIds}
                            selectedProject={this.selectedProject}
                            auscultationsIdsString={state.mergedProject?.auscultationsIdsString}
                            azureMap={this.map}
                            currentMeasurementSystemType={state.measurementSystemType}
                            handleDisplayImageFromSectionClicked={(e) => this.handleMapClick(e, state)}
                            handleCLoseRoadSectionDetails={() => this.handleCloseRoadSectionDetails(state)}
                        />
                    </div>
                    <div id="measurementInfo" className="measure"></div>
                </Box>
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(RoadsConditionView2));