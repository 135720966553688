import { RoadTrunkLabelScore } from "../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/RoadTrunkLabelScore";
import { AreaRoadSectionViewData } from "./AreaRoadSectionViewData";

export interface RoadTrunkLabelScoreExtended extends RoadTrunkLabelScore {
    index: number,
    fixedAverageScore: string,
    lengthInMeters: number,
    hiddenFixedAverageScoreForSort: number,
    isVisible: boolean,
    isSelected: boolean,
    hiddenIsSelectedForSort?: number,
    expanded: boolean,
    labelLowerWithoutDiacritics: string,
    hiddenLabelForSort: string,
    sectionIds: number[],
    sections: AreaRoadSectionViewData[],
    monitoringSectionsPercent: number,
    localizedRepairSectionsPercent: number,
    generalMaintenanceSectionsPercent: number,
    reinforcementSectionsPercent: number,
    rehabilitationSectionsPercent: number
}

export const getHiddenIsSelectedForSort = (isSelected: boolean): number => isSelected === false ? 2 : (isSelected === null ? 1 : 0);