import { Box, IconButton, Tooltip } from "@mui/material";
import { Calendar, CalendarChangeEvent, DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useState } from 'react';
import iconCalendar from 'src/assets/icons/icon-calendar.svg';
import Translate from "../../../../localization/Localization";
import { ProgrammingAreaExtended } from "../../models/ProgrammingAreaExtended";

interface CustomDatePickerCellProps {
    isStartDate: boolean,
    disabled?: boolean,
    handleDateChanged?: (dataItem: ProgrammingAreaExtended) => void
}

export const CustomDatePickerCell = (props: GridCellProps & CustomDatePickerCellProps): JSX.Element => {
    const { dataItem } = props;

    const [show, setShow] = useState<boolean>(null);
    const [value, setValue] = useState<Date>(props.isStartDate ? (dataItem.startDate ?? null) : (dataItem.endtDate ?? null));

    const onCalendarChanged = (e: CalendarChangeEvent): void => {
        let value = e.target.value;
        if (props.isStartDate) {
            dataItem.startDate = value;
        }
        else {
            dataItem.endtDate = value;
        }
        setShow(false);
        setValue(value);
        props.handleDateChanged(dataItem);
    }

    const onDatePickerChanged = (e: DatePickerChangeEvent): void => {
        if (e.target.validity.valid) {
            let value = e.value;

            if (props.isStartDate) {
                dataItem.startDate = value;
                if (!value || value >= dataItem.endtDate) {
                    dataItem.endtDate = null;
                }
            }
            else {
                dataItem.endtDate = value;
            }
            setValue(value);
        }
    }

    const onDatePickerBlur = () => {
        props.handleDateChanged(dataItem);
    }

    const defaultRendering = (
        <td>
            <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                {props.isStartDate &&
                    <DatePicker
                        disabled={props.disabled}
                        toggleButton={() => <></>}
                        show={show}
                        className="date-picker"
                        format={{ year: "numeric", month: "numeric" }}
                        calendar={() => <Calendar
                            bottomView="year"
                            topView="year"
                            value={value}
                            onChange={(e) => onCalendarChanged(e)}
                        />}
                        value={value}
                        onChange={(e) => onDatePickerChanged(e)}
                        onBlur={() => onDatePickerBlur()}
                    />
                }
                {!props.isStartDate &&
                    <Tooltip title={!dataItem.startDate ? Translate.Resources.UI_Programming_DatePicker_Tooltip_PleaseEnterAStartSate : ""}>
                        <span>
                            <DatePicker
                                disabled={props.disabled}
                                toggleButton={() => <></>}
                                show={show}
                                className="date-picker-with-tooltip"
                                format={{ year: "numeric", month: "numeric" }}
                                calendar={() => <Calendar
                                    bottomView="year"
                                    topView="year"
                                    value={value}
                                    onChange={(e) => onCalendarChanged(e)}
                                    min={new Date(dataItem.startDate) }
                                />}
                                value={value}
                                onChange={(e) => onDatePickerChanged(e)}
                                onBlur={() => onDatePickerBlur()}
                                min={new Date(dataItem.startDate)}
                            />
                        </span>
                    </Tooltip>
                }
                <IconButton onClick={() => setShow(true)} className="icon-button" disabled={props.disabled}>
                    <img src={iconCalendar} alt="icon calendar" />
                </IconButton>
            </Box>
        </td>
    );

    return defaultRendering;
}