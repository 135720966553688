import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import Translate from '../../../localization/Localization';
import { getScoreClassName, getScoreLabel, ScoreTypes } from '../../../shared/models/ScoreTypes';
import { RoadSectionViewData } from '../models/RoadSectionViewData';
import './EditScoreDialogStyles.scss';

interface SelectOptionModel {
    label: string,
    value: number,
    scoreType: string,
    disabled: boolean
}

interface EditScoreDialogProps {
    isOpened: boolean,
    selectedRoadSection: RoadSectionViewData,
    handleEditScore: (roadSectionId: number, auscultationId: number, newScore: number, reason: string) => void,
    onClose: () => void
}

export const EditScoreDialog = (props: EditScoreDialogProps): JSX.Element => {
    const { selectedRoadSection } = props;

    let inputTextReasonsRef: MutableRefObject<HTMLInputElement> = useRef(null);

    const [scoreValue, setScoreValue] = useState<SelectOptionModel>(null);
    const [enabledValidate, setEnabledValidate] = useState<boolean>(false);
    const [reasons, setReasons] = useState<string>('');

    useEffect(() => {
        setEnabledValidate(reasons.trim() !== '' && scoreValue !== null && scoreValue?.value !== selectedRoadSection.score);
    }, [reasons, scoreValue])

    const scoresOptions: SelectOptionModel[] = [
        { scoreType: ScoreTypes.rehabilitation, value: 1, label: `1 - ${Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}`, disabled: selectedRoadSection.score === 1 },
        { scoreType: ScoreTypes.reinforcement, value: 2, label: `2 - ${Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}`, disabled: selectedRoadSection.score === 2 },
        { scoreType: ScoreTypes.reinforcement, value: 3, label: `3 - ${Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}`, disabled: selectedRoadSection.score === 3 },
        { scoreType: ScoreTypes.generalMaintenance, value: 4, label: `4 - ${Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}`, disabled: selectedRoadSection.score === 4 },
        { scoreType: ScoreTypes.generalMaintenance, value: 5, label: `5 - ${Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}`, disabled: selectedRoadSection.score === 5 },
        { scoreType: ScoreTypes.localizedRepair, value: 6, label: `6 - ${Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}`, disabled: selectedRoadSection.score === 6 },
        { scoreType: ScoreTypes.localizedRepair, value: 7, label: `7 - ${Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}`, disabled: selectedRoadSection.score === 7 },
        { scoreType: ScoreTypes.monitoring, value: 8, label: `8 - ${Translate.Resources.UI_ActionsMenu_Quality_Monitoring}`, disabled: selectedRoadSection.score === 8 },
        { scoreType: ScoreTypes.monitoring, value: 9, label: `9 - ${Translate.Resources.UI_ActionsMenu_Quality_Monitoring}`, disabled: selectedRoadSection.score === 9 },
        { scoreType: ScoreTypes.monitoring, value: 10, label: `10 - ${Translate.Resources.UI_ActionsMenu_Quality_Monitoring}`, disabled: selectedRoadSection.score === 10 },
        { scoreType: null, label: Translate.Resources.UI_ActionsMenu_Empty, value: null, disabled: selectedRoadSection.score === null }
    ];

    const handleScoreChanged = (e: SelectOptionModel): void => {
        setScoreValue(e);
    }

    const handleInputTextReasonsChanged = (e): void => {
        setReasons(e.target.value);
    }

    const handleValidate = debounce((): void => {
        if (scoreValue?.value && inputTextReasonsRef?.current?.value) {
            props.handleEditScore(props.selectedRoadSection.roadSectionId, props.selectedRoadSection.auscultationId, scoreValue.value, inputTextReasonsRef.current.value);
            setEnabledValidate(false);
        }
    }, 500);

    return (
        <Dialog id="edit-score-dialog" open={props.isOpened}>
            <DialogContent>
                <Box className="content">
                    <Box className="dialog-title">
                        <div className="title">{Translate.Resources.UI_EditRoadSectionScore_EditingTheScore}</div>
                        <CloseIcon fontSize="small" onClick={props.onClose} className="close-icon" />
                    </Box>
                    <div className="title">{`${Translate.Resources.UI_EditRoadSectionScore_Section} : ${selectedRoadSection.roadSectionId}`}</div>
                    <div className="title statement-of">{`${Translate.Resources.UI_EditRoadSectionScore_StatementOf} : ${selectedRoadSection.videoDateTime.toLocaleDateString()}`}</div>
                    <Box className="score">
                        <div className="title">{`${Translate.Resources.UI_EditRoadSectionScore_AutomaticScore} : `}</div>
                        <div className={`score-color ${getScoreClassName(selectedRoadSection.computedScoreType)}`}></div>
                        <div className="title">{selectedRoadSection.computedScore === null ? getScoreLabel(selectedRoadSection.computedScoreType) : `${selectedRoadSection.computedScore} - ${getScoreLabel(selectedRoadSection.computedScoreType)}`}</div>
                    </Box>
                    <Box className="select-score">
                        <Select
                            className="select"
                            classNamePrefix="score"
                            menuShouldBlockScroll={true}
                            menuPosition="fixed"
                            options={scoresOptions}
                            value={scoreValue}
                            isOptionDisabled={(option) => option.disabled === true}
                            components={{
                                Option: (p) => {
                                    return <div>
                                        <components.Option {...p} className="item-option">
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <div className={`score-color ${getScoreClassName(p.data.scoreType)}`}></div>
                                                <div className="option-label">{p.data.value === null ? getScoreLabel(p.data.scoreType) : `${p.data.value} - ${getScoreLabel(p.data.scoreType)}`}</div>
                                            </Box>
                                        </components.Option>
                                    </div>
                                }
                            }}
                            placeholder={Translate.Resources.UI_EditRoadSectionScore_ManualScore}
                            onChange={(e) => handleScoreChanged(e)}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <div className="title">{Translate.Resources.UI_EditRoadSectionScore_ReasonsForModification} <span className="required">*</span> : </div>
                        <TextField
                            margin="normal"
                            multiline
                            variant="outlined"
                            rows="3"
                            className="input-text-multiline"
                            inputRef={inputTextReasonsRef}
                            onChange={handleInputTextReasonsChanged}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <div className="mandatory-field"><span className="required">*</span> {Translate.Resources.UI_EditRoadSectionScore_MandatoryField}</div>
                    </Box>
                    <Box className="btns-actions">
                        <Button className="btn-secondary" onClick={props.onClose}>
                            {Translate.Resources.UI_EditRoadSectionScore_Cancel}
                        </Button>
                        <Button className="btn-primary" disabled={!enabledValidate} onClick={handleValidate}>
                            {Translate.Resources.UI_EditRoadSectionScore_Validate}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}