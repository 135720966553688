import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Box, Button, ButtonGroup, Checkbox, Input, InputAdornment, ListItemIcon, MenuItem, Select } from '@mui/material';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import mapButtonIcon from 'src/assets/icons/icon-map-button.svg';
import Translate from '../../../localization/Localization';
import { Auscultation } from '../../Home/services/dataContracts/queryStack/Auscultation';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import { ProjectVersionExtended } from '../models/ProjectVersionExtended';
import { Environment } from '../services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../services/RoadsCondition/dataContracts/queryStack/Manager';
import { StepImageAnomalies } from '../services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from '../services/RoadsCondition/dataContracts/queryStack/Traffic';
import './ActionsMenuStyles.scss';
import { AttributesListFilterComponent } from './AttributesListFilterComponent';
import { DetectionListFilterComponent } from './DetectionListFilterComponent';
import { DetectionListFilterComponent2 } from './DetectionListFilterComponent2';
import { GeographyListFilterComponent } from './GeographyListFilterComponent';
import { QualityListFilterComponent } from './QualityListFilterComponent';

interface ActionsMenuComponentProps {
    openedRoadsDrawer: boolean,
    statsOpened: boolean,
    inputRef: React.RefObject<HTMLInputElement>,
    inputImageRef: React.RefObject<HTMLInputElement>,
    activeAnomalies: Set<string>,
    activeMarkingDetections?: Set<string>,
    activeComplementaryDetections?: Set<string>,
    activeWarningDetections?: Set<string>,
    activeInformationDetections?: Set<string>,
    activeRegulatoryDetections?: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    mergedProject: MergedProjectVersion,
    projectVersion: ProjectVersionExtended,
    isDisable: boolean,
    role: string,
    POC?: boolean,
    projectVersionAnomalies: Map<string, string>,
    projectVersionMarking?: Map<string, string>,
    projectVersionComplementary?: Map<string, string>,
    projectVersionWarning?: Map<string, string>,
    projectVersionInformation?: Map<string, string>,
    projectVersionRegulatory?: Map<string, string>,
    handleRoadsDrawerClosed: () => void,
    handleRoadsDrawerOpened: () => void,
    handleStatsOpened: () => void,
    handleSearchTextChanged: (value: string) => void,
    handleDisplayDetections: (activeAnomalies: Set<string>) => void,
    handleDisplayDetections2?: (activeAnomalies: Set<string>,
        activeMarkingDetections: Set<string>,
        activeComplementaryDetections: Set<string>,
        activeWarningDetections: Set<string>,
        activeInformationDetections: Set<string>,
        activeRegulatoryDetections: Set<string>) => void,
    handleDisplaySections: (activeQualities: Set<number>) => void,
    handleSelectAuscultation: (auscultationId: number, isCompleteHeritageChecked: boolean) => void,
    handleDisplaySectionsFromGeographiesFilters: (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => void,
    handleDisplaySectionsFromAttributesFilters: (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => void,
    handleSearchImageKeyPressed: (code: string) => void,
    setAnomaliesData: ({ anomalies,
        markingDetections,
        complementaryDetections,
        warningDetections,
        informationDetections,
        regulatoryDetections,
        perStepImagesAnomaliesMap
    }: {
        anomalies: Map<string, string>,
        markingDetections: Map<string, string>,
        complementaryDetections: Map<string, string>,
        warningDetections: Map<string, string>,
        informationDetections: Map<string, string>,
        regulatoryDetections: Map<string, string>,
        perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]>
    }) => void,
    setSelectedAuscultation: (auscultationId: number, isCompleteHeritageSelected: boolean) => void
}

export const ActionsMenuComponent = (props: ActionsMenuComponentProps): JSX.Element => {

    const [detectionListAnchorEl, setDetectionListAnchorEl] = useState<null | HTMLElement>(null);
    const isDetectionListOpened = Boolean(detectionListAnchorEl);

    const [qualityListAnchorEl, setQualityListAnchorEl] = useState<null | HTMLElement>(null);
    const isQualityListOpened = Boolean(qualityListAnchorEl);

    const [geographyListAnchorEl, setGeographyListAnchorEl] = useState<null | HTMLElement>(null);
    const isGeographyListOpened = Boolean(geographyListAnchorEl);

    const [attributeListAnchorEl, setAttributeListAnchorEl] = useState<null | HTMLElement>(null);
    const isAttributeListOpened = Boolean(attributeListAnchorEl);

    const handleOpenDetectionListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isDetectionListOpened) {
            setDetectionListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenQualityListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isQualityListOpened) {
            setQualityListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenGeographyListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isGeographyListOpened) {
            setGeographyListAnchorEl(e.currentTarget);
        }
    }

    const handleOpenAttributeListFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!isAttributeListOpened) {
            setAttributeListAnchorEl(e.currentTarget);
        }
    }

    const [auscultations, setAuscultations] = useState<{
        fromDate: number | string,
        isFromDateDisabled: boolean,
        toDate: number | string,
        isToDateDisabled: boolean,
        list: { label: string, value: number }[],
        hasOnlyAuscultation: boolean,
        isCompleteHeritageSelected: boolean
    }>({
        fromDate: "",
        isFromDateDisabled: false,
        toDate: "",
        isToDateDisabled: false,
        list: [],
        hasOnlyAuscultation: false,
        isCompleteHeritageSelected: false
    });

    useEffect(() => {
        if (auscultations.list.length === 0) {
            let projectVersion = props.projectVersion;
            if (projectVersion) {
                let auscultations = {
                    fromDate: 0,
                    isFromDateDisabled: false,
                    toDate: 0,
                    isToDateDisabled: false,
                    list: [],
                    hasOnlyAuscultation: false,
                    isCompleteHeritageSelected: false
                };

                let auscultationsDates: { label: string, value: number }[] = [];
                if (projectVersion.auscultations.length > 1) {
                    let projectAuscultationsArray = orderBy(projectVersion.auscultations, ['videoDateTime', 'processingDateTime', 'auscultationNumber'], ['asc', 'asc', 'asc']);
                    projectAuscultationsArray.forEach((auscultation: Auscultation, index: number) => {
                        let value = auscultation.auscultationId;
                        let label = new Date(auscultation.videoDateTime).toLocaleDateString();
                        let item = { label: label, value: value };
                        auscultationsDates.push(item);

                        if (index === 0) {
                            auscultations.fromDate = item.value;
                        }

                        if (index === projectAuscultationsArray.length - 1) {
                            auscultations.toDate = item.value;
                        }

                        auscultations.isCompleteHeritageSelected = true;
                        auscultations.isFromDateDisabled = true;
                    });
                }
                else {
                    let auscultation = projectVersion.auscultations[0];
                    let item = { label: new Date(auscultation.videoDateTime).toLocaleDateString(), value: auscultation.auscultationId };
                    auscultationsDates.push(item);
                    auscultations.hasOnlyAuscultation = true;
                    auscultations.fromDate = item.value;
                }

                auscultations.list = auscultationsDates;
                setAuscultations(auscultations);
                props.setSelectedAuscultation(auscultations.isCompleteHeritageSelected ? auscultations.toDate : auscultations.fromDate, auscultations.isCompleteHeritageSelected);
            }
        }
    }, [props.projectVersion])

    const handleFromDateChanged = (value: number): void => {
        setAuscultations({ ...auscultations, fromDate: value, toDate: value });
        props.handleSelectAuscultation(value, false);
    }

    const handleToDateChanged = (value: number): void => {
        setAuscultations({ ...auscultations, toDate: value });
        props.handleSelectAuscultation(value, true);
    }

    const handleIsCompleteHeritageSelected = (e: ChangeEvent<HTMLInputElement>): void => {
        var checked = e.target.checked;
        setAuscultations({
            ...auscultations,
            isCompleteHeritageSelected: checked,
            isFromDateDisabled: checked ? true : false,
            isToDateDisabled: checked ? false : true,
            fromDate: !checked ? auscultations.toDate : auscultations.list[0].value
        });
        props.handleSelectAuscultation(auscultations.toDate as number, checked);
    }

    const selectedQualitiesNotes: number[] = Array.from(props.activeQualities);
    const activeGeographies = props.activeMunicipalities.size + props.activeDistricts.size + props.activeCollaborativeDevelopmentZones.size;
    const activeAttributes = props.activeHierarchies.size + props.activeTraffics.size + props.activeEnvironments.size + props.activeManagers.size + props.activeImportances.size + props.activeOtherAttributes.size;

    return (
        <Box className="actions-menu" display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
                <Box display="flex" flexDirection="row" alignItems="center">
                    <ButtonGroup variant="contained" aria-label="button group" className="button-group" disabled={props.isDisable}>
                        <Button className={`button map ${!props.openedRoadsDrawer && !props.statsOpened ? 'active' : ''} ${props.isDisable ? ' disabled' : ''} `} onClick={() => props.handleRoadsDrawerClosed()}>
                            <img src={mapButtonIcon} alt="map button icon" />
                            <span className="label">{Translate.Resources.UI_ActionsMenu_Map}</span>
                        </Button>
                        <Button className={`button ${props.openedRoadsDrawer && !props.statsOpened ? 'active' : ''} ${props.isDisable ? ' disabled' : ''}`} onClick={() => props.handleRoadsDrawerOpened()}>
                            <ViewListIcon className="view-list-icon" />
                            <span className="label">{Translate.Resources.UI_ActionsMenu_List}</span>
                        </Button>
                        <Button className={`button view-list ${props.statsOpened ? 'active' : ''} ${props.isDisable ? ' disabled' : ''}`} onClick={() => props.handleStatsOpened()}>
                            <QueryStatsIcon className="stats-icon" />
                            <span className="label">{Translate.Resources.UI_ActionsMenu_Stats}</span>
                        </Button>
                    </ButtonGroup>
                    {!props.statsOpened &&
                        <Box className="search-input">
                            <Input disableUnderline className={`input-search-roads ${props.isDisable ? ' disabled' : ''}`} inputRef={props.inputRef} disabled={props.isDisable}
                                endAdornment={<>
                                    <InputAdornment position="end" classes={{ root: 'input-icon-search-root' }}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputAdornment>
                                </>}
                                id="input-search-roads-text"
                                placeholder={Translate.Resources.UI_ActionsBar_Search_Placeholder}
                                onChange={(event) => props.handleSearchTextChanged(event.target.value)}
                            />
                        </Box>
                    }
                    {!props.statsOpened &&
                        <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper quality ${props.isDisable ? ' disabled' : ''} ${isQualityListOpened ? 'selected' : ''}`} onClick={(e) => !props.isDisable ? handleOpenQualityListFilter(e) : {}}>
                            <Box className="counter filled">{selectedQualitiesNotes.length}</Box>
                            <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                {Translate.Resources.UI_ActionsMenu_Quality}
                                {isQualityListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </Box>
                        </Box>
                    }
                    {isQualityListOpened && !props.isDisable &&
                        <QualityListFilterComponent open={isQualityListOpened}
                            anchorEl={qualityListAnchorEl}
                            activeQualities={props.activeQualities}
                            onClose={() => setQualityListAnchorEl(null)}
                            handleDisplaySections={props.handleDisplaySections}
                        />
                    }
                    {!props.POC && !props.statsOpened &&
                        <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${props.isDisable ? ' disabled' : ''} ${isDetectionListOpened ? 'selected' : ''}`} onClick={(e) => !props.isDisable ? handleOpenDetectionListFilter(e) : {}}>
                            <Box className={`counter ${props.activeAnomalies?.size > 0 ? 'filled' : ''}`}>{props.activeAnomalies.size > 0 ? props.activeAnomalies.size : ''}</Box>
                            <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                {Translate.Resources.UI_ActionsMenu_Detection}
                                {isDetectionListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </Box>
                        </Box>
                    }
                    {isDetectionListOpened && !props.isDisable && !props.POC &&
                        <DetectionListFilterComponent open={isDetectionListOpened}
                            anchorEl={detectionListAnchorEl}
                            activeAnomalies={props.activeAnomalies}
                            mergedProject={props.mergedProject}
                            onClose={() => setDetectionListAnchorEl(null)}
                            handleDisplayDetection={props.handleDisplayDetections}
                            setAnomaliesData={props.setAnomaliesData}
                            projectVersionAnomalies={props.projectVersionAnomalies}
                        />
                    }
                    {props.POC && !props.statsOpened &&
                        <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${props.isDisable ? ' disabled' : ''} ${isDetectionListOpened ? 'selected' : ''}`} onClick={(e) => !props.isDisable ? handleOpenDetectionListFilter(e) : {}}>
                            <Box className={`counter ${(props.activeAnomalies.size + props.activeMarkingDetections.size + props.activeComplementaryDetections.size + props.activeWarningDetections.size + props.activeInformationDetections.size + props.activeRegulatoryDetections.size) > 0 ? 'filled' : ''}`}>
                                {(props.activeAnomalies.size + props.activeMarkingDetections.size + props.activeComplementaryDetections.size + props.activeWarningDetections.size + props.activeInformationDetections.size + props.activeRegulatoryDetections.size) > 0 ? (props.activeAnomalies.size + props.activeMarkingDetections.size + props.activeComplementaryDetections.size + props.activeWarningDetections.size + props.activeInformationDetections.size + props.activeRegulatoryDetections.size) : ''}
                            </Box>
                            <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                {Translate.Resources.UI_ActionsMenu_Detection}
                                {isDetectionListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </Box>
                        </Box>
                    }
                    {isDetectionListOpened && !props.isDisable && props.POC && !props.statsOpened &&
                        <DetectionListFilterComponent2 open={isDetectionListOpened}
                            anchorEl={detectionListAnchorEl}
                            activeAnomalies={props.activeAnomalies}
                            activeMarkingDetections={props.activeMarkingDetections}
                            activeComplementaryDetections={props.activeComplementaryDetections}
                            activeWarningDetections={props.activeWarningDetections}
                            activeInformationDetections={props.activeInformationDetections}
                            activeRegulatoryDetections={props.activeRegulatoryDetections}
                            mergedProject={props.mergedProject}
                            projectVersionAnomalies={props.projectVersionAnomalies}
                            projectVersionMarking={props.projectVersionMarking}
                            projectVersionComplementary={props.projectVersionComplementary}
                            projectVersionWarning={props.projectVersionWarning}
                            projectVersionInformation={props.projectVersionInformation}
                            projectVersionRegulatory={props.projectVersionRegulatory}
                            onClose={() => setDetectionListAnchorEl(null)}
                            handleDisplayDetection={props.handleDisplayDetections2}
                            setAnomaliesData={props.setAnomaliesData}
                        />
                    }
                    <Box className="auscultations-dates" display="flex" flexDirection="row" alignItems="center">
                        <Box display="flex" flexDirection="row" alignItems="center" className="from-date">
                            <Box className="title">{Translate.Resources.UI_ActionsMenu_SurveyFrom}</Box>
                            <Box>
                                <Select
                                    className={auscultations.isFromDateDisabled || props.isDisable ? "select disabled" : "select"}
                                    value={auscultations.fromDate}
                                    disabled={auscultations.isFromDateDisabled || props.isDisable}
                                    onChange={(e) => handleFromDateChanged(e.target.value as number)}
                                >
                                    {auscultations.list.map((item, index: number) => {
                                        return (
                                            <MenuItem key={`fromDate-${index}`} value={item.value}>
                                                <ListItemIcon className="item-icon">
                                                    <CalendarMonthIcon />
                                                </ListItemIcon>
                                                {item.label}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </Box>
                        {!auscultations.hasOnlyAuscultation &&
                            <>
                                <Box display="flex" flexDirection="row" alignItems="center" className="to-date">
                                    <Box className="title">{Translate.Resources.UI_ActionsMenu_To}</Box>
                                    <Box>
                                        <Select
                                            className={auscultations.isToDateDisabled || props.isDisable ? "select disabled" : "select"}
                                            value={auscultations.toDate}
                                            disabled={auscultations.isToDateDisabled || props.isDisable}
                                            onChange={(e) => handleToDateChanged(e.target.value as number)}
                                        >
                                            {auscultations.list.map((item, index: number) => {
                                                return (
                                                    <MenuItem key={`toDate-${index}`} value={item.value}>
                                                        <ListItemIcon className="item-icon">
                                                            <CalendarMonthIcon />
                                                        </ListItemIcon>
                                                        {item.label}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                </Box>
                                <Checkbox className={`checkbox ${props.isDisable ? 'disabled' : ''}`}
                                    checked={auscultations.isCompleteHeritageSelected}
                                    onChange={(e) => handleIsCompleteHeritageSelected(e)}
                                    title={Translate.Resources.UI_RoadCondition_Checkbox_Tooltip_CumulativeDisplayOfSurvey}
                                    disabled={props.isDisable}
                                />
                            </>
                        }
                    </Box>
                    {!props.statsOpened &&
                        <>
                            <Box className="title filter">{`${Translate.Resources.UI_ActionsMenu_Filter} :`}</Box>
                            <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${props.isDisable ? ' disabled' : ''} ${isGeographyListOpened ? 'selected' : ''}`} onClick={(e) => !props.isDisable ? handleOpenGeographyListFilter(e) : {}}>
                                <Box className={`counter ${activeGeographies > 0 ? 'filled' : ''}`}>{activeGeographies > 0 ? activeGeographies : ''}</Box>
                                <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                    {Translate.Resources.UI_ActionsMenu_Geography}
                                    {isGeographyListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </Box>
                            </Box>
                        </>
                    }
                    {isGeographyListOpened && !props.isDisable &&
                        <GeographyListFilterComponent open={isGeographyListOpened}
                            anchorEl={geographyListAnchorEl}
                            mergedProject={props.mergedProject}
                            activeMunicipalities={props.activeMunicipalities}
                            activeDistricts={props.activeDistricts}
                            activeCollaborativeDevelopmentZones={props.activeCollaborativeDevelopmentZones}
                            onClose={() => setGeographyListAnchorEl(null)}
                            handleDisplaySections={props.handleDisplaySectionsFromGeographiesFilters}
                        />
                    }
                    {!props.statsOpened &&
                        <Box display="flex" flexDirection="row" alignItems="center" className={`btn-toggle-popper ${props.isDisable ? ' disabled' : ''} ${isAttributeListOpened ? 'selected' : ''}`} onClick={(e) => !props.isDisable ? handleOpenAttributeListFilter(e) : {}}>
                            <Box className={`counter ${activeAttributes > 0 ? 'filled' : ''}`}>{activeAttributes > 0 ? activeAttributes : ''}</Box>
                            <Box className="text" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                {Translate.Resources.UI_ActionsMenu_Attributes}
                                {isAttributeListOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </Box>
                        </Box>
                    }
                    {isAttributeListOpened && !props.isDisable &&
                        <AttributesListFilterComponent open={isAttributeListOpened}
                            anchorEl={attributeListAnchorEl}
                            activeHierarchies={props.activeHierarchies}
                            activeTraffics={props.activeTraffics}
                            activeEnvironments={props.activeEnvironments}
                            activeManagers={props.activeManagers}
                            activeImportances={props.activeImportances}
                            activeOtherAttributes={props.activeOtherAttributes}
                            onClose={() => setAttributeListAnchorEl(null)}
                            handleDisplaySections={props.handleDisplaySectionsFromAttributesFilters}
                        />
                    }
                </Box>
                {props.role === "ADM" && !props.statsOpened &&
                    <Box className="search-input">
                        <Input disableUnderline className={`input-search-images`} inputRef={props.inputImageRef}
                            id="input-search-images-text"
                            placeholder="Image Id"
                            onKeyPress={(event) => props.handleSearchImageKeyPressed(event.code)}
                        />
                    </Box>
                }
            </Box>
        </Box>
    );
}