import { Box } from '@mui/system';
import { AnimationOptions, CameraBoundsOptions, data, layer, Map as AzureMap, MapMouseEvent, Popup, Shape, source } from 'azure-maps-control';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { barAction, MapActionBar } from '../../../../shared/components/MapActionBar/MapActionBar';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, createMap, createShape, getAreaShapeId, getAreaTooltip, getEnlightenedAreaShapeId, getMapChoiceValue, getSectionShapeId, getSelectedSectionShapeId, hideAreaEnlightenedShape, hideSectionSelectedShape, sectionWidth, setMapCameraFromPositions, setMapCursor, showAreaEnlightenedShape, showSectionSelectedShape, transparentColor } from '../../../../shared/Map/MapUtils';
import { MapCursorMode } from '../../../../shared/models/MapCursorMode';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { Point } from '../../../../shared/models/Point';
import { getScoreType } from '../../../../shared/models/ScoreTypes';
import { ShapeEntityType } from '../../../../shared/models/ShapeEntityType';
import styles from '../../../../_variables.scss';
import styles2 from '../../../../_variables2.scss';
import { ImageExtended } from '../../../RoadsCondition/models/ImageExtended';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { OtherAttributes } from '../../../RoadsCondition/models/OtherAttributes';
import { RoadSectionViewData } from '../../../RoadsCondition/models/RoadSectionViewData';
import { ScoreTypesColors } from '../../../RoadsCondition/models/ScoreTypesColors';
import { mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { Environment } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { StepImageAnomalies } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { ProgrammingAreaExtended } from '../../models/ProgrammingAreaExtended';
import { ProgrammingExtended } from '../../models/ProgrammingExtended';
import { AreaSection } from '../../services/dataContracts/queryStack/AreaSection';
import { FilteredProgramming } from '../../services/dataContracts/queryStack/FilteredProgramming';
import { ProgrammingArea } from '../../services/dataContracts/queryStack/ProgrammingArea';
import './AreasMapStyles.scss';

const cursorAutoClassName = "cursor-auto";
const cursorCrosshairClassName = "cursor-crosshair";

interface AreasMapComponentProps {
    areaToEdit: ProgrammingArea,
    isAddOrEditAreaActive: boolean,
    isZonesListOpened: boolean,
    locationGeometry: Point,
    mergedProject: MergedProjectVersion,
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    inputSearchValue: string,
    selectedProgramming: ProgrammingExtended,
    showProgrammingArea: ProgrammingAreaExtended,
    hideProgrammingArea: ProgrammingAreaExtended,
    selectedSectionsIds: Set<number>,
    filteredProgrammingsFromFilter: FilteredProgramming[],
    perStepImagesAnomalies: Map<number, StepImageAnomalies[]>,
    currentMeasurementSystemType: MeasurementSystemType,
    selectedImage: ImageExtended,
    areasSections: Map<number, Map<number, AreaSection>>,
    reInitDisplayedProgrammingArea: () => void,
    reInitHidenProgrammingArea: () => void,
    onSelectedSectionChange: (sectionsIds: number[], areaToEdit: ProgrammingArea) => void,
    handleDisplayImageFromSectionClicked: (event: MapMouseEvent) => void,
    handleCLoseRoadSectionDetails: () => void
}

export const AreasMapComponent = (props: AreasMapComponentProps): JSX.Element => {

    const azureMap = useRef<AzureMap>();
    const selectedAction = useRef<barAction>();
    const areaTooltipPopup: Popup = new Popup({ closeButton: false });

    const anomalyPointClickHandler: (e: void | MapMouseEvent | layer.Layer) => void = null;

    const filteredAreasDatasource = "filteredAreasDatasource";
    const filteredArea = "filteredArea";
    const filteredAreasRoadLayerId = "filteredAreasRoadLayerId";

    const [selectedSectionsId, setSelectedSectionsId] = useState<Set<number>>(new Set<number>());
    const [filteredAndSelectedSectionsIds, setFilteredAndSelectedSectionsIds] = useState<Set<number>>(new Set<number>());
    const [activeAnomalies, setActiveAnomalies] = React.useState<Set<string>>(new Set<string>());

    let currentMapCursorClassName = cursorAutoClassName;

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.mergedProject) {
            initMap(azureMap.current, () => {
                let datasource = createMainDatasource();
                createMapSectionsShapes(props.mergedProject, datasource);
                setMapZoom(azureMap.current, props.mergedProject);
                displayMapAreasShapes(props.selectedProgramming, datasource);
            });
        }
    }, [props.mergedProject]);

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource && props.selectedProgramming && props.mergedProject) {
            datasource.getShapes().forEach((section: Shape) => {
                let properties = section.getProperties();
                let entityType = properties.EntityType;
                let sectionId = properties.RoadSectionId;
                if (entityType === ShapeEntityType.area) {
                    let unselectedAreaShapeId = getAreaShapeId(sectionId);
                    hideShape(unselectedAreaShapeId, datasource);
                }
            });

            displayMapAreasShapes(props.selectedProgramming, datasource);

            let roadLayer = azureMap.current.layers.getLayerById(roadLayerId);
            if (roadLayer) {
                azureMap.current.events.remove('mouseover', roadLayer, (e) => handleRoadLayerMouseover(e as MapMouseEvent));
                azureMap.current.events.remove('mouseout', roadLayer, handleRoadLayerMouseout);
                azureMap.current.layers.remove(roadLayer);
            }
            roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
            azureMap.current.layers.add(roadLayer);

            azureMap.current.events.add('mouseover', roadLayer, (e) => handleRoadLayerMouseover(e));
            azureMap.current.events.add('mouseout', roadLayer, handleRoadLayerMouseout);
        }
    }, [props.selectedProgramming]);

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource && props.showProgrammingArea) {
            props.showProgrammingArea.sectionsId.forEach((sectionId) => {
                let shapeId = getEnlightenedAreaShapeId(sectionId);
                showAreaEnlightenedShape(shapeId, datasource);

                showShape(getSectionShapeId(sectionId), datasource, sectionWidth);
                hideShape(getUnfilteredSectionShapeId(sectionId), datasource);
                hideShape(getAreaShapeId(sectionId), datasource);
            });
        }

        props.reInitDisplayedProgrammingArea();
    }, [props.showProgrammingArea]);

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource && props.hideProgrammingArea) {
            props.hideProgrammingArea.sectionsId.forEach((sectionId) => {
                let shapeId = getEnlightenedAreaShapeId(sectionId);
                hideAreaEnlightenedShape(shapeId, datasource);

                showShape(getUnfilteredSectionShapeId(sectionId), datasource, sectionWidth);
                showShape(getAreaShapeId(sectionId), datasource, areaWidth);
                hideShape(getSectionShapeId(sectionId), datasource);
            });
        }

        props.reInitHidenProgrammingArea();
    }, [props.hideProgrammingArea]);

    useEffect(() => {
        azureMap.current.resize("100%", "100%");
    }, [props.isAddOrEditAreaActive, props.isZonesListOpened])

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource) {
            let areasSections = props.areasSections;
            datasource.getShapes().forEach((section: Shape) => {
                let properties = section.getProperties();
                let entityType = properties.EntityType;
                let sectionId = properties.RoadSectionId;
                if (entityType === ShapeEntityType.section) {
                    let sectionShapeId = getSectionShapeId(sectionId);
                    let unfilteredSectionShapeId = getUnfilteredSectionShapeId(sectionId);

                    let shapeId = section.getId() as string;
                    if (shapeId === sectionShapeId && props.isAddOrEditAreaActive) {
                        if (props.areaToEdit) {
                            let programmingAreaId = props.areaToEdit.programmingAreaId;
                            let areaSections = areasSections.get(programmingAreaId);
                            if (areaSections.has(sectionId)) {
                                let areaSection = areaSections.get(sectionId);
                                let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(areaSection.score);
                                properties.sectionScoreType = getScoreType(scoreColor);
                                properties.strokeColor = scoreColor;
                            }
                        }
                        properties.strokeWidth = sectionWidth;
                    }
                    else if (shapeId === sectionShapeId && !props.isAddOrEditAreaActive) {
                        properties.strokeWidth = 0;
                    }
                    else if (shapeId === unfilteredSectionShapeId && props.isAddOrEditAreaActive) {
                        properties.strokeWidth = 0;
                    }
                    else if (shapeId === unfilteredSectionShapeId && !props.isAddOrEditAreaActive) {
                        properties.strokeWidth = sectionWidth;
                    }
                    section.setProperties(properties);
                }

                if (entityType === ShapeEntityType.areaEnlightened) {
                    if (properties.strokeWidth > 0) {
                        let enlightenedAreaShapeId = getEnlightenedAreaShapeId(sectionId);
                        hideAreaEnlightenedShape(enlightenedAreaShapeId, datasource);
                    }
                }
            });

            setMapZoom(azureMap.current, props.mergedProject);
        }
    }, [props.isAddOrEditAreaActive, props.areaToEdit])

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource) {
            let selectedSectionsIds = Array.from(selectedSectionsId);
            let unselectedSection = selectedSectionsIds.filter(x => !props.selectedSectionsIds.has(x));
            unselectedSection.forEach((sectionId: number) => {
                hideSectionSelectedShape(datasource, sectionId);
            });

            props.selectedSectionsIds.forEach((sectionId: number) => {
                showSectionSelectedShape(datasource, sectionId);
            });
        }

        setSelectedSectionsId(props.selectedSectionsIds);
    }, [props.selectedSectionsIds])

    useEffect(() => {
        if (props.mergedProject && props.isAddOrEditAreaActive) {
            let roadsSections = props.mergedProject.roadsSections;

            let sectionsVisibilities = updateSectionsVisibility(roadsSections, filteredAndSelectedSectionsIds, selectedSectionsId, props.activeQualities, props.inputSearchValue, props.activeMunicipalities, props.activeDistricts, props.activeCollaborativeDevelopmentZones, props.activeHierarchies, props.activeTraffics, props.activeEnvironments, props.activeManagers, props.activeImportances, props.activeOtherAttributes);
            setFilteredAndSelectedSectionsIds(sectionsVisibilities.filteredAndSelectedSections);

            if (sectionsVisibilities.filteredSections.size > 0) {
                let displayedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(sectionsVisibilities.filteredSections, props.mergedProject.roadsSections);
                setMapCameraFromPositions(azureMap.current, displayedSectionsPositions);
            }
            else {
                setMapZoom(azureMap.current, props.mergedProject);
            }

            let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
            if (datasource) {
                datasource.getShapes().forEach((section: Shape) => {
                    let properties = section.getProperties();
                    let entityType = properties.EntityType;
                    if (entityType === ShapeEntityType.section) {
                        let sectionId = properties.RoadSectionId;

                        let sectionShapeId = getSectionShapeId(sectionId);
                        let unfilteredSectionShapeId = getUnfilteredSectionShapeId(sectionId);

                        let shapeId = section.getId() as string;
                        if (sectionsVisibilities.filteredAndSelectedSections.has(sectionId) || props.selectedSectionsIds.has(sectionId)) {
                            if (shapeId === sectionShapeId) {
                                properties.strokeWidth = sectionWidth;
                            }
                            else if (shapeId === unfilteredSectionShapeId) {
                                properties.strokeWidth = 0;
                            }
                        }
                        else {
                            if (shapeId === sectionShapeId) {
                                properties.strokeWidth = 0;
                            }
                            else if (shapeId === unfilteredSectionShapeId) {
                                properties.strokeWidth = sectionWidth;
                            }
                        }

                        section.setProperties(properties);
                    }
                });
            }
        }
    }, [props.activeAnomalies, props.activeQualities, props.activeMunicipalities, props.activeDistricts, props.activeCollaborativeDevelopmentZones, props.activeHierarchies, props.activeTraffics, props.activeEnvironments, props.activeManagers, props.activeImportances, props.activeOtherAttributes, props.inputSearchValue])

    useEffect(() => {
        if (!isEqual(props.activeAnomalies, activeAnomalies)) {
            let hasAnomaliesLayerMapEvent = true;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(azureMap.current, props.mergedProject, hasAnomaliesLayerMapEvent, anomalyLayerMouseover, anomalyLayerMouseout, anomalyPointClickHandler, handleAnomalyPointClicked);

            if (props.activeAnomalies.size > 0) {
                props.perStepImagesAnomalies.forEach((value, key) => {
                    let step = props.mergedProject.roadsSteps.get(key);
                    let scoringParameters = step.scoringParameters;
                    let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(value, scoringParameters, props.activeAnomalies);

                    if (anomalies.size >= 1) {
                        let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                        anomaliesDatasource.add(anomalyPoint);
                    }
                });
            }

            setActiveAnomalies(props.activeAnomalies);
        }
    }, [props.activeAnomalies]);

    useEffect(() => {
        removeFilteredAreasDatasourceShapes();

        if (props.filteredProgrammingsFromFilter) {
            let datasource = createFilteredAreasDatasource();

            props.filteredProgrammingsFromFilter.forEach((p) => {
                if (p.programmingId !== props.selectedProgramming.programmingId)
                    createFilteredMapAreasShapes(p, datasource);
            });
        }
    }, [props.filteredProgrammingsFromFilter])

    useEffect(() => {
        if (props.selectedImage) {
            RoadsConditionAndScenariosShared.setImagePosition(azureMap.current, props.selectedImage, props.mergedProject);
            return;
        }

        RoadsConditionAndScenariosShared.removeCarDatasource(azureMap.current);
    }, [props.selectedImage])

    useEffect(() => {
        return function cleanup() {
            azureMap.current?.dispose();
        }
    }, [])

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (callback) {
                callback();
            }
        });
    }

    const createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        azureMap.current.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        azureMap.current.layers.add(roadLayer);

        azureMap.current.events.add('mouseover', roadLayer, (e) => handleRoadLayerMouseover(e));
        azureMap.current.events.add('mouseout', roadLayer, handleRoadLayerMouseout);

        return datasource;
    }

    const createFilteredAreasDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(filteredAreasDatasource);
        azureMap.current.sources.add(datasource);

        let filteredAreasRoadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, filteredAreasRoadLayerId);
        azureMap.current.layers.add(filteredAreasRoadLayer);

        azureMap.current.events.add('mouseover', filteredAreasRoadLayer, (e) => handleRoadLayerMouseover(e));
        azureMap.current.events.add('mouseout', filteredAreasRoadLayer, handleRoadLayerMouseout);

        return datasource;
    }

    const handleRoadLayerMouseover = (e: MapMouseEvent): void => {
        let classList = azureMap.current.getCanvasContainer().classList;

        if (classList.contains(cursorAutoClassName)) {
            currentMapCursorClassName = cursorAutoClassName;
        }
        else if (classList.contains(cursorCrosshairClassName)) {
            currentMapCursorClassName = cursorCrosshairClassName;
        }
        handleLayerMouseover();

        let tooltips: string[] = [];
        let areasAlreadyChecked = new Map<number, number>();
        e.shapes.forEach((s) => {
            let shape = s as Shape;
            let shapeProps = shape.getProperties();
            if (shapeProps.EntityType === filteredArea) {
                let sectionId = shapeProps.RoadSectionId;

                props.filteredProgrammingsFromFilter.forEach(p => {
                    let areas = p.areas;
                    areas.forEach((area) => {
                        if (!areasAlreadyChecked.has(area.programmingAreaId) && area.sections.some(s => s.roadSectionId === sectionId)) {
                            areasAlreadyChecked.set(area.programmingAreaId, area.programmingAreaId);
                            let tooltip = getAreaTooltip(p.year, p.label, area.label, area.selectedWork);
                            if (tooltip) {
                                tooltips.push(tooltip);
                            }
                        }
                    });
                });
            }

            if (shapeProps.EntityType === ShapeEntityType.area || shapeProps.EntityType === ShapeEntityType.areaEnlightened || shapeProps.EntityType === ShapeEntityType.sectionSelected) {
                let sectionId = shapeProps.RoadSectionId;
                let p = props.selectedProgramming;
                let areas = p.areas;
                areas.forEach((area) => {
                    if (!areasAlreadyChecked.has(area.programmingAreaId) && area.sections.some(s => s.roadSectionId === sectionId)) {
                        areasAlreadyChecked.set(area.programmingAreaId, area.programmingAreaId);
                        let tooltip = getAreaTooltip(p.year, p.label, area.label, area.selectedWork);
                        if (tooltip) {
                            tooltips.push(tooltip);
                        }
                    }
                });
            }
        });

        if (tooltips.length > 0) {
            let content = `<div style="padding:10px;">`;
            tooltips.forEach(tooltip => {
                content += `<p style="margin:0;">${tooltip}</p>`;
            });
            content += `</div>`;

            areaTooltipPopup.setOptions({
                content: content,
                position: e.position,
                pixelOffset: [0, -18]
            });
            areaTooltipPopup.open(azureMap.current);
        }
    }

    const handleRoadLayerMouseout = (): void => {
        handleLayerMouseout();

        if (areaTooltipPopup.isOpen()) {
            areaTooltipPopup.close();
        }
    }

    const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        };

        options.type = cameraAnimationType;
        options.duration = cameraAnimationDuration;

        map.setCamera(options);
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion, datasource: source.DataSource): void => {
        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let enlightenedAreaShapeId = getEnlightenedAreaShapeId(roadSectionId);
            let selectedAreaShape = createShape(coordinates, enlightenedAreaShapeId, transparentColor, 0, ShapeEntityType.areaEnlightened, roadSectionId);
            datasource.add(selectedAreaShape);

            let selectedSectionShapeId = getSelectedSectionShapeId(roadSectionId);
            let selectedSectionShape = createShape(coordinates, selectedSectionShapeId, transparentColor, 0, ShapeEntityType.sectionSelected, roadSectionId);
            datasource.add(selectedSectionShape);

            let sectionShapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
            let sectionShape = createShape(coordinates, sectionShapeId, strokeColor, 0, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(sectionShape);

            let unfilteredSectionShapeId = getUnfilteredSectionShapeId(roadSectionId);
            let unfilteredSectionShape = createShape(coordinates, unfilteredSectionShapeId, styles.unfilteredSectionColor, sectionWidth, ShapeEntityType.section, roadSectionId);
            datasource.add(unfilteredSectionShape);

            let unselectedAreaShapeId = getAreaShapeId(roadSectionId);
            let unselectedAreaShape = createShape(coordinates, unselectedAreaShapeId, transparentColor, 0, ShapeEntityType.area, roadSectionId);
            datasource.add(unselectedAreaShape);
        });
    }

    const displayMapAreasShapes = (selectedProgramming: ProgrammingExtended, datasource: source.DataSource): void => {
        if (selectedProgramming) {
            selectedProgramming.areas.forEach((area) => {
                area.sections.map(x => x.roadSectionId).forEach((sectionId) => {
                    let unselectedAreaShapeId = getAreaShapeId(sectionId);
                    showShape(unselectedAreaShapeId, datasource, areaWidth, area.hexColor);
                    showShape(getUnfilteredSectionShapeId(sectionId), datasource, areaWidth);
                });
            });
        }
    }

    const removeFilteredAreasDatasourceShapes = (): void => {
        let filteredAreasRoadLayer = azureMap.current.layers.getLayerById(filteredAreasRoadLayerId);
        if (filteredAreasRoadLayer) {
            azureMap.current.events.remove('mouseover', filteredAreasRoadLayer, (e) => handleRoadLayerMouseover(e as MapMouseEvent));
            azureMap.current.events.remove('mouseout', filteredAreasRoadLayer, handleRoadLayerMouseout);
            azureMap.current.layers.remove(filteredAreasRoadLayer);
        }

        let datasource = azureMap.current.sources.getById(filteredAreasDatasource) as source.DataSource;
        if (datasource) {
            datasource.clear();
            azureMap.current.sources.remove(datasource);
        }
    }

    const createFilteredMapAreasShapes = (programming: FilteredProgramming, datasource: source.DataSource): void => {
        if (programming) {
            programming.areas.forEach((area) => {
                area.sections.forEach((section) => {
                    let sectionId = section.roadSectionId;
                    let filteredAreaShapeId = getFilteredAreaShapeId(sectionId);
                    let unselectedAreaShape = createShape(section.pathGeometry.coordinates, filteredAreaShapeId, area.hexColor, areaWidth, filteredArea, sectionId);
                    datasource.add(unselectedAreaShape);
                });
            });
        }
    }

    const getFilteredAreaShapeId = (roadSectionId: number): string =>
        `${roadSectionId}/filteredArea`;

    const updateSectionsVisibility = (sections: Map<number, RoadSectionViewData>, filteredAndSelectedSectionsIds: Set<number>, selectedSectionsIds: Set<number>, activeQualities: Set<number>, inputSearchText: string, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>): { filteredAndSelectedSections: Set<number>, filteredSections: Set<number> } => {
        let filteredAndSelectedSections = new Set<number>(filteredAndSelectedSectionsIds);
        let filteredSections = new Set<number>();

        sections.forEach((section) => {
            if ((activeQualities.size > 0 && activeQualities.has(section.score)) &&
                (section.labelLowerWithoutDiacritics?.includes(inputSearchText)) &&
                ((activeMunicipalities.size > 0 && activeMunicipalities.has(section.municipality)) || activeMunicipalities.size === 0) &&
                ((activeDistricts.size > 0 && activeDistricts.has(section.district)) || activeDistricts.size === 0) &&
                ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(section.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                ((activeHierarchies.size > 0 && activeHierarchies.has(section.hierarchy)) || activeHierarchies.size === 0) &&
                ((activeTraffics.size > 0 && activeTraffics.has(section.traffic)) || activeTraffics.size === 0) &&
                ((activeEnvironments.size > 0 && activeEnvironments.has(section.environment)) || activeEnvironments.size === 0) &&
                ((activeManagers.size > 0 && activeManagers.has(section.manager)) || activeManagers.size === 0) &&
                ((activeImportances.size > 0 && activeImportances.has(section.importance ? section.importance.toString() : null)) || activeImportances.size === 0) &&
                ((activeOtherAttributes.size > 0 && (
                    (section.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                    (section.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                    (section.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                    (section.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                    (section.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                    (!section.bus && !section.bikeLase && !section.border && !section.ditch && !section.side && activeOtherAttributes.has(null))
                )) || activeOtherAttributes.size === 0)) {

                if (!filteredAndSelectedSections.has(section.roadSectionId)) {
                    filteredAndSelectedSections.add(section.roadSectionId);
                }

                filteredSections.add(section.roadSectionId);
            }
            else {
                if (filteredAndSelectedSections.has(section.roadSectionId) && !selectedSectionsIds.has(section.roadSectionId)) {
                    filteredAndSelectedSections.delete(section.roadSectionId);
                }

                filteredSections.delete(section.roadSectionId);
            }
        });

        return {
            filteredAndSelectedSections: filteredAndSelectedSections,
            filteredSections: filteredSections
        };
    }

    const showShape = (shapeId: string, datasource: source.DataSource, strokeWidth: number, strokeColor?: string): void => {
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            let props = shape.getProperties();
            props.strokeWidth = strokeWidth;

            if (strokeColor) {
                props.strokeColor = strokeColor;
            }

            shape.setProperties(props);
        }
    }

    const hideShape = (shapeId: string, datasource: source.DataSource): void => {
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            let props = shape.getProperties();
            props.strokeWidth = 0;
            shape.setProperties(props);
        }
    }

    const anomalyLayerMouseover = (): void => {
        handleLayerMouseover();
    }

    const anomalyLayerMouseout = (): void => {
        handleLayerMouseout();
    }

    const handleLayerMouseover = (): void => {
        if (selectedAction.current === barAction.carSelect) {
            setMapCursor(azureMap.current, MapCursorMode.Car);
            return;
        }

        if (selectedAction.current === barAction.MonoSelect) {
            setMapCursor(azureMap.current, MapCursorMode.Pointer);
            return;
        }

        let cursorMode = currentMapCursorClassName === cursorCrosshairClassName ? MapCursorMode.Crosshair : MapCursorMode.Auto;
        setMapCursor(azureMap.current, cursorMode);
    }

    const handleLayerMouseout = (): void => {
        let cursorMode = currentMapCursorClassName === cursorCrosshairClassName ? MapCursorMode.Crosshair : MapCursorMode.Auto;
        setMapCursor(azureMap.current, cursorMode);
    }

    const handleAnomalyPointClicked = (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion): void => {
        e = e as MapMouseEvent;
        let shape = e.shapes[0] as Shape;
        let shapeProps = shape.getProperties();
        let section: RoadSectionViewData = mergedProject.roadsSections.get(shapeProps.sectionId);
        if (section) {
            //let clickedPosition = e.position;
            //TODO HGA handleSectionClicked(section, clickedPosition);
        }
    }

    const selectedActionChanged = (action: barAction): void => {
        selectedAction.current = action;
    }

    const getUnfilteredSectionShapeId = (roadSectionId: number): string =>
        `${roadSectionId}/unfiltered`;

    return (
        <Box className={`areas-map-content ${props.isAddOrEditAreaActive ? 'in-edit' : ''}`}>
            {props.mergedProject && props.isAddOrEditAreaActive && <div id="measurementInfo" className="measure"></div>}
            <div className="map-actions">
                {props.mergedProject && props.isAddOrEditAreaActive &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[barAction.carSelect, barAction.MonoSelect, barAction.ZoneSelect, barAction.ClearZone, barAction.Measure]}
                        selectedSectionsId={Array.from(props.selectedSectionsIds)}
                        sections={props.mergedProject.roadsSections}
                        mainLayer={roadLayerId}
                        currentMeasurementSystemType={props.currentMeasurementSystemType}
                        selectedDefaultAction={barAction.MonoSelect}
                        onSelectedSectionChange={(sectionIds) => props.onSelectedSectionChange(sectionIds, props.areaToEdit)}
                        handleDisplayImageFromSectionClicked={props.handleDisplayImageFromSectionClicked}
                        handleCLoseRoadSectionDetails={props.handleCLoseRoadSectionDetails}
                        selectedActionChanged={selectedActionChanged}
                    />
                }
                {props.mergedProject && !props.isAddOrEditAreaActive &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[barAction.carSelect]}
                        selectedSectionsId={[]}
                        sections={null}
                        mainLayer={roadLayerId}
                        currentMeasurementSystemType={null}
                        selectedDefaultAction={barAction.None}
                        onSelectedSectionChange={null}
                        handleDisplayImageFromSectionClicked={props.handleDisplayImageFromSectionClicked}
                        handleCLoseRoadSectionDetails={props.handleCLoseRoadSectionDetails}
                        selectedActionChanged={selectedActionChanged}
                    />
                }
            </div>
            <div id="AzureMap"></div>
        </Box>
    );
}
