import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { orderBy as sortOrderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import Translate, { Localization } from '../../../../../localization/Localization';
import { MeasurementSystemType } from '../../../../../shared/models/MeasurementSystemType';
import { getScoreType } from '../../../../../shared/models/ScoreTypes';
import { MeasurementSystem } from '../../../../../utils/MeasurementSystem';
import { AppModule, LocalStorage } from '../../../../../utils/Storage';
import { RoadsConditionAndScenariosShared } from '../../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { getScoreTypeCssClass } from '../../../models/Quality';
import { getHiddenIsSelectedForSort, RoadSection } from '../../../models/RoadSection';
import { Scenario } from '../../../services/dataContracts/queryStack/Scenario';
import { ScenarioBudgetSummaryComponent } from '../../CommonComponents/ScenarioBudgetSummaryComponent';
import '../../CommonComponents/SectionsSelectorStyles.scss';
import { SizingUtilities } from '../../SizingUtilities';

interface SectionsComponentProps {
    selectedScenario: Scenario,
    scenarioSections: Map<number, RoadSection>,
    selectedSectionsIds: Set<number>,
    currency: string,
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    measurementSystemType: MeasurementSystemType,
    handleUpdateScenarioSectionsClicked: () => void,
    handleRequestProgrammingClicked: () => void,
    updateSelectedSections: (selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>, lastSelectedSection: number) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ScenarioSectionsGridName = 'scenarioSections';
const ModuleKey = AppModule.Scenarios;

export const SectionsComponent = (props: SectionsComponentProps): JSX.Element => {

    let initialSort: SortDescriptor[] = [{ field: 'label', dir: 'desc' }];

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);
    const [totalSelectedSectionsLengthInMeters, setTotalSelectedSectionsLengthInMeters] = useState<number>(0);
    const [totalSelectedSectionsSurface, setTotalSelectedSectionsSurface] = useState<number>(0);

    useEffect(() => {
        let totalSelectedSectionsLengthInMeters = 0;
        let totalSelectedSectionsSurface = 0;

        props.scenarioSections.forEach((section) => {
            let lengthInMeters = Math.round(section.lengthInMeters);
            totalSelectedSectionsLengthInMeters += lengthInMeters;

            let surface = Math.round(lengthInMeters * section.widthInMeters);
            totalSelectedSectionsSurface += surface;
        });

        setTotalSelectedSectionsLengthInMeters(totalSelectedSectionsLengthInMeters);
        setTotalSelectedSectionsSurface(totalSelectedSectionsSurface);
    }, [props.scenarioSections, props.measurementSystemType]);

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        let sort = e.sort[0];

        if (sort && sort.field === "isSelected") {
            setSort([{ field: 'hiddenIsSelectedForSort', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "widthInMetersGridValue") {
            setSort([{ field: 'widthInMeters', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "surfaceGridValue") {
            setSort([{ field: 'surface', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "costGridValue") {
            setSort([{ field: 'cost', dir: sort.dir }, sort]);
            return;
        }

        setSort(e.sort);
    }

    const getGridOrderIndexColumn = (propName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ScenarioSectionsGridName, propName, defaultIndex);
    }

    const getGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ScenarioSectionsGridName, fieldName, columnWidth);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id === event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ScenarioSectionsGridName,
            currentColumn.field,
            currentColumn.width as number);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ScenarioSectionsGridName, event.columns);
        forceUpdate();
    }

    const handleRowClick = (item: RoadSection): void => {
        let sections = props.scenarioSections;
        let section = sections.get(item.roadSectionId);

        let selected = !section.isSelected;
        section.isSelected = selected;
        section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(selected);
        sections.set(section.roadSectionId, section);

        let selectedSectionsIds = new Set<number>(props.selectedSectionsIds);
        if (selected) {
            if (!selectedSectionsIds.has(section.roadSectionId)) {
                selectedSectionsIds.add(section.roadSectionId);
            }
        }
        else if (!selected) {
            if (selectedSectionsIds.has(section.roadSectionId)) {
                selectedSectionsIds.delete(section.roadSectionId);
            }
        }

        props.updateSelectedSections(selectedSectionsIds, sections, selected ? item.roadSectionId : null);
    }

    let sectionsCost = Array.from(props.scenarioSections).map(x => x[1].cost ?? 0);
    let sectionsCostSum = sectionsCost.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const forceUpdate = useForceUpdate();

    let gridHeightGapFromWindow: number = SizingUtilities.sectionsGridHeightGapFromWindow();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridHeightGapFromWindow);
    let rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    let gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridHeightGapFromWindow;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    let filteredSections: RoadSection[] = [];
    props.scenarioSections.forEach((element) => {
        if (element.isVisible === true) {
            filteredSections.push(element);
        }
    });

    if (skip > filteredSections.length || isNaN(skip)) {
        setSkip(0);
    }

    let dataGrid = sortOrderBy(filteredSections, sort).slice(skip, skip + gridPageSize);

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    return (
        <Box className={`sections-selector ${props.isSectionsDrawerOpened ? 'opened' : ''}`}>
            <ScenarioBudgetSummaryComponent
                sectionsCostSum={sectionsCostSum}
                currency={props.currency}
                selectedScenario={props.selectedScenario}
                selectedSectionCount={props.scenarioSections?.size}
                totalSelectedSectionsLengthInMeters={totalSelectedSectionsLengthInMeters}
                totalSelectedSectionsSurface={totalSelectedSectionsSurface}
            />
            <Box>
                <LocalizationProvider language={Localization.locale}>
                    <IntlProvider locale={Localization.locale}>
                        <Grid
                            className="grid"
                            data={dataGrid}
                            selectedField="isSelected"
                            sortable
                            resizable
                            reorderable
                            sort={sort}
                            scrollable="virtual"
                            skip={skip}
                            total={filteredSections.length}
                            rowHeight={rowHeight}
                            pageSize={gridPageSize}
                            style={gridStyle}
                            onPageChange={pageChange}
                            onSortChange={handleSortChange}
                            onColumnResize={onResizeHandler}
                            onColumnReorder={onReorderHandler}
                            onRowClick={(e) => handleRowClick(e.dataItem)}
                        >
                            <GridColumn title="" width="8px" orderIndex={0} resizable={false} reorderable={false}
                                cell={(_props) => {
                                    let scoreType = _props.dataItem.scoreType;
                                    if (_props.dataItem.scenarioSectionScore && _props.dataItem.scenarioSectionScore.score !== _props.dataItem.score) {
                                        let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(_props.dataItem.scenarioSectionScore.score);
                                        scoreType = getScoreType(scoreColor);
                                    }

                                    return <td className={`score-color-indicator ${getScoreTypeCssClass.get(scoreType)}`}></td>
                                }}
                            />
                            <GridColumn field="roadSectionId" orderIndex={getGridOrderIndexColumn("roadSectionId", 1)} minResizableWidth={60} width={getGridWidth("roadSectionId", 100)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Section} />
                            <GridColumn field="roadLabel" orderIndex={getGridOrderIndexColumn("label", 2)} minResizableWidth={60} width={getGridWidth("label", 200)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Road} />
                            <GridColumn field="workPriority" className="column-number" orderIndex={getGridOrderIndexColumn("workPriority", 3)} minResizableWidth={60} width={getGridWidth("workPriority", 100)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_PriorityOfWork} />
                            <GridColumn field="importance" className="column-number" orderIndex={getGridOrderIndexColumn("importance", 4)} minResizableWidth={60} width={getGridWidth("importance", 100)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Importance} />
                            <GridColumn field="lengthInMetersGridValue" className="column-number" orderIndex={getGridOrderIndexColumn("lengthInMetersGridValue", 5)} minResizableWidth={60} width={getGridWidth("lengthInMetersGridValue", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Linear} (${symbolOfLengthUnit})`}
                                cell={(cellProps) => <td>{MeasurementSystem.getLengthInMetersOrYards(cellProps.dataItem.lengthInMeters)?.toFixedLocalized(0)}</td>}/>
                            <GridColumn field="widthInMeters" className="column-number" orderIndex={getGridOrderIndexColumn("widthInMeters", 6)} minResizableWidth={60} width={getGridWidth("widthInMeters", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Width} (${symbolOfLengthUnit})`}
                                cell={(cellProps) => <td>{MeasurementSystem.getLengthInMetersOrYards(cellProps.dataItem.widthInMeters)?.toFixedLocalized(0)}</td>}  />
                            <GridColumn field="surface" className="column-number" orderIndex={getGridOrderIndexColumn("surface", 7)} minResizableWidth={60} width={getGridWidth("surface", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Surface} (${symbolOfSurfaceUnit})`}
                                cell={(cellProps) => <td >{MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(cellProps.dataItem.surface)?.toFixedLocalized(0)}</td> } />
                            <GridColumn field="costRatio" className="column-number" orderIndex={getGridOrderIndexColumn("costRatio", 8)} minResizableWidth={60} width={getGridWidth("costRatio", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Ratio} (${props.currency}/${symbolOfSurfaceUnit}) `}
                                cell={(cellProps) => <td>{MeasurementSystem.getRatioInMetersOrYards(cellProps.dataItem.costRatio)?.toFixedLocalized(2)}</td>} />
                            <GridColumn field="costGridValue" className="column-number" orderIndex={getGridOrderIndexColumn("costGridValue", 9)} minResizableWidth={60} width={getGridWidth("costGridValue", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Cost} (${props.currency})`} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box className="btns-actions" display="flex" flexDirection="row" justifyContent="end">
                <Button className="btn-primary" disabled={props.scenarioSections.size === 0} onClick={props.handleRequestProgrammingClicked}>
                    {Translate.Resources.UI_ScenarioVisualisation_Sections_Button_RequestProgramming}
                </Button>
                <Button className="btn-primary" onClick={() => props.handleUpdateScenarioSectionsClicked()}>
                    {Translate.Resources.UI_ScenarioVisualisation_Sections_Button_ModifyTheSelectionOfSections}
                </Button>
            </Box>
        </Box>
    );
}

                 
