import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { MetersOrYardsRoundedCell } from '../../../../shared/GridUtils/GridUtils';
import { getScoreType, ScoreTypes } from '../../../../shared/models/ScoreTypes';
import { RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { AreaRoadSectionViewData } from '../../models/AreaRoadSectionViewData';
import { RoadTrunkLabelScoreExtended } from '../../models/RoadTrunkLabelScoreExtended';
import { AreaSection } from '../../services/dataContracts/queryStack/AreaSection';
import { ProgrammingArea } from '../../services/dataContracts/queryStack/ProgrammingArea';
import './SectionsSelectorStyles.scss';

interface DetailsRoadGridComponentProps {
    dataItem: RoadTrunkLabelScoreExtended,
    areasSections: Map<number, Map<number, AreaSection>>,
    isNew: boolean,
    areaToEdit: ProgrammingArea,
    handleSelectSectionChanged: (checked: boolean, roadSectionId: number) => void
}

export const DetailsRoadGridComponent = (props: DetailsRoadGridComponentProps) => {

    let sections: AreaRoadSectionViewData[] = [];
    let dataSections = props.dataItem.sections.filter(x => x.isVisible || x.isSelected);
    dataSections.forEach((section) => {
        let roadSection = section as AreaRoadSectionViewData;
        if (props.isNew === false) {
            let areaSections = props.areasSections.get(props.areaToEdit.programmingAreaId);
            if (areaSections.has(section.roadSectionId)) {
                let areaSection = areaSections.get(section.roadSectionId);
                roadSection.areaSectionScore = areaSection.score;
            }
            else {
                roadSection.areaSectionScore = section.score;
            }
        }
        else {
            roadSection.areaSectionScore = section.score;
        }

        let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(roadSection.areaSectionScore);
        roadSection.areaSectionScoreType = getScoreType(scoreColor);

        sections.push(roadSection);
    });

    return (
        <Grid
            className="sections-details-grid"
            data={sections}
            rowHeight={36}
        >
            <GridColumn field="score" width="8px"
                cell={(properties: GridCellProps) => <td className={`score-color ${properties.dataItem.areaSectionScoreType === ScoreTypes.monitoring ? 'monitoring' :
                    (properties.dataItem.areaSectionScoreType === ScoreTypes.localizedRepair ? 'localized-repair' :
                        (properties.dataItem.areaSectionScoreType === ScoreTypes.generalMaintenance ? 'general-maintenance' :
                            (properties.dataItem.areaSectionScoreType === ScoreTypes.reinforcement ? 'reinforcement' :
                                (properties.dataItem.areaSectionScoreType === ScoreTypes.rehabilitation ? 'rehabilitation' : 'empty'))))}`}>
                </td>}
            />
            <GridColumn field="isSelected" width="50px"
                cell={(_props: GridCellProps) =>
                    <td >
                        <input type="checkbox" className="k-checkbox" checked={_props.dataItem.isSelected} onChange={(e) => props.handleSelectSectionChanged(e.target.checked, _props.dataItem.roadSectionId)} />
                    </td>}
            />
            <GridColumn field="roadSectionId" width="210px"
                cell={(properties: GridCellProps) => <td>{`Section ${properties.dataItem.roadSectionId}`}</td>} />
            <GridColumn field="areaSectionScore" width="100px"
                cell={(properties: GridCellProps) => <td className="column-number">{properties.dataItem.areaSectionScore}</td>} />
            <GridColumn field="lengthInMeters" width="130" cell={MetersOrYardsRoundedCell} />
        </Grid>
    )
}