import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import Translate from '../../../localization/Localization';
import { Hierarchy, hierarchyColors, Manager, managerColors, Traffic, trafficColors } from '../../../shared/Map/AttributesColors';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import './IndicatorStatsStyles.scss';

interface PieChartModel {
    id: string,
    lengthInMeters: number,
    surface?: number,
    color: string,
    percent: number
}

interface SurfacesByDistrict {
    district: string,
    mainHierarchySectionsSurface: number,
    localHierarchySectionsSurface: number,
    residentialHierarchySectionsSurface: number,
    strongTrafficSectionsSurface: number,
    mediumTrafficSectionsSurface: number,
    lowTrafficSectionsSurface: number
}

interface IndicatorStatsComponentProps {
    mergedProject: MergedProjectVersion
}

enum TooltipUnitMode {
    Length,
    Surface
}

const CustomItemTooltipContent = (props): JSX.Element => {
    const { itemData, series, data, unitMode } = props;
    let item = series.data[itemData.dataIndex];
    let value = data.find(x => x.id === item.id);

    if (unitMode === TooltipUnitMode.Length) {
        return (
            <Box className="stats-pie-chart-tooltip">
                <div>{`${MeasurementSystem.getLenghtInKilometersOrMiles(value.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfKilometersOrMiles()}`}</div>
                <div>{`${value.percent.toFixedLocalized(2)} %`}</div>
            </Box>
        );
    }
    else if (unitMode === TooltipUnitMode.Surface) {
        return (
            <Box className="stats-pie-chart-tooltip">
                <div>{`${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(value.surface).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfSurfaceUnit()}`}</div>
                <div>{`${value.percent.toFixedLocalized(2)} %`}</div>
            </Box>
        );
    }
};

export const IndicatorStatsComponent = (props: IndicatorStatsComponentProps): JSX.Element => {

    const [hierarchyData, setHierarchyData] = useState<PieChartModel[]>([]);
    const [trafficData, setTrafficData] = useState<PieChartModel[]>([]);
    const [managerData, setManagerData] = useState<PieChartModel[]>([]);
    const [hierarchyAndTrafficSurfacesByDistrict, setHierarchyAndTrafficSurfacesByDistrict] = useState<SurfacesByDistrict[]>([]);

    useEffect(() => {
        let mainHierarchySectionsLengthInMeters = 0;
        let localHierarchySectionsLengthInMeters = 0;
        let residentialHierarchySectionsLengthInMeters = 0;

        let mainHierarchySectionsSurface = 0;
        let localHierarchySectionsSurface = 0;
        let residentialHierarchySectionsSurface = 0;

        let strongTrafficSectionsLengthInMeters = 0;
        let mediumTrafficSectionsLengthInMeters = 0;
        let lowTrafficSectionsLengthInMeters = 0;

        let strongTrafficSectionsSurface = 0;
        let mediumTrafficSectionsSurface = 0;
        let lowTrafficSectionsSurface = 0;

        let municipalityManagerSectionsLengthInMeters = 0;
        let communityOfMunicipalitiesManagerSectionsLengthInMeters = 0;
        let agglomerationCommunityManagerSectionsLengthInMeters = 0;
        let metropolisManagerSectionsLengthInMeters = 0;
        let departmentManagerSectionsLengthInMeters = 0;
        let stateManagerSectionsLengthInMeters = 0;
        let privateManagerSectionsLengthInMeters = 0;

        let totalSectionsLengthInMeters = 0;

        props.mergedProject.roadsSections.forEach((section) => {
            let sectionLengthInMeters = section.lengthInMeters ? Math.round(section.lengthInMeters) : 0;
            let surface = sectionLengthInMeters * section.widthInMeters;

            switch (section.hierarchy) {
                case Hierarchy.main:
                    mainHierarchySectionsLengthInMeters += sectionLengthInMeters;
                    mainHierarchySectionsSurface += surface;
                    break;

                case Hierarchy.local:
                    localHierarchySectionsLengthInMeters += sectionLengthInMeters;
                    localHierarchySectionsSurface += surface;
                    break;

                case Hierarchy.residential:
                    residentialHierarchySectionsLengthInMeters += sectionLengthInMeters;
                    residentialHierarchySectionsSurface += surface;
                    break;

                default:
                    break;
            }

            switch (section.traffic) {
                case Traffic.strong:
                    strongTrafficSectionsLengthInMeters += sectionLengthInMeters;
                    strongTrafficSectionsSurface += surface;
                    break;

                case Traffic.medium:
                    mediumTrafficSectionsLengthInMeters += sectionLengthInMeters;
                    mediumTrafficSectionsSurface += surface;
                    break;

                case Traffic.low:
                    lowTrafficSectionsLengthInMeters += sectionLengthInMeters;
                    lowTrafficSectionsSurface += surface;
                    break;

                default:
                    break;
            }

            switch (section.manager) {
                case Manager.municipality:
                    municipalityManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Manager.communityOfMunicipalities:
                    communityOfMunicipalitiesManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Manager.agglomerationCommunity:
                    agglomerationCommunityManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Manager.metropolis:
                    metropolisManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Manager.department:
                    departmentManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Manager.state:
                    stateManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                case Manager.private:
                    privateManagerSectionsLengthInMeters += sectionLengthInMeters;
                    break;

                default:
                    break;
            }

            totalSectionsLengthInMeters = totalSectionsLengthInMeters + sectionLengthInMeters;
        });

        let mainHierarchySectionsPercent = totalSectionsLengthInMeters ? (mainHierarchySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let localHierarchySectionsPercent = totalSectionsLengthInMeters ? (localHierarchySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let residentialHierarchySectionsPercent = totalSectionsLengthInMeters ? (residentialHierarchySectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let hierarchyArray: PieChartModel[] = [];
        if (mainHierarchySectionsLengthInMeters > 0) {
            hierarchyArray.push({
                id: Hierarchy.main,
                color: hierarchyColors[Hierarchy.main],
                lengthInMeters: mainHierarchySectionsLengthInMeters,
                surface: mainHierarchySectionsSurface,
                percent: mainHierarchySectionsPercent
            })
        }
        if (localHierarchySectionsLengthInMeters > 0) {
            hierarchyArray.push({
                id: Hierarchy.local,
                color: hierarchyColors[Hierarchy.local],
                lengthInMeters: localHierarchySectionsLengthInMeters,
                surface: localHierarchySectionsSurface,
                percent: localHierarchySectionsPercent
            })
        }
        if (residentialHierarchySectionsLengthInMeters > 0) {
            hierarchyArray.push({
                id: Hierarchy.residential,
                color: hierarchyColors[Hierarchy.residential],
                lengthInMeters: residentialHierarchySectionsLengthInMeters,
                surface: residentialHierarchySectionsSurface,
                percent: residentialHierarchySectionsPercent
            })
        }
        setHierarchyData(hierarchyArray);

        let strongTrafficSectionsPercent = totalSectionsLengthInMeters ? (strongTrafficSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let mediumTrafficSectionsPercent = totalSectionsLengthInMeters ? (mediumTrafficSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let lowTrafficSectionsPercent = totalSectionsLengthInMeters ? (lowTrafficSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let trafficArray: PieChartModel[] = [];
        if (strongTrafficSectionsLengthInMeters > 0) {
            trafficArray.push({
                id: Traffic.strong,
                color: trafficColors[Traffic.strong],
                lengthInMeters: strongTrafficSectionsLengthInMeters,
                surface: strongTrafficSectionsSurface,
                percent: strongTrafficSectionsPercent
            });
        }
        if (mediumTrafficSectionsLengthInMeters > 0) {
            trafficArray.push({
                id: Traffic.medium,
                color: trafficColors[Traffic.medium],
                lengthInMeters: mediumTrafficSectionsLengthInMeters,
                surface: mediumTrafficSectionsSurface,
                percent: mediumTrafficSectionsPercent
            });
        }
        if (lowTrafficSectionsLengthInMeters > 0) {
            trafficArray.push({
                id: Traffic.low,
                color: trafficColors[Traffic.low],
                lengthInMeters: lowTrafficSectionsLengthInMeters,
                surface: lowTrafficSectionsSurface,
                percent: lowTrafficSectionsPercent
            });
        }
        setTrafficData(trafficArray);

        let municipalityManagerSectionsPercent = totalSectionsLengthInMeters ? (municipalityManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let communityOfMunicipalitiesManagerSectionsPercent = totalSectionsLengthInMeters ? (communityOfMunicipalitiesManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let agglomerationCommunityManagerSectionsPercent = totalSectionsLengthInMeters ? (agglomerationCommunityManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let metropolisManagerSectionsPercent = totalSectionsLengthInMeters ? (metropolisManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let departmentManagerSectionsPercent = totalSectionsLengthInMeters ? (departmentManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let stateManagerSectionsPercent = totalSectionsLengthInMeters ? (stateManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let privateManagerSectionsPercent = totalSectionsLengthInMeters ? (privateManagerSectionsLengthInMeters * 100) / totalSectionsLengthInMeters : 0;
        let managerArray: PieChartModel[] = [];
        if (municipalityManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.municipality,
                color: managerColors[Manager.municipality],
                lengthInMeters: municipalityManagerSectionsLengthInMeters,
                percent: municipalityManagerSectionsPercent
            });
        }
        if (communityOfMunicipalitiesManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.communityOfMunicipalities,
                color: managerColors[Manager.communityOfMunicipalities],
                lengthInMeters: communityOfMunicipalitiesManagerSectionsLengthInMeters,
                percent: communityOfMunicipalitiesManagerSectionsPercent
            });
        }
        if (agglomerationCommunityManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.agglomerationCommunity,
                color: managerColors[Manager.agglomerationCommunity],
                lengthInMeters: agglomerationCommunityManagerSectionsLengthInMeters,
                percent: agglomerationCommunityManagerSectionsPercent
            });
        }
        if (metropolisManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.metropolis,
                color: managerColors[Manager.metropolis],
                lengthInMeters: metropolisManagerSectionsLengthInMeters,
                percent: metropolisManagerSectionsPercent
            });
        }
        if (departmentManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.department,
                color: managerColors[Manager.department],
                lengthInMeters: departmentManagerSectionsLengthInMeters,
                percent: departmentManagerSectionsPercent
            });
        }
        if (stateManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.state,
                color: managerColors[Manager.state],
                lengthInMeters: stateManagerSectionsLengthInMeters,
                percent: stateManagerSectionsPercent
            });
        }
        if (privateManagerSectionsLengthInMeters > 0) {
            managerArray.push({
                id: Manager.private,
                color: managerColors[Manager.private],
                lengthInMeters: privateManagerSectionsLengthInMeters,
                percent: privateManagerSectionsPercent
            });
        }
        setManagerData(managerArray);

        let sectionsByDistrictGroup = groupBy(Array.from(props.mergedProject.roadsSections), x => x[1].district);
        let hierarchyAndTrafficSurfacesByDistrictData: SurfacesByDistrict[] = [];
        Object.entries(sectionsByDistrictGroup).forEach(([key, value]) => {
            if (key !== 'null') {
                let mainHierarchySectionsSurface = 0;
                let localHierarchySectionsSurface = 0;
                let residentialHierarchySectionsSurface = 0;

                let strongTrafficSectionsSurface = 0;
                let mediumTrafficSectionsSurface = 0;
                let lowTrafficSectionsSurface = 0;

                value.forEach(([valueKey, section]) => {
                    let lengthInMeters = Math.round(section.lengthInMeters);
                    let surface = Math.round(lengthInMeters * section.widthInMeters);

                    switch (section.hierarchy) {
                        case Hierarchy.main:
                            mainHierarchySectionsSurface += surface;
                            break;

                        case Hierarchy.local:
                            localHierarchySectionsSurface += surface;
                            break;

                        case Hierarchy.residential:
                            residentialHierarchySectionsSurface += surface;
                            break;

                        default:
                            break;
                    }

                    switch (section.traffic) {
                        case Traffic.strong:
                            strongTrafficSectionsSurface += surface;
                            break;

                        case Traffic.medium:
                            mediumTrafficSectionsSurface += surface;
                            break;

                        case Traffic.low:
                            lowTrafficSectionsSurface += surface;
                            break;

                        default:
                            break;
                    }
                });

                hierarchyAndTrafficSurfacesByDistrictData.push({
                    district: key,
                    mainHierarchySectionsSurface: mainHierarchySectionsSurface,
                    localHierarchySectionsSurface: localHierarchySectionsSurface,
                    residentialHierarchySectionsSurface: residentialHierarchySectionsSurface,
                    strongTrafficSectionsSurface: strongTrafficSectionsSurface,
                    mediumTrafficSectionsSurface: mediumTrafficSectionsSurface,
                    lowTrafficSectionsSurface: lowTrafficSectionsSurface
                });
            }
        });
        setHierarchyAndTrafficSurfacesByDistrict(hierarchyAndTrafficSurfacesByDistrictData);
    }, [props.mergedProject])

    return (
        <Box className="highway-indicator-stats">
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_LengthByManager} (${MeasurementSystem.getSymbolOfKilometersOrMiles()})`}</div>
                    <PieChart
                        series={[{
                            data: managerData.map((item) => ({
                                id: item.id,
                                value: item.lengthInMeters,
                                label: `${MeasurementSystem.getLenghtInKilometersOrMiles(item.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfKilometersOrMiles()}`
                            })),
                            arcLabel: "label",
                            arcLabelMinAngle: 35,
                            arcLabelRadius: '60%'
                        }]}
                        sx={{
                            [`.${pieArcLabelClasses.root}`]: {
                                fontSize: '9px',
                                padding: '10px'
                            }
                        }}
                        legend={{
                            hidden: true
                        }}
                        height={200}
                        width={270}
                        colors={managerData.map((item) => item.color)}
                        tooltip={{
                            trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} data={managerData} unitMode={TooltipUnitMode.Length} />
                        }}
                        onClick={(e, a, b) => {
                            
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_LengthByHierarchy} (${MeasurementSystem.getSymbolOfKilometersOrMiles()})`}</div>
                    <PieChart
                        series={[{
                            data: hierarchyData.map((item) => ({
                                id: item.id,
                                value: item.lengthInMeters,
                                label: `${MeasurementSystem.getLenghtInKilometersOrMiles(item.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfKilometersOrMiles()}`
                            })),
                            arcLabel: "label",
                            arcLabelMinAngle: 35,
                            arcLabelRadius: '60%'
                        }]}
                        sx={{
                            [`.${pieArcLabelClasses.root}`]: {
                                fontSize: '9px'
                            }
                        }}
                        legend={{
                            hidden: true
                        }}
                        height={200}
                        width={270}
                        colors={hierarchyData.map((item) => item.color)}
                        tooltip={{
                            trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} data={hierarchyData} unitMode={TooltipUnitMode.Length} />
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_AreaByHierarchy} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                    <PieChart
                        series={[{
                            data: hierarchyData.map((item) => ({
                                id: item.id,
                                value: item.surface,
                                label: `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(item.surface).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfSurfaceUnit()}`
                            })),
                            arcLabel: "label",
                            arcLabelMinAngle: 35,
                            arcLabelRadius: '60%'
                        }]}
                        sx={{
                            [`.${pieArcLabelClasses.root}`]: {
                                fontSize: '9px'
                            }
                        }}
                        legend={{
                            hidden: true
                        }}
                        height={200}
                        width={270}
                        colors={hierarchyData.map((item) => item.color)}
                        tooltip={{
                            trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} data={hierarchyData} unitMode={TooltipUnitMode.Surface} />
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_LengthByTraffic} (${MeasurementSystem.getSymbolOfKilometersOrMiles()})`}</div>
                    <PieChart
                        series={[{
                            data: trafficData.map((item) => ({
                                id: item.id,
                                value: item.lengthInMeters,
                                label: `${MeasurementSystem.getLenghtInKilometersOrMiles(item.lengthInMeters).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfKilometersOrMiles()}`
                            })),
                            arcLabel: "label",
                            arcLabelMinAngle: 35,
                            arcLabelRadius: '60%'
                        }]}
                        sx={{
                            [`.${pieArcLabelClasses.root}`]: {
                                fontSize: '9px'
                            }
                        }}
                        legend={{
                            hidden: true
                        }}
                        height={200}
                        width={270}
                        colors={trafficData.map((item) => item.color)}
                        tooltip={{
                            trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} data={trafficData} unitMode={TooltipUnitMode.Length} />
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="column">
                    <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_AreaByTraffic} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                    <PieChart
                        series={[{
                            data: trafficData.map((item) => ({
                                id: item.id,
                                value: item.surface,
                                label: `${MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(item.surface).toFixedLocalized(2)} ${MeasurementSystem.getSymbolOfSurfaceUnit()}`
                            })),
                            arcLabel: "label",
                            arcLabelMinAngle: 35,
                            arcLabelRadius: '60%'
                        }]}
                        sx={{
                            [`.${pieArcLabelClasses.root}`]: {
                                fontSize: '9px'
                            }
                        }}
                        legend={{
                            hidden: true
                        }}
                        height={200}
                        width={270}
                        colors={trafficData.map((item) => item.color)}
                        tooltip={{
                            trigger: "item", itemContent: (properties) => <CustomItemTooltipContent {...properties} data={trafficData} unitMode={TooltipUnitMode.Surface} />
                        }}
                    />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" mb="20px">
                <ManagerLegendsComponent />
                <HierarchyLegendsComponent />
                <HierarchyLegendsComponent />
                <TrafficLegendsComponent />
                <TrafficLegendsComponent />
            </Box>
            {hierarchyAndTrafficSurfacesByDistrict.length > 0 &&
                <Box display="flex" flexDirection="row" justifyContent="space-between" className="surface-by-district">
                    <Box display="flex" flexDirection="column">
                        <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_TypeOfHierarchyInAreaByDistrict} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                        <BarChart
                            yAxis={[{
                                scaleType: 'band',
                                dataKey: 'district',
                                label: Translate.Resources.UI_IndicatorStats_Districts
                            }]}
                            xAxis={[{
                                label: MeasurementSystem.getSymbolOfSurfaceUnit(),
                            }]}
                            series={[
                                { dataKey: 'mainHierarchySectionsSurface', label: Translate.Resources.UI_Highways_Grid_Hierarchy_Main, stack: 'total' },
                                { dataKey: 'localHierarchySectionsSurface', label: Translate.Resources.UI_Highways_Grid_Hierarchy_Local, stack: 'total' },
                                { dataKey: 'residentialHierarchySectionsSurface', label: Translate.Resources.UI_Highways_Grid_Hierarchy_Residential, stack: 'total' }
                            ]}
                            dataset={hierarchyAndTrafficSurfacesByDistrict as any}
                            width={700}
                            height={350}
                            colors={[hierarchyColors[Hierarchy.main], hierarchyColors[Hierarchy.local], hierarchyColors[Hierarchy.residential]]}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(28px, -40%)'
                                },
                                [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                                    transform: 'translate(333px, 0)'
                                }
                            }}
                            legend={{
                                hidden: true
                            }}
                            layout="horizontal"
                        />
                        <Box display="flex" flexDirection="row">
                            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
                            <Box display="flex" flexDirection="row" alignItems="center" ml="10px">
                                <div className="legend main"></div>
                                <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Main}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend local"></div>
                                <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Local}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend residential"></div>
                                <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Residential}</div>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <div className="title">{`${Translate.Resources.UI_HighwaysIndicatorStats_TypeOfSurfaceHGVTrafficByDistrict} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                        <BarChart
                            yAxis={[{
                                scaleType: 'band',
                                dataKey: 'district',
                                label: Translate.Resources.UI_IndicatorStats_Districts
                            }]}
                            xAxis={[{
                                label: MeasurementSystem.getSymbolOfSurfaceUnit(),
                            }]}
                            series={[
                                { dataKey: 'strongTrafficSectionsSurface', label: Translate.Resources.UI_Highways_Grid_Traffic_Strong, stack: 'total' },
                                { dataKey: 'mediumTrafficSectionsSurface', label: Translate.Resources.UI_Highways_Grid_Traffic_Moderate, stack: 'total' },
                                { dataKey: 'lowTrafficSectionsSurface', label: Translate.Resources.UI_Highways_Grid_Traffic_Low, stack: 'total' }
                            ]}
                            dataset={hierarchyAndTrafficSurfacesByDistrict as any}
                            width={700}
                            height={350}
                            colors={[trafficColors[Traffic.strong], trafficColors[Traffic.medium], trafficColors[Traffic.low]]}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(28px, -40%)'
                                },
                                [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                                    transform: 'translate(333px, 0)'
                                }
                            }}
                            legend={{
                                hidden: true
                            }}
                            layout="horizontal"
                        />
                        <Box display="flex" flexDirection="row">
                            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
                            <Box display="flex" flexDirection="row" alignItems="center" ml="10px">
                                <div className="legend strong"></div>
                                <div className="legend">{Translate.Resources.UI_Highways_Grid_Traffic_Strong}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend moderate"></div>
                                <div className="legend">{Translate.Resources.UI_Highways_Grid_Traffic_Moderate}</div>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <div className="legend low"></div>
                                <div className="legend">{Translate.Resources.UI_Highways_Grid_Traffic_Low}</div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    );
}

const TrafficLegendsComponent = (): JSX.Element => {
    return (
        <Box display="flex" flexDirection="column" width="270px">
            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend strong"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Traffic_Strong}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend moderate"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Traffic_Moderate}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend low"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Traffic_Low}</div>
            </Box>
        </Box>
    );
}

const HierarchyLegendsComponent = (): JSX.Element => {
    return (
        <Box display="flex" flexDirection="column" width="270px">
            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend main"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Main}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend local"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Local}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend residential"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Hierarchy_Residential}</div>
            </Box>
        </Box>
    );
}

const ManagerLegendsComponent = (): JSX.Element => {
    return (
        <Box display="flex" flexDirection="column" width="270px">
            <div className="title">{`${Translate.Resources.UI_RoadConditionIndicatorStats_Legends} :`}</div>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend municipality"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_Municipality}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend community-of-municipalities"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_CommunityOfMunicipalities}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend agglomeration-community"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_AgglomerationCommunity}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend metropolis"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_Metropolis}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend department"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_Department}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend state"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_State}</div>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
                <div className="legend private"></div>
                <div className="legend">{Translate.Resources.UI_Highways_Grid_Manager_Private}</div>
            </Box>
        </Box>
    );
}