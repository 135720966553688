import { ScoreTypes } from "../../../shared/models/ScoreTypes";

export const getScoreTypeCssClass: Map<string, string> = new Map<string, string>([
    [ScoreTypes.monitoring, "monitoring"],
    [ScoreTypes.generalMaintenance, "general-maintenance"],
    [ScoreTypes.localizedRepair, "localized-repair"],
    [ScoreTypes.reinforcement, "reinforcement"],
    [ScoreTypes.rehabilitation, "rehabilitation"],
    [null, "empty"]
]);
