import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { CompositeFilterDescriptor, FilterDescriptor, orderBy as sortOrderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn, GridColumnMenuCheckboxFilter, GridColumnMenuProps, GridColumnReorderEvent, GridColumnResizeEvent, GridFilterChangeEvent, GridHeaderCellProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import Translate, { Localization } from '../../../../../localization/Localization';
import { MeasurementSystemType } from '../../../../../shared/models/MeasurementSystemType';
import { getScoreType } from '../../../../../shared/models/ScoreTypes';
import { MeasurementSystem } from '../../../../../utils/MeasurementSystem';
import { AppModule, LocalStorage } from '../../../../../utils/Storage';
import { CostRatio } from '../../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { WorkPriority } from '../../../../ProjectSettings/services/dataContracts/queryStack/WorkPriority';
import { RoadsConditionAndScenariosShared } from '../../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { getScoreTypeCssClass } from '../../../models/Quality';
import { getHiddenIsSelectedForSort, RoadSection } from '../../../models/RoadSection';
import { Scenario } from '../../../services/dataContracts/queryStack/Scenario';
import { ScenarioBudgetSummaryComponent } from '../../CommonComponents/ScenarioBudgetSummaryComponent';
import '../../CommonComponents/SectionsSelectorStyles.scss';
import ScenariosUtilities from '../../ScenariosUtilities';
import { SizingUtilities } from '../../SizingUtilities';
import { CustomCheckboxCell } from './CustomCheckboxCell';
import { CustomCheckboxHeaderCell } from './CustomCheckboxHeaderCell';

interface SectionsSelectorComponentProps {
    selectedScenario: Scenario,
    currency: string,
    sections: Map<number, RoadSection>,
    selectedSectionsIds: Set<number>,
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    measurementSystemType: MeasurementSystemType,
    workPriorities: WorkPriority[],
    costRatios: CostRatio[],
    handleSaveScenarioSections: () => void,
    handleCancelUpdateScenarioSections: () => void,
    updateSelectedSections: (selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>) => void,
    handleSectionFilteredChanged: (filteredSectionsIdsFromGrid: Set<number>) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const ScenarioSectionsGridName = 'scenarioSections';
const ModuleKey = AppModule.Scenarios;

export const SectionsSelectorComponent = (props: SectionsSelectorComponentProps): JSX.Element => {

    let initialSort: SortDescriptor[] = [{ field: 'label', dir: 'desc' }];

    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);
    const [totalSelectedSectionsLengthInMeters, setTotalSelectedSectionsLengthInMeters] = useState<number>(0);
    const [totalSelectedSectionsSurface, setTotalSelectedSectionsSurface] = useState<number>(0);
    const [selectedSectionCount, setSelectedSectionCount] = useState<number>(0);
    const [sectionsCostSum, setSectionsCostSum] = useState<number>(0);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({ logic: 'and', filters: [] });
    const [initialData, setInitialData] = useState<RoadSection[]>([]);
    const [data, setData] = useState<RoadSection[]>([]);

    useEffect(() => {
        let totalSelectedSectionsLengthInMeters = 0;
        let totalSelectedSectionsSurface = 0;
        let selectedSectionCount = 0;
        let sectionsCostSum = 0;

        if (props.selectedScenario) {
            props.selectedScenario.sections.map(e => e.roadSectionId).forEach((sectionId) => {
                let section = props.sections.get(sectionId);
                if (section.isSelected) {
                    let lengthInMeters = Math.round(section.lengthInMeters);
                    totalSelectedSectionsLengthInMeters += section.lengthInMeters;

                    let surface = Math.round(lengthInMeters * section.widthInMeters);
                    totalSelectedSectionsSurface += surface;
                    selectedSectionCount++;

                    sectionsCostSum += section.cost ?? 0;
                }
            });

            setTotalSelectedSectionsLengthInMeters(totalSelectedSectionsLengthInMeters);
            setTotalSelectedSectionsSurface(totalSelectedSectionsSurface);
            setSelectedSectionCount(selectedSectionCount);
            setSectionsCostSum(sectionsCostSum);

            let initialData: RoadSection[] = [];
            props.sections.forEach((element) => {
                initialData.push(element);
            });
            setInitialData(initialData);
            setData(filter.filters.length > 0 ? data : initialData);
        }
    }, [props.selectedScenario, props.measurementSystemType, props.sections]);

    useEffect(() => {
        setSkip(0);
    }, [sort])

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const getGridOrderIndexColumn = (propName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, ScenarioSectionsGridName, propName, defaultIndex);
    }

    const getGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, ScenarioSectionsGridName, fieldName, columnWidth);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id === event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            ScenarioSectionsGridName,
            currentColumn.field,
            currentColumn.width as number);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, ScenarioSectionsGridName, event.columns);
        forceUpdate();
    }

    const handleSortColumnChange = (sortItems: SortDescriptor[]): void => {
        setSort(sortItems);
    }

    const handleSortChange = (e: GridSortChangeEvent): void => {
        let sort = e.sort[0];

        if (sort && sort.field === "isSelected") {
            setSort([{ field: 'hiddenIsSelectedForSort', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "widthInMetersGridValue") {
            setSort([{ field: 'widthInMeters', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "surfaceGridValue") {
            setSort([{ field: 'surface', dir: sort.dir }, sort]);
            return;
        }

        if (sort && sort.field === "costGridValue") {
            setSort([{ field: 'cost', dir: sort.dir }, sort]);
            return;
        }

        setSort(e.sort);
    }

    const handleRowClick = (item: RoadSection): void => {
        let sections = props.sections;
        let section = sections.get(item.roadSectionId);

        let selected = !section.isSelected;
        section.isSelected = selected;
        section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(selected);

        if (!selected) {
            let dataArray = data;
            let initialDataArray = initialData;
            updateSectionData(section, props.workPriorities, props.costRatios, sections, dataArray, initialDataArray);
            setData(dataArray);
            setInitialData(initialDataArray);
        }

        let selectedSectionsIds = new Set<number>(props.selectedSectionsIds);
        if (selected) {
            if (!selectedSectionsIds.has(section.roadSectionId)) {
                selectedSectionsIds.add(section.roadSectionId);
            }
        }
        else if (!selected) {
            if (selectedSectionsIds.has(section.roadSectionId)) {
                selectedSectionsIds.delete(section.roadSectionId);
            }
        }

        props.updateSelectedSections(selectedSectionsIds, sections);
    }

    const handleHeaderCheckboxChanged = (checked: boolean): void => {
        let selectedSectionsIds = new Set<number>(props.selectedSectionsIds);

        let dataArray = data;
        let initialDataArray = initialData;

        let sections = props.sections;
        sections.forEach((section) => {
            if ((section.isVisible === true || section.isSelected === true) && data.some(x => x.roadSectionId === section.roadSectionId)) {
                section.isSelected = checked;
                section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(checked);

                if (checked) {
                    if (!selectedSectionsIds.has(section.roadSectionId)) {
                        selectedSectionsIds.add(section.roadSectionId);
                    }
                }
                else {
                    updateSectionData(section, props.workPriorities, props.costRatios, sections, dataArray, initialDataArray);

                    if (selectedSectionsIds.has(section.roadSectionId)) {
                        selectedSectionsIds.delete(section.roadSectionId);
                    }
                }
            }
        });

        setData(dataArray);
        setInitialData(initialDataArray);

        props.updateSelectedSections(selectedSectionsIds, sections);
    }

    const updateSectionData = (section: RoadSection, workPriorities: WorkPriority[], costRatios: CostRatio[], sections: Map<number, RoadSection>, dataArray: RoadSection[], initialDataArray: RoadSection[]): void => {
        section.scenarioSectionScore = {
            score: section.score,
            roadSectionScoreOverrideHistoryId: section.roadSectionScoreOverrideHistoryId,
            lastModificationUserFullName: section.lastModificationUserFullName,
            lastModificationDate: section.lastModificationDate,
            remarkScoreOverridden: section.remarkScoreOverridden
        };

        let sectionData = ScenariosUtilities.getSectionDataWhenHasImportance(section.importance, section.scenarioSectionScore.score, section.traffic, workPriorities, costRatios);
        section.workPriority = sectionData.workPriority ? sectionData.workPriority.value : null;
        section.costRatio = sectionData.costRatio ? sectionData.costRatio.costRatioValue : null;
        section.cost = section.surface && section.costRatio ? section.surface * section.costRatio : null;
        section.costGridValue = section.cost ? section.cost.toFixedLocalized(0) : null;
        sections.set(section.roadSectionId, section);

        let dataIndex = dataArray.findIndex(x => x.roadSectionId === section.roadSectionId);
        dataArray[dataIndex].workPriority = section.workPriority;
        dataArray[dataIndex].costRatio = section.costRatio;
        dataArray[dataIndex].cost = section.cost;
        dataArray[dataIndex].costGridValue = section.costGridValue;

        let initialDataIndex = initialDataArray.findIndex(x => x.roadSectionId === section.roadSectionId);
        initialDataArray[initialDataIndex].workPriority = section.workPriority;
        initialDataArray[initialDataIndex].costRatio = section.costRatio;
        initialDataArray[initialDataIndex].cost = section.cost;
        initialDataArray[initialDataIndex].costGridValue = section.costGridValue;
    }

    const handleFilterChange = (event: GridFilterChangeEvent) => {
        setFilter(event.filter);

        let selectedLines = initialData.filter(x => x.isSelected === true);
        let newData = process(initialData, { filter: event.filter }).data as RoadSection[];
        selectedLines.forEach(line => {
            if (!newData.find(x => x.roadSectionId === line.roadSectionId)) {
                newData.push(line);
            }
        });

        setData(newData);
        props.handleSectionFilteredChanged(new Set<number>(newData.map(x => x.roadSectionId)));
    };

    const checkboxFilter = (columnsprops: GridColumnMenuProps) => {
        let field = columnsprops.column.field;
        switch (field) {
            case "roadLabel":
                initialData.sort((a, b) => {
                    if (a[field] < b[field]) return -1;
                    if (a[field] > b[field]) return 1;
                    return 0;
                });

                break;

            case "roadSectionId":
            case "workPriority":
            case "importance":
            case "lengthInMetersGridValue":
            case "widthInMeters":
            case "surface":
            case "costRatio":
            case "costGridValue":
                initialData.sort((a, b) => a[field] - b[field]);

                break;

            default:
                break;
        }

        return (
            <div className="kendo-checkbox">
                <GridColumnMenuCheckboxFilter
                    {...columnsprops}
                    data={initialData}
                    expanded={true}
                    searchBoxFilterOperator={"contains"}
                />
            </div>
        );
    }

    const isColumnActive = (field: string) => {
        let hasFilter = false;
        if (filter && filter.filters && filter.filters[0]) {
            hasFilter = (filter.filters as CompositeFilterDescriptor[]).some((rootFilter) => (rootFilter.filters as FilterDescriptor[])[0].field === field);
        }
        return hasFilter;
    };

    const forceUpdate = useForceUpdate();

    let gridHeightGapFromWindow: number = SizingUtilities.sectionsGridHeightGapFromWindow();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridHeightGapFromWindow);
    let rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);
    let gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridHeightGapFromWindow;
        gridPageSize = Number((gridHeight / rowHeight).toFixedLocalized(0)) * 2;
        forceUpdate();
    }
    window.onresize = resize;

    let filteredSections: RoadSection[] = [];
    data.forEach((element) => {
        if (element.isVisible === true || element.isSelected === true) {
            filteredSections.push(element);
        }
    });

    if (skip > filteredSections.length || isNaN(skip)) {
        setSkip(0);
    }

    let dataGrid = sortOrderBy(filteredSections, sort).slice(skip, skip + gridPageSize);

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    return (
        <Box className={`sections-selector ${props.isSectionsDrawerOpened ? 'opened' : ''}`}>
            <ScenarioBudgetSummaryComponent
                sectionsCostSum={sectionsCostSum}
                currency={props.currency}
                selectedScenario={props.selectedScenario}
                selectedSectionCount={selectedSectionCount}
                totalSelectedSectionsLengthInMeters={totalSelectedSectionsLengthInMeters}
                totalSelectedSectionsSurface={totalSelectedSectionsSurface}
            />
            <Box>
                <LocalizationProvider language={Localization.locale}>
                    <IntlProvider locale={Localization.locale}>
                        <Grid
                            className="grid"
                            data={dataGrid}
                            selectedField="isSelected"
                            sortable
                            resizable
                            reorderable
                            sort={sort}
                            scrollable="virtual"
                            skip={skip}
                            total={filteredSections.length}
                            rowHeight={rowHeight}
                            pageSize={gridPageSize}
                            style={gridStyle}
                            onPageChange={pageChange}
                            onSortChange={(e) => handleSortChange(e)}
                            onRowClick={(e) => handleRowClick(e.dataItem)}
                            onColumnResize={onResizeHandler}
                            onColumnReorder={onReorderHandler}
                            filterable
                            filter={filter}
                            onFilterChange={handleFilterChange}
                        >
                            <GridColumn title="" width="8px" orderIndex={0} resizable={false} reorderable={false}
                                cell={(_props) => {
                                    let scoreType = _props.dataItem.scoreType;
                                    if (_props.dataItem.scenarioSectionScore && _props.dataItem.scenarioSectionScore.score !== _props.dataItem.score) {
                                        let scoreColor = RoadsConditionAndScenariosShared.getScoreColor(_props.dataItem.scenarioSectionScore.score);
                                        scoreType = getScoreType(scoreColor);
                                    }

                                    return <td className={`score-color-indicator ${getScoreTypeCssClass.get(scoreType)}`}></td>
                                }}
                            />
                            <GridColumn field="isSelected" width="60px" orderIndex={1} resizable={false} reorderable={false}
                                cell={(_props: GridCellProps) => <CustomCheckboxCell {..._props} handleRowClick={handleRowClick} />}
                                headerCell={(_props: GridHeaderCellProps) => <CustomCheckboxHeaderCell {..._props}
                                    sortingField="hiddenIsSelectedForSort"
                                    sort={sort}
                                    filteredSections={filteredSections}
                                    handleSortColumnChange={handleSortColumnChange}
                                    handleHeaderCheckboxChanged={handleHeaderCheckboxChanged}
                                />}
                            />
                            <GridColumn field="roadSectionId" columnMenu={checkboxFilter} headerClassName={isColumnActive("roadSectionId") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("roadSectionId", 1)} minResizableWidth={60} width={getGridWidth("roadSectionId", 100)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Section} />
                            <GridColumn field="roadLabel" columnMenu={checkboxFilter} headerClassName={isColumnActive("roadLabel") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("label", 2)} minResizableWidth={60} width={getGridWidth("label", 200)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Road} />
                            <GridColumn field="workPriority" columnMenu={checkboxFilter} headerClassName={isColumnActive("workPriority") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("workPriority", 3)} minResizableWidth={60} width={getGridWidth("workPriority", 100)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_PriorityOfWork} />
                            <GridColumn field="importance" columnMenu={checkboxFilter} headerClassName={isColumnActive("importance") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("importance", 4)} minResizableWidth={60} width={getGridWidth("importance", 100)} title={Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Importance} />
                            <GridColumn field="lengthInMetersGridValue" columnMenu={checkboxFilter} headerClassName={isColumnActive("lengthInMetersGridValue") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("lengthInMetersGridValue", 5)} minResizableWidth={60} width={getGridWidth("lengthInMetersGridValue", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Linear} (${symbolOfLengthUnit})`}
                                cell={(cellProps) => <td>{MeasurementSystem.getLengthInMetersOrYards(cellProps.dataItem.lengthInMeters)?.toFixedLocalized(0)}</td>} />
                            <GridColumn field="widthInMeters" columnMenu={checkboxFilter} headerClassName={isColumnActive("widthInMeters") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("widthInMeters", 6)} minResizableWidth={60} width={getGridWidth("widthInMeters", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Width} (${symbolOfLengthUnit})`}
                                cell={(cellProps) => <td>{MeasurementSystem.getLengthInMetersOrYards(cellProps.dataItem.widthInMeters)?.toFixedLocalized(0)}</td>} />
                            <GridColumn field="surface" columnMenu={checkboxFilter} headerClassName={isColumnActive("surface") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("surface", 7)} minResizableWidth={60} width={getGridWidth("surface", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Surface} (${symbolOfSurfaceUnit})`}
                                cell={(cellProps) => <td >{MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(cellProps.dataItem.surface)?.toFixedLocalized(0)}</td>} />
                            <GridColumn field="costRatio" columnMenu={checkboxFilter} headerClassName={isColumnActive("costRatio") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("costRatio", 8)} minResizableWidth={60} width={getGridWidth("costRatio", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Ratio} (${props.currency}/${symbolOfSurfaceUnit})`}
                                cell={(cellProps) => <td>{MeasurementSystem.getRatioInMetersOrYards(cellProps.dataItem.costRatio)?.toFixedLocalized(2)}</td>} />
                            <GridColumn field="costGridValue" columnMenu={checkboxFilter} headerClassName={isColumnActive("costGridValue") ? "k-filterable-active" : ""} className="column-number" orderIndex={getGridOrderIndexColumn("costGridValue", 9)} minResizableWidth={60} width={getGridWidth("costGridValue", 100)} title={`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Cost} (${props.currency})`} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box className="btns-actions" display="flex" flexDirection="row" justifyContent="end">
                <Button className="btn-secondary" onClick={() => props.handleCancelUpdateScenarioSections()}>
                    {Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Button_Cancel}
                </Button>
                <Button className="btn-primary" onClick={() => props.handleSaveScenarioSections()}>
                    {Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Button_Save}
                </Button>
            </Box>
        </Box>
    );
}